import React from 'react'
import { Component } from 'reflux'

export default class DashboardFrame extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { DashboardID, DashboardToken } = this.props.match.params

    if (!DashboardID || !DashboardToken) return <h3>Could not load dashboard</h3>

    const source = `https://analytics.zoho.eu/open-view/${DashboardID}/${DashboardToken}`
    return (
      <React.Fragment>
        <iframe frameBorder='0' width='100%' height='1200' src={source}></iframe>
      </React.Fragment>
    )
  }
}
