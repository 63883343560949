/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import keyMirror from 'keymirror'
import moment from 'moment'
import 'moment/locale/da'
import _ from 'lodash'

export const STATE = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  HOF_FAILED: null,
  HOF_SUCCESS: null,
  FETCHING_HOFLIST: null,
  FETCHING_HOFLIST_FAILED: null,
  FETCHING_HOFLIST_SUCCESS: null,
  FETCHING_HOF: null,
  FETCHING_HOF_FAILED: null,
  HOF_DELETE_ERROR: null,
  HOF_DELETE_SUCCESS: null,
  FETCHING_GROUPS: null,
  FETCHING_GROUPS_FAILED: null,
  FETCHING_PREVIEW: null,
  FETCHING_PREVIEW_FAILED: null,
  FETCHING_HOF_ELEMENTS: null,
  FETCHING_HOF_ELEMENTS_FAILED: null,
  FETCHING_BRANDS_FAILED: null,
  ACTIVATE_HOF_SUCCESS: null
})

export const HofActions = createActions([
  'clearToast',
  'createEmptyHOF',
  'getHOFData',
  'getHOFDetails',
  'updateHOFField',
  'saveHOF',
  'deleteHOF',
  'getGroups',
  'toggleGroup',
  'previewHOF',
  'getHOFElements',
  'copyHOF',
  'toggleQueryContent',
  'getBrands',
  'giveAccess',
  'activateHOF',
  'toggleInActiveHof',
  'toggleActiveHof'
])

export class HofStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = HofActions
    this.state = {
      status: null,
      filteredGroupData: [],
      hofDetails: {
        BrandID: '',
        moduleName: '',
        moduleDescription: '',
        moduleQuery: '',
        showProfileImages: 1,
        poolOrder: '',
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: '',
        groups: [],
        groupFilterText: ''
      },
      hofList: [],
      groups: [],
      isPreview: false,
      previewElements: [],
      hofElements: [],
      isAdminAccess: false,
      fetchingBlogs: false,
      fetchingGroups: false,
      brands: [],
      HofFilterValue: 'active',
      showInactiveHof: false,
      showActiveHof: true
    }
  }
  clearToast(status) {
    status ? this.setState({ status }) : this.setState({ status: null })
  }
  createEmptyHOF() {
    this.setState({
      groups: [],
      isPreview: '',
      hofDetails: {
        BrandID: '',
        moduleName: '',
        moduleDescription: '',
        moduleQuery: '',
        showProfileImages: 1,
        poolOrder: '',
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: '',
        groups: [],
        groupFilterText: ''
      }
    })
  }

  getHOFData() {
    if (this.state.status === STATE.FETCHING_HOFLIST) {
      return false
    }
    this.setState({ status: STATE.FETCHING_HOFLIST })
    let api = this.state.isAdminAccess ? 'halloffame/admin-pool' : 'halloffame/pool'
    GET(api).then(responseJSON => {
      let nextState = {
        hofList: []
      }
      if (responseJSON.error) {
        nextState.status = STATE.FETCHING_HOFLIST_FAILED
      } else {
        responseJSON = responseJSON.map(item => {
          if (item.moduleQuery && item.moduleQuery.length > 100) {
            item.moduleQueryReadMore = true
            item.moduleQueryReadLess = false
          } else {
            item.moduleQueryReadMore = false
            item.moduleQueryReadLess = false
          }
          return item
        })
        nextState.hofList = responseJSON
        nextState.status = STATE.FETCHING_HOFLIST_SUCCESS
      }
      this.setState(nextState)
    })
  }

  getHOFDetails(HalloffameID, copy) {
    this.setState({ status: STATE.FETCHING_HOF, isPreview: false })
    GET('halloffame/fetch-admin', { HalloffameID: HalloffameID }).then(responseJSON => {
      let nextState = {
        hofDetails: {}
      }
      if (responseJSON.error) {
        nextState.status = STATE.FETCHING_HOF_FAILED
      } else {
        responseJSON.timeEnd = responseJSON.timeEnd && moment.parseZone(responseJSON.timeEnd).format('YYYY-MM-DD')
        responseJSON.timeStart = responseJSON.timeStart && moment.parseZone(responseJSON.timeStart).format('YYYY-MM-DD')
        this.getGroups(responseJSON.BrandID)
        copy && delete responseJSON.HalloffameID
        nextState.hofDetails = responseJSON
        nextState.status = STATE.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }
  getGroups(BrandID) {
    if (this.state.fetchingGroups) {
      return false
    }
    this.setState({ fetchingGroups: true })
    GET('groups/fetch-groups', { BrandID }).then(responseJSON => {
      let nextState = {
        groups: []
      }
      if (responseJSON.error) {
        nextState.status = STATE.FETCHING_GROUPS_FAILED
      } else {
        nextState.groups = responseJSON
        ;(nextState.status = STATE.TAKING_INPUT), (nextState.fetchingGroups = false)
      }
      this.setState(nextState)
    })
  }
  updateHOFField(field) {
    let value = strip_tags(field.target.value)
    let hofDetails = this.state.hofDetails
    hofDetails[field.target.name] = value
    if (field.target.name === 'groupFilterText') {
      const groupsFilter = value ? this.state.groups.filter(item => item.moduleName.toLowerCase().includes(value.toLowerCase())) : this.state.groups
      this.setState({ filteredGroupData: groupsFilter })
    }
    if (field.target.name === 'timeStart') {
      value = moment.parseZone(value).format('YYYY-MM-DD')
    }
    if (field.target.name === 'timeEnd') {
      value = moment.parseZone(value).format('YYYY-MM-DD')
    }
    if (field.target.name === 'BrandID' || field.target.name === 'poolOrder' || field.target.name === 'showProfileImages') {
      hofDetails[field.target.name] = Number(value)
    }
    if (field.target.name === 'BrandID') {
      this.getGroups(value)
    }
    this.setState({ hofDetails })
  }

  saveHOF() {
    if (this.state.hofDetails.moduleName != '') {
      this.setState({ status: STATE.IS_SAVING })
      let hof = this.state.hofDetails
      hof.BrandID === '' && delete hof.BrandID
      hof.poolOrder === '' && delete hof.poolOrder
      hof.showProfileImages === '' && delete hof.showProfileImages
      POST('halloffame/replace', hof).then(responseJSON => {
        let nextState = {
          hofDetails: this.state.hofDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATE.HOF_FAILED
          } else {
            nextState.hofDetails = responseJSON
            nextState.status = STATE.HOF_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }

  deleteHOF(HalloffameID = null) {
    if (HalloffameID) {
      POST('halloffame/removeHof', { HalloffameID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATE.HOF_DELETE_ERROR })
        } else {
          this.setState({ status: STATE.HOF_DELETE_SUCCESS })
        }
      })
    }
  }
  toggleGroup(checkbox) {
    let GroupID = checkbox.target.name
    let checked = checkbox.target.checked
    let hofDetails = this.state.hofDetails
    if (checked) {
      let thisGroup = this.state.groups.find(e => {
        return e.GroupID == GroupID
      })
      if (hofDetails.groups) {
        hofDetails.groups.push(thisGroup)
      } else {
        hofDetails.groups = [thisGroup]
      }
    } else {
      hofDetails.groups = hofDetails.groups.filter(e => {
        return e.GroupID != GroupID
      })
    }
    this.setState({ hofDetails: hofDetails })
  }

  previewHOF() {
    this.setState({ status: STATE.FETCHING_PREVIEW, isPreview: true })
    GET('halloffame/preview', { moduleQuery: this.state.hofDetails.moduleQuery }).then(responseJSON => {
      let nextState = {
        previewElements: this.state.previewElements,
        fetchingPreview: false
      }
      if (responseJSON.error) {
        nextState.status = STATE.FETCHING_PREVIEW_FAILED
      } else {
        nextState.previewElements = responseJSON
        nextState.status = STATE.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  getHOFElements(HalloffameID = null) {
    this.setState({ status: STATE.FETCHING_HOF_ELEMENTS })
    GET('halloffame/elements', { HalloffameID }).then(responseJSON => {
      let nextState = {
        hofElements: []
      }
      if (responseJSON.error) {
        nextState.status = STATE.FETCHING_HOF_ELEMENTS_FAILED
      } else {
        nextState.hofElements = responseJSON
        nextState.status = STATE.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  copyHOF(hof, props) {
    props.history.push(props.location.pathname + '/new?copy=' + hof.HalloffameID)
  }

  toggleQueryContent(e) {
    let hofList = this.state.hofList
    hofList = hofList.map(item => {
      if (item.HalloffameID === e.HalloffameID) {
        item.moduleQueryReadMore = !item.moduleQueryReadMore
        item.moduleQueryReadLess = !item.moduleQueryReadLess
      }
      return item
    })
    this.setState({ hofList })
  }

  getBrands() {
    this.setState({ fetchingBrands: true })
    GET('schemas/allbrands').then(responseJSON => {
      let nextState = {
        brands: []
      }
      if (responseJSON.error) {
        nextState.status = STATE.FETCHING_BRANDS_FAILED
      } else {
        nextState.brands = _.sortBy(responseJSON, 'brandName')
        nextState.status = STATE.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  giveAccess(BrandID, fetchGroups) {
    if (fetchGroups) {
      let hofDetails = this.state.hofDetails
      hofDetails.BrandID = BrandID
      this.setState({ hofDetails, isAdminAccess: true })
      this.getGroups(hofDetails.BrandID)
    } else {
      this.setState({ isAdminAccess: true })
    }
  }

  activateHOF(HalloffameID = null) {
    if (HalloffameID) {
      POST('halloffame/enable', { HalloffameID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATE.HOF_DELETE_ERROR })
        } else {
          this.setState({ status: STATE.ACTIVATE_HOF_SUCCESS })
        }
      })
    }
  }

  toggleActiveHof() {
    let value = !this.state.showActiveHof ? 'active' : 'both'
    this.setState({ showActiveHof: !this.state.showActiveHof, HofFilterValue: value, showInactiveHof: false })
  }
  toggleInActiveHof() {
    let value = this.state.showInactiveHof ? 'both' : 'inactive'
    this.setState({ showInactiveHof: !this.state.showInactiveHof, HofFilterValue: value, showActiveHof: false })
  }
}
