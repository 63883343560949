import React, { useEffect, useState, useCallback, useRef } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import {
  fetchData,
  saveData,
  STATUS,
  fetchHeaderConfigDetails,
  fetchShopCustomisationData,
  saveHeaderConfigData,
  saveClubCMSData,
  fetchLoginPageCustomisationData,
  fetchFooterCustomisationData,
  fetchColorsCustomisationData,
  fetchProfileCustomisationData,
  fetchShops,
  fetchShopProducts,
  fetchPersonalShopperCustomisationData,
  fetchhofCustomisationData,
  fetchCountDownCustomisationData
} from 'Reducers/actions/cmsActions'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, FormGroup, Label, Col, Input, Row, UncontrolledTooltip, CustomInput, Fade, Table, Container } from 'reactstrap'
import FormField from 'Components/formField'
import { updateBannerContent, clearToast, updateHeaderConfig, updateClubCmsContent } from 'Reducers/cmsReducer'
import { toast } from 'react-toastify'
import 'react-image-crop/dist/ReactCrop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Proxies from 'Components/proxies'
const { GET, PUT, wrapFilter } = Proxies
import styled from 'styled-components'

const StyledCustomInput = styled(CustomInput)`
  top: 6px !important;
`

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 68px;
  height: 32px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`

const SwitchInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`

const ToggleSwitch = props => {
  const { label, handleChange, value } = props
  const [checked, setChecked] = useState(value || false)

  // const handleChange = (e) => {
  //   setChecked(e.target.checked);
  // };

  useEffect(() => {
    setChecked(value || false)
  }, [value])

  return (
    <SwitchLabel>
      <SwitchInput checked={checked} type='checkbox' onChange={handleChange} />
      <Switch />
      <span>{label}</span>
    </SwitchLabel>
  )
}

export default class ClubCMS extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <ClubCMSComponent />
      </React.Fragment>
    )
  }
}

function ClubCMSComponent() {
  const dispatch = useDispatch()
  const platformData = useSelector(state => state.login.settings)
  const cmsData = useSelector(state => state.cms.cmsData)
  const brandShops = useSelector(state => state.cms.brandShops)
  const shopCategories = useSelector(state => state.cms.shopCategories)
  const headerLogoSizeData = useSelector(state => state.cms.headerLogoSizeData)
  const themeOneMenuBarColors = useSelector(state => state.cms.themeOneMenuBarColors)
  const settings = useSelector(state => state.login.settings)
  const brandCmsData = useSelector(state => state.cms.brandCmsData)
  const loadingState = useSelector(state => state.cms.loadingState)
  const savedHeaderConfigDetails = useSelector(state => state.cms.savedHeaderConfigDetails)
  const loginPageDetails = useSelector(state => state.cms.loginPageDetails)
  const savedLoginPageDetails = useSelector(state => state.cms.savedLoginPageDetails)
  const footerDetails = useSelector(state => state.cms.footerDetails)
  const savedFooterDetails = useSelector(state => state.cms.savedFooterDetails)
  const themeOverrides = useSelector(state => state.cms.themeOverrides)
  const savedthemeOverrides = useSelector(state => state.cms.savedthemeOverrides)
  const profileDetails = useSelector(state => state.cms.profileDetails)
  const savedProfileDetails = useSelector(state => state.cms.savedProfileDetails)
  const personalShopperDetails = useSelector(state => state.cms.personalShopperDetails)
  const savedpersonalShopperDetails = useSelector(state => state.cms.savedpersonalShopperDetails)

  const wishlistDetails = useSelector(state => state.cms.wishlistDetails)
  const savedWishlistDetails = useSelector(state => state.cms.savedWishlistDetails)

  const hofDetails = useSelector(state => state.cms.hofDetails)
  const savedhofDetails = useSelector(state => state.cms.savedhofDetails)
  const countDownDetails = useSelector(state => state.cms.countDownDetails)
  const savedCountDownDetails = useSelector(state => state.cms.savedCountDownDetails)
  const colorExampleStyle = { border: '0.5px solid #e4e7ea', paddingLeft: 40, marginLeft: 5 }
  const [allLanguages, setAllLanguages] = useState([])
  const [languages, setLanguages] = useState([])
  const [frontBanner, setFrontBanner] = useState([{}])
  const [addFrontBanner, setAddFrontBanner] = useState(false)
  const [loginData, setLoginData] = useState([{}])
  const [addLoginData, setAddLoginData] = useState(false)
  const [hofData, setHofData] = useState([{}])
  const [addHofData, setAddHofData] = useState(false)
  const [countDownData, setCountDownData] = useState([{}])
  const [addCountDownData, setAddCountDownData] = useState(false)
  const [personalShopperData, setPersonalShopperData] = useState([{}])
  const [addPersonalShopperData, setAddPersonalShopperData] = useState(false)

  useEffect(() => {
    dispatch(fetchData(settings.BrandID))
    dispatch(fetchShops())
    dispatch(fetchShopCustomisationData(settings.BrandID))
    dispatch(fetchHeaderConfigDetails(settings.BrandID))
    dispatch(fetchLoginPageCustomisationData(settings.BrandID))
    dispatch(fetchFooterCustomisationData(settings.BrandID))
    dispatch(fetchColorsCustomisationData(settings.BrandID))
    dispatch(fetchProfileCustomisationData(settings.BrandID))
    dispatch(fetchPersonalShopperCustomisationData(settings.BrandID))
    dispatch(fetchhofCustomisationData(settings.BrandID))
    dispatch(fetchCountDownCustomisationData(settings.BrandID))
  }, [])

  useEffect(() => {
    if (loadingState === STATUS.CMS_SUCCESS) {
      toast.success('Successfully Saved', {
        autoClose: 7500,
        onClose: () => {
          dispatch(clearToast())
        }
      })
      dispatch(clearToast())
    }
    if (loadingState === STATUS.CMS_FAILED) {
      toast.error('Error while saving Banner Content', {
        autoClose: 7500,
        onClose: () => {
          dispatch(clearToast())
        }
      })
      dispatch(clearToast())
    }
  }, [loadingState])

  const fetchAllLanguage = useCallback(() => {
    Proxies.GET('schemas/languages').then(responseJSON => {
      if (!responseJSON.error) {
        return setAllLanguages(responseJSON)
      }
    })
  }, [])

  const fetchLanguages = useCallback(item => {
    let BrandID = platformData && platformData.BrandID ? platformData.BrandID : null
    Proxies.GET('brands/fetchBrandLanguages', { BrandID: BrandID }).then(responseJSON => {
      if (!responseJSON.error) {
        return setLanguages(item.filter(obj => responseJSON.includes(obj.LanguageID)))
      }
    })
  }, [])

  const uploadLoginImageURL = (data, fieldName, name) => {
    let file = data.target.files[0]
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        dispatch(
          updateClubCmsContent({
            fieldName: fieldName,
            name: name,
            value: responseJSON.imageOriginSource ? responseJSON.imageOriginSource : ''
          })
        )
      }
    })
  }

  const themeDefaultColors = {
    largeScreenBackground: '#E8E8E8',
    headerBackground: '#FFFFFF',
    ThemeONE: {
      primaryColor1: '#383693',
      primaryColor2: '#4A48C4',
      secondaryColor1: '#E56B2E',
      secondaryColor2: '#B25324',
      tertiaryColor1: '#27454b'
    },
    ThemeTWO: {
      primaryColor1: '#7FA894',
      primaryColor2: '#597568',
      secondaryColor1: '#2B3D52',
      secondaryColor2: '#466385',
      tertiaryColor1: '#27454b'
    },
    ThemeTHREE: {
      primaryColor1: '#F2DFD8',
      primaryColor2: '#D9C8C1',
      secondaryColor1: '#FF906C',
      secondaryColor2: '#CC7356',
      tertiaryColor1: '#27454b'
    },
    ThemeFOUR: {
      primaryColor1: '#383693',
      primaryColor2: '#4A48C4',
      secondaryColor1: '#E56B2E',
      secondaryColor2: '#B25324',
      tertiaryColor1: '#27454b'
    }
  }

  useEffect(() => {
    fetchAllLanguage()
  }, [])

  useEffect(() => {
    if (allLanguages.length > 0) {
      fetchLanguages(allLanguages)
    }
  }, [allLanguages])

  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Toggle features and screens On/Off</strong>{' '}
          </React.Fragment>
        }
      >
        <FeatureToggles />
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>GDPR & News consent text</strong>{' '}
          </React.Fragment>
        }
      >
        <GdprTextConfiguration />
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Front page banner</strong>{' '}
          </React.Fragment>
        }
      >
        <Button
          className='float-right'
          onClick={() => {
            setAddFrontBanner(true)
            setFrontBanner([
              ...frontBanner,
              {
                languageKey: '',
                frontPageBannerTitle: '',
                frontPageBannerContent: ''
              }
            ])
          }}
        >
          <FontAwesomeIcon icon='plus' />
        </Button>
        <Form
          style={{ marginTop: '5%' }}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          {frontBanner.map((event, idx) => (
            <FrontBannerMultiple
              key={idx}
              event={event}
              idx={idx}
              languages={languages}
              addFrontBanner={addFrontBanner}
              setAddFrontBanner={setAddFrontBanner}
              frontPageBannerTitleData={cmsData.frontPageBannerTitle}
              frontPageBannerContentData={cmsData.frontPageBannerContent}
              frontBanner={frontBanner}
              setFrontBanner={setFrontBanner}
            />
          ))}
          <FormGroup row>
            <Label for='FrontPageTextColor' sm={3}>
              FrontPage BannerTextColor
            </Label>
            <Col>
              <FormField
                name='White'
                field='frontPageTextColorBlack'
                data={cmsData}
                type='checkbox'
                unCheckedValue={true}
                checkedValue={false}
                onChange={e => {
                  dispatch(updateBannerContent({ name: e.target.name, value: e.target.value }))
                }}
              />
              <FormField
                name='Black'
                field='frontPageTextColorBlack'
                data={cmsData}
                type='checkbox'
                unCheckedValue={false}
                checkedValue={true}
                onChange={e => {
                  dispatch(updateBannerContent({ name: e.target.name, value: e.target.value }))
                }}
              />
            </Col>
          </FormGroup>
        </Form>
        <Button
          style={{ marginTop: '1rem' }}
          onClick={() => {
            dispatch(saveData(cmsData, settings, brandCmsData))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Wishlist banner</strong>{' '}
          </React.Fragment>
        }
      >
        <Form
          style={{ marginTop: '5%' }}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormGroup row>
            <Label for='wishlistBannerImage' sm={3}>
              Image{' '}
              <span>
                <FontAwesomeIcon icon='info-circle' id='wishlistImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='wishlistImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
              </span>
            </Label>
            <Col sm={9}>
              <Input
                name='wishlistBannerImageURL'
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={wishlistDetails.wishlistBannerImageURL !== ''}
                onChange={e => {
                  uploadLoginImageURL(e, 'wishlistDetails', 'wishlistBannerImageURL')
                }}
                accept='image/*'
              />
              <img
                src={wishlistDetails.wishlistBannerImageURL}
                alt={wishlistDetails.wishlistBannerImageURL}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: wishlistDetails.wishlistBannerImageURL ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='WishlistTextColor' sm={3}>
              Wishlist banner TextColor
            </Label>
            <Col>
              <FormField
                name='White'
                field='wishlistTextColorBlack'
                data={wishlistDetails}
                type='checkbox'
                unCheckedValue={true}
                checkedValue={false}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'wishlistDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <FormField
                name='Black'
                field='wishlistTextColorBlack'
                data={wishlistDetails}
                type='checkbox'
                unCheckedValue={false}
                checkedValue={true}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'wishlistDetails', name: e.target.name, value: e.target.value }))
                }}
              />
            </Col>
          </FormGroup>
        </Form>
        <Button
          onClick={() => {
            dispatch(saveClubCMSData(wishlistDetails, settings, savedWishlistDetails, 'Wishlist'))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Header</strong>{' '}
          </React.Fragment>
        }
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormGroup row>
            <Label for='brandLogoSize' sm={2}>
              Logo Size
            </Label>
            <Col sm={10}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='brandLogoHeight'
                    id='brandLogoHeight'
                    value={headerLogoSizeData.brandLogoHeight ? headerLogoSizeData.brandLogoHeight : ''}
                    onChange={e => {
                      dispatch(updateHeaderConfig({ name: 'brandLogoHeight', value: e.target.value }))
                    }}
                    placeholder='Height in px'
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='brandLogoWidth'
                    id='brandLogoWidth'
                    value={headerLogoSizeData.brandLogoWidth ? headerLogoSizeData.brandLogoWidth : ''}
                    onChange={e => {
                      dispatch(updateHeaderConfig({ name: 'brandLogoWidth', value: e.target.value }))
                    }}
                    placeholder='Width in px'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='themeOneMenuBarColors' sm={2}>
              Menubar Color
            </Label>
            <Col sm={10}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='themeOneMenuBarBackgroundColor'
                    id='themeOneMenuBarBackgroundColor'
                    value={themeOneMenuBarColors.themeOneMenuBarBackgroundColor ? themeOneMenuBarColors.themeOneMenuBarBackgroundColor : ''}
                    onChange={e => {
                      dispatch(updateHeaderConfig({ name: 'themeOneMenuBarBackgroundColor', value: e.target.value }))
                    }}
                  />
                  <Fade>Background Color</Fade>
                </Col>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='themeOneMenuBarTextColor'
                    id='themeOneMenuBarTextColor'
                    value={themeOneMenuBarColors.themeOneMenuBarTextColor ? themeOneMenuBarColors.themeOneMenuBarTextColor : ''}
                    onChange={e => {
                      dispatch(updateHeaderConfig({ name: 'themeOneMenuBarTextColor', value: e.target.value }))
                    }}
                  />
                  <Fade>Text Color</Fade>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </Form>
        <Button
          onClick={() => {
            dispatch(saveHeaderConfigData({ ...headerLogoSizeData, ...themeOneMenuBarColors }, settings, savedHeaderConfigDetails))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Login Page</strong>{' '}
          </React.Fragment>
        }
      >
        <Button
          className='float-right'
          onClick={() => {
            setAddLoginData(true)
            setLoginData([
              ...loginData,
              {
                languageKey: '',
                loginPageTitle: '',
                loginPageDescription: ''
              }
            ])
          }}
        >
          <FontAwesomeIcon icon='plus' />
        </Button>
        <Form
          style={{ marginTop: '5%' }}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          {loginData.map((event, idx) => (
            <LoginMultiple
              key={idx}
              event={event}
              idx={idx}
              languages={languages}
              addLoginData={addLoginData}
              setAddLoginData={setAddLoginData}
              loginPageTitleData={loginPageDetails.loginPageTitle}
              loginPageContentData={loginPageDetails.loginPageDescription}
              loginData={loginData}
              setLoginData={setLoginData}
            />
          ))}
          <FormGroup row>
            <Label for='brandLogoPosition' sm={3}>
              Brand Logo Position
            </Label>
            <Col sm={9}>
              <CustomInput
                type='select'
                id='brandLogoPosition'
                name='brandLogoPosition'
                value={loginPageDetails.brandLogoPosition ? loginPageDetails.brandLogoPosition : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: e.target.name, value: e.target.value }))
                }}
              >
                <option value={null}>System Default</option>
                {['Right', 'Left'].map((e, i) => {
                  return (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  )
                })}
              </CustomInput>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='loginPageLogoSize' sm={3}>
              Logo Size
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='loginPageBrandLogoHeight'
                    id='loginPageBrandLogoHeight'
                    value={loginPageDetails.loginPageBrandLogoHeight ? loginPageDetails.loginPageBrandLogoHeight : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Height in px'
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='loginPageBrandLogoWidth'
                    id='loginPageBrandLogoWidth'
                    value={loginPageDetails.loginPageBrandLogoWidth ? loginPageDetails.loginPageBrandLogoWidth : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Width in px'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='loginImageURL' sm={3}>
              Login Image URL
              <span>
                <FontAwesomeIcon icon='info-circle' id='loginImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='loginImageTooltip'>Best Size for Image: 911 x 900 </UncontrolledTooltip>
              </span>
            </Label>
            <Col sm={9}>
              <Input
                name='loginImageURL'
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={loginPageDetails.loginImageURL !== ''}
                onChange={e => {
                  uploadLoginImageURL(e, 'loginPageDetails', 'loginImageURL')
                }}
                accept='image/*'
              />
              <img
                src={loginPageDetails.loginImageURL}
                alt={loginPageDetails.loginImageURL}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: loginPageDetails.loginImageURL ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='LoginPageTextColor' sm={3}>
              LoginPage TextColor
            </Label>
            <Col>
              <FormField
                name='White'
                field='loginPageTextColorBlack'
                data={loginPageDetails}
                type='checkbox'
                unCheckedValue={true}
                checkedValue={false}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <FormField
                name='Black'
                field='loginPageTextColorBlack'
                data={loginPageDetails}
                type='checkbox'
                unCheckedValue={false}
                checkedValue={true}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: e.target.name, value: e.target.value }))
                }}
              />
            </Col>
          </FormGroup>
        </Form>
        <Button
          onClick={() => {
            dispatch(saveClubCMSData(loginPageDetails, settings, savedLoginPageDetails, 'LoginPage'))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Footer</strong>{' '}
          </React.Fragment>
        }
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormGroup row>
            <Label for='footerLogoSize' sm={3}>
              Logo Size
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='footerBrandLogoHeight'
                    id='footerBrandLogoHeight'
                    value={footerDetails.footerBrandLogoHeight ? footerDetails.footerBrandLogoHeight : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Height in px'
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='footerBrandLogoWidth'
                    id='footerBrandLogoWidth'
                    value={footerDetails.footerBrandLogoWidth ? footerDetails.footerBrandLogoWidth : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Width in px'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormField
            name='Facebook URL'
            onChange={e => {
              dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
            }}
            field='facebookURL'
            data={footerDetails}
          />
          <FormField
            name='Twitter URL'
            onChange={e => {
              dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
            }}
            field='twitterURL'
            data={footerDetails}
          />
          <FormField
            name='Instagram URL'
            onChange={e => {
              dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
            }}
            field='instagramURL'
            data={footerDetails}
          />
          <FormField
            name='LinkedIn URL'
            onChange={e => {
              dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
            }}
            field='linkedInURL'
            data={footerDetails}
          />
          <Row style={{ margin: '2.5rem 0' }}>
            <Col sm={6}>
              <Input
                type='color'
                name='footerBgColor'
                id='footerBgColor'
                value={footerDetails.footerBgColor ? footerDetails.footerBgColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>Footer background color</Fade>
              <Fade>{footerDetails && !footerDetails.footerBgColor && <em>Color not set and so default color will be used on Club</em>}</Fade>
            </Col>
            <Col sm={6}>
              <Input
                type='color'
                name='footerTextColor'
                id='footerTextColor'
                value={footerDetails.footerTextColor ? footerDetails.footerTextColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'footerDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>Footer text color</Fade>
              <Fade>{footerDetails && !footerDetails.footerTextColor && <em>Color not set and so default color will be used on Club</em>}</Fade>
            </Col>
          </Row>
        </Form>
        <Button
          onClick={() => {
            dispatch(saveClubCMSData(footerDetails, settings, savedFooterDetails, 'Footer'))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Colors</strong>{' '}
          </React.Fragment>
        }
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormGroup row>
            <Label for='footerLogoSize' sm={3}>
              Primary Color: <br />
              <Button
                color='light'
                style={{ marginTop: 20 }}
                onClick={() => {
                  ;['primaryColor1', 'primaryColor2'].forEach(name => {
                    dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: name, value: null }))
                  })
                }}
              >
                Reset to default
              </Button>
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='primaryColor1'
                    id='primaryColor1'
                    value={
                      themeOverrides.primaryColor1
                        ? themeOverrides.primaryColor1
                        : (themeDefaultColors[settings.features && settings.features.BrandTheme] &&
                            themeDefaultColors[settings.features && settings.features.BrandTheme].primaryColor1) ||
                          ''
                    }
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: e.target.name, value: e.target.value }))
                    }}
                  />
                  <Fade tag='small'>
                    Primary Color 1 for your Club theme.
                    <br /> Used for background of large elements like header & footer.
                    <br />
                    <em>
                      Default values are,
                      <br />
                      ThemeONE : #383693 - <span style={{ ...colorExampleStyle, backgroundColor: '#383693' }} />
                      <br />
                      ThemeTWO : #7FA894 - <span style={{ ...colorExampleStyle, backgroundColor: '#7FA894' }} />
                      <br />
                      ThemeTHREE : #F2DFD8 - <span style={{ ...colorExampleStyle, backgroundColor: '#F2DFD8' }} />
                      <br />
                      ThemeFOUR : #383693 - <span style={{ ...colorExampleStyle, backgroundColor: '#383693' }} />
                      <br />
                    </em>
                  </Fade>
                </Col>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='primaryColor2'
                    id='primaryColor2'
                    value={
                      themeOverrides.primaryColor2
                        ? themeOverrides.primaryColor2
                        : (themeDefaultColors[settings.features && settings.features.BrandTheme] &&
                            themeDefaultColors[settings.features && settings.features.BrandTheme].primaryColor2) ||
                          ''
                    }
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: e.target.name, value: e.target.value }))
                    }}
                  />
                  <Fade tag='small'>
                    Primary Color 2 for your Club theme.
                    <br /> Used for scenarios like hover. <br />
                    <em>
                      Default values are,
                      <br />
                      ThemeONE : #4A48C4 - <span style={{ ...colorExampleStyle, backgroundColor: '#4A48C4' }} /> <br />
                      ThemeTWO : #597568 - <span style={{ ...colorExampleStyle, backgroundColor: '#597568' }} /> <br />
                      ThemeTHREE : #D9C8C1 - <span style={{ ...colorExampleStyle, backgroundColor: '#D9C8C1' }} /> <br />
                      ThemeFOUR : #4A48C4 - <span style={{ ...colorExampleStyle, backgroundColor: '#4A48C4' }} /> <br />
                    </em>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginTop: '5rem' }}>
            <Label for='footerLogoSize' sm={3}>
              Secondary Color: <br />
              <Button
                color='light'
                style={{ marginTop: 20 }}
                onClick={() => {
                  ;['secondaryColor1', 'secondaryColor2'].forEach(name => {
                    dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: name, value: null }))
                  })
                }}
              >
                Reset to default
              </Button>
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='secondaryColor1'
                    id='secondaryColor1'
                    value={
                      themeOverrides.secondaryColor1
                        ? themeOverrides.secondaryColor1
                        : (themeDefaultColors[settings.features && settings.features.BrandTheme] &&
                            themeDefaultColors[settings.features && settings.features.BrandTheme].secondaryColor1) ||
                          ''
                    }
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: e.target.name, value: e.target.value }))
                    }}
                  />
                  <Fade tag='small'>
                    Secondary Color 1 for your Club theme.
                    <br /> Used for form elements like buttons.
                    <br />
                    <em>
                      Default values are,
                      <br />
                      ThemeONE : #E56B2E - <span style={{ ...colorExampleStyle, backgroundColor: '#E56B2E' }} /> <br />
                      ThemeTWO : #2B3D52 - <span style={{ ...colorExampleStyle, backgroundColor: '#2B3D52' }} /> <br />
                      ThemeTHREE : #FF906C - <span style={{ ...colorExampleStyle, backgroundColor: '#FF906C' }} /> <br />
                      ThemeFOUR : #E56B2E - <span style={{ ...colorExampleStyle, backgroundColor: '#E56B2E' }} /> <br />
                    </em>
                  </Fade>
                </Col>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='secondaryColor2'
                    id='secondaryColor2'
                    value={
                      themeOverrides.secondaryColor2
                        ? themeOverrides.secondaryColor2
                        : (themeDefaultColors[settings.features && settings.features.BrandTheme] &&
                            themeDefaultColors[settings.features && settings.features.BrandTheme].secondaryColor2) ||
                          ''
                    }
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: e.target.name, value: e.target.value }))
                    }}
                  />
                  <Fade tag='small'>
                    Secondary Color 2 for your Club theme.
                    <br /> Used for scenarios like button hover
                    <br />
                    <em>
                      Default values are,
                      <br />
                      ThemeONE : #B25324 - <span style={{ ...colorExampleStyle, backgroundColor: '#B25324' }} /> <br />
                      ThemeTWO : #466385 - <span style={{ ...colorExampleStyle, backgroundColor: '#466385' }} /> <br />
                      ThemeTHREE : #CC7356 - <span style={{ ...colorExampleStyle, backgroundColor: '#CC7356' }} /> <br />
                      ThemeFOUR : #B25324 - <span style={{ ...colorExampleStyle, backgroundColor: '#B25324' }} /> <br />
                    </em>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: '5rem' }}>
            <Label for='footerLogoSize' sm={3}>
              Tertiary Color: <br />
              <Button
                color='light'
                style={{ marginTop: 20 }}
                onClick={() => {
                  ;['tertiaryColor1'].forEach(name => {
                    dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: name, value: null }))
                  })
                }}
              >
                Reset to default
              </Button>
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='tertiaryColor1'
                    id='tertiaryColor1'
                    value={
                      themeOverrides.tertiaryColor1
                        ? themeOverrides.tertiaryColor1
                        : (themeDefaultColors[settings.features && settings.features.BrandTheme] &&
                            themeDefaultColors[settings.features && settings.features.BrandTheme].tertiaryColor1) ||
                          ''
                    }
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: e.target.name, value: e.target.value }))
                    }}
                  />
                  <Fade tag='small'>
                    Tertiary Color 1 for your Club theme.
                    <br /> Used in profile page.
                    <br />
                    <em>
                      Default values are,
                      <br />
                      ThemeONE : #27454b - <span style={{ ...colorExampleStyle, backgroundColor: '#27454b' }} /> <br />
                      ThemeTWO : #2B3D52 - <span style={{ ...colorExampleStyle, backgroundColor: '#2B3D52' }} /> <br />
                      ThemeTHREE : #FF906C - <span style={{ ...colorExampleStyle, backgroundColor: '#FF906C' }} /> <br />
                      ThemeFOUR : #E56B2E - <span style={{ ...colorExampleStyle, backgroundColor: '#E56B2E' }} /> <br />
                    </em>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: '5rem' }}>
            <Label for='footerLogoSize' sm={3}>
              Large screen background: <br />
              <Button
                color='light'
                style={{ marginTop: 20 }}
                onClick={() => {
                  ;['largeScreenBackground'].forEach(name => {
                    dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: name, value: null }))
                  })
                }}
              >
                Reset to default
              </Button>
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='largeScreenBackground'
                    id='largeScreenBackground'
                    value={themeOverrides.largeScreenBackground ? themeOverrides.largeScreenBackground : themeDefaultColors.largeScreenBackground}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: e.target.name, value: e.target.value }))
                    }}
                  />
                  <Fade tag='small'>
                    This color will be used to fill the empty space on left & right side of your club when viewing in a large screen.
                    <br />
                    Large screen : Resolution with width greater than 1920px.
                    <br />
                    Eg. Width x Height <br />
                    1920 x 1080, <br />
                    2560 x 1440, <br />
                    3840 x 2160 <br />
                    <em>
                      <br />
                      Default color is, #E8E8E8 - <span style={{ ...colorExampleStyle, backgroundColor: themeDefaultColors.largeScreenBackground }} />
                      <br />
                    </em>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: '5rem' }}>
            <Label for='footerLogoSize' sm={3}>
              Header background: <br />
              <Button
                color='light'
                style={{ marginTop: 20 }}
                onClick={() => {
                  ;['headerBackground'].forEach(name => {
                    dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: name, value: 'transparent' }))
                  })
                }}
              >
                Transparent
              </Button>
              <br />
              <Button
                color='light'
                style={{ marginTop: 20 }}
                onClick={() => {
                  ;['headerBackground'].forEach(name => {
                    dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: name, value: '#FFFFFF' }))
                  })
                }}
              >
                Reset to default
              </Button>
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='color'
                    name='headerBackground'
                    id='headerBackground'
                    value={themeOverrides.headerBackground ? themeOverrides.headerBackground : themeDefaultColors.headerBackground}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'themeOverrides', name: e.target.name, value: e.target.value }))
                    }}
                  />
                  <Fade tag='small'>
                    <em>{themeOverrides?.headerBackground?.toLowerCase() === `transparent` ? `Header is now set transparent background` : ``}</em>
                    <br />
                    This color will be used as the background on the section of header where logo is placed.
                    <br />
                    <em>
                      <br />
                      Default color is, WHITE - <span style={{ ...colorExampleStyle, backgroundColor: themeDefaultColors.headerBackground }} />
                      <br />
                    </em>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </Form>
        <Button
          onClick={() => {
            dispatch(saveClubCMSData(themeOverrides, settings, savedthemeOverrides, 'Colors'))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Profile</strong>{' '}
          </React.Fragment>
        }
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormGroup row>
            <Label for='profileBannerImage' sm={3}>
              Image{' '}
              <span>
                <FontAwesomeIcon icon='info-circle' id='profileImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='profileImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
              </span>
            </Label>
            <Col sm={9}>
              <Input
                name='profileBannerImageURL'
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={profileDetails.profileBannerImageURL !== ''}
                onChange={e => {
                  uploadLoginImageURL(e, 'profileDetails', 'profileBannerImageURL')
                }}
                accept='image/*'
              />
              <img
                src={profileDetails.profileBannerImageURL}
                alt={profileDetails.profileBannerImageURL}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: profileDetails.profileBannerImageURL ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='profileBannerImage' sm={3}>
              Image Size
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='profileBannerImageHeight'
                    id='profileBannerImageHeight'
                    value={profileDetails.profileBannerImageHeight ? profileDetails.profileBannerImageHeight : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Height in px'
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='profileBannerImageWidth'
                    id='profileBannerImageWidth'
                    value={profileDetails.profileBannerImageWidth ? profileDetails.profileBannerImageWidth : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Width in px'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='profileProductsInfoBannerImage' sm={3}>
              Products Info Banner Image{' '}
              <span>
                <FontAwesomeIcon icon='info-circle' id='productInfoImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='productInfoImageTooltip'>Best Size for Image: 800 x 500 </UncontrolledTooltip>
              </span>
            </Label>
            <Col sm={9}>
              <Input
                name='profileProductsInfoBannerImage'
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={profileDetails.profileProductsInfoBannerImage !== ''}
                onChange={e => {
                  uploadLoginImageURL(e, 'profileDetails', 'profileProductsInfoBannerImage')
                }}
                accept='image/*'
              />
              <img
                src={profileDetails.profileProductsInfoBannerImage}
                alt={profileDetails.profileProductsInfoBannerImage}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: profileDetails.profileProductsInfoBannerImage ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='PointExpiryGrid' sm={3}>
              Profile Grid v2
            </Label>
            <Col sm={9}>
              <StyledCustomInput
                type='switch'
                checked={profileDetails.PointExpiryGrid}
                id='PointExpiryGrid'
                name='PointExpiryGrid'
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.checked }))
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='profileNote' sm={3}>
              Profile Note
            </Label>
            <Col sm={2}>
              <StyledCustomInput
                type='switch'
                checked={profileDetails.enableProfileNote}
                id='enableProfileNote'
                name='enableProfileNote'
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.checked }))
                }}
              />
            </Col>
            <Col sm={7}>
              <Row>
                <Col>
                  <Input
                    disabled={!profileDetails.enableProfileNote}
                    type='textarea'
                    name='profileNoteText'
                    id='profileNoteText'
                    value={profileDetails.profileNoteText ? profileDetails.profileNoteText : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Profile Note'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <Row style={{ margin: '2.5rem 0' }}>
            <Col sm={6}>
              <Input
                type='color'
                name='activatedPointsBgColor'
                id='activatedPointsBgColor'
                value={profileDetails.activatedPointsBgColor ? profileDetails.activatedPointsBgColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Activated point" background color`}</Fade>
            </Col>
            <Col sm={6}>
              <Input
                type='color'
                name='activatedPointsTextColor'
                id='activatedPointsTextColor'
                value={profileDetails.activatedPointsTextColor ? profileDetails.activatedPointsTextColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Activated point" text color`}</Fade>
            </Col>
          </Row>

          <Row style={{ margin: '2.5rem 0' }}>
            <Col sm={6}>
              <Input
                type='color'
                name='pointsForActivationBgColor'
                id='pointsForActivationBgColor'
                value={profileDetails.pointsForActivationBgColor ? profileDetails.pointsForActivationBgColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Point for activation" background color`}</Fade>
            </Col>
            <Col sm={6}>
              <Input
                type='color'
                name='pointsForActivationTextColor'
                id='pointsForActivationTextColor'
                value={profileDetails.pointsForActivationTextColor ? profileDetails.pointsForActivationTextColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Point for activation" text color`}</Fade>
            </Col>
          </Row>

          <Row style={{ margin: '2.5rem 0' }}>
            <Col sm={6}>
              <Input
                type='color'
                name='shoppingPointsBgColor'
                id='shoppingPointsBgColor'
                value={profileDetails.shoppingPointsBgColor ? profileDetails.shoppingPointsBgColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Points for shopping" background color`}</Fade>
            </Col>
            <Col sm={6}>
              <Input
                type='color'
                name='shoppingPointsTextColor'
                id='shoppingPointsTextColor'
                value={profileDetails.shoppingPointsTextColor ? profileDetails.shoppingPointsTextColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Points for shopping" text color`}</Fade>
            </Col>
          </Row>

          <Row style={{ margin: '2.5rem 0' }}>
            <Col sm={6}>
              <Input
                type='color'
                name='currentYearActivatedPointsBgColor'
                id='currentYearActivatedPointsBgColor'
                value={profileDetails.currentYearActivatedPointsBgColor ? profileDetails.currentYearActivatedPointsBgColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Current year - Activated point" background color`}</Fade>
            </Col>
            <Col sm={6}>
              <Input
                type='color'
                name='currentYearActivatedPointsTextColor'
                id='currentYearActivatedPointsTextColor'
                value={profileDetails.currentYearActivatedPointsTextColor ? profileDetails.currentYearActivatedPointsTextColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Current year - Activated point" text color`}</Fade>
            </Col>
          </Row>

          <Row style={{ margin: '2.5rem 0' }}>
            <Col sm={6}>
              <Input
                type='color'
                name='pointsAvailableForActivationBgColor'
                id='pointsAvailableForActivationBgColor'
                value={profileDetails.pointsAvailableForActivationBgColor ? profileDetails.pointsAvailableForActivationBgColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Points available for activation" background color`}</Fade>
            </Col>
            <Col sm={6}>
              <Input
                type='color'
                name='pointsAvailableForActivationTextColor'
                id='pointsAvailableForActivationTextColor'
                value={profileDetails.pointsAvailableForActivationTextColor ? profileDetails.pointsAvailableForActivationTextColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Points available for activation" text color`}</Fade>
            </Col>
          </Row>

          <Row style={{ margin: '2.5rem 0' }}>
            <Col sm={6}>
              <Input
                type='color'
                name='expectedTaxBgColor'
                id='expectedTaxBgColor'
                value={profileDetails.expectedTaxBgColor ? profileDetails.expectedTaxBgColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Expected tax calculation" background color`}</Fade>
            </Col>
            <Col sm={6}>
              <Input
                type='color'
                name='expectedTaxTextColor'
                id='expectedTaxTextColor'
                value={profileDetails.expectedTaxTextColor ? profileDetails.expectedTaxTextColor : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'profileDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <Fade>{`"Expected tax calculation" text color`}</Fade>
            </Col>
          </Row>
        </Form>
        <Button
          onClick={() => {
            dispatch(saveClubCMSData(profileDetails, settings, savedProfileDetails, 'Profile'))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Personal Shopper</strong>{' '}
          </React.Fragment>
        }
      >
        <Button
          className='float-right'
          onClick={() => {
            setAddPersonalShopperData(true)
            setPersonalShopperData([
              ...personalShopperData,
              {
                languageKey: '',
                personalShopperDescription: ''
              }
            ])
          }}
        >
          <FontAwesomeIcon icon='plus' />
        </Button>
        <Form
          style={{ marginTop: '5%' }}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          {personalShopperData.map((event, idx) => (
            <PersonalShopperMultiple
              key={idx}
              event={event}
              idx={idx}
              languages={languages}
              addPersonalShopperData={addPersonalShopperData}
              setAddPersonalShopperData={setAddPersonalShopperData}
              personalShopperPageContentData={personalShopperDetails.personalShopperDescription}
              personalShopperData={personalShopperData}
              setPersonalShopperData={setPersonalShopperData}
            />
          ))}
          <FormGroup row>
            <Label for='personalShopperBannerImage' sm={3}>
              Image{' '}
              <span>
                <FontAwesomeIcon icon='info-circle' id='personelShopperImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='personelShopperImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
              </span>
            </Label>
            <Col sm={9}>
              <Input
                name='personalShopperBannerImageURL'
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={personalShopperDetails.personalShopperBannerImageURL !== ''}
                onChange={e => {
                  uploadLoginImageURL(e, 'personalShopperDetails', 'personalShopperBannerImageURL')
                }}
                accept='image/*'
              />
              <img
                src={personalShopperDetails.personalShopperBannerImageURL}
                alt={personalShopperDetails.personalShopperBannerImageURL}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: personalShopperDetails.personalShopperBannerImageURL ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='personalShopperBannerImage' sm={3}>
              Image Size
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='personalShopperImageHeight'
                    id='personalShopperImageHeight'
                    value={personalShopperDetails.personalShopperImageHeight ? personalShopperDetails.personalShopperImageHeight : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'personalShopperDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Height in px'
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='personalShopperImageWidth'
                    id='personalShopperImageWidth'
                    value={personalShopperDetails.personalShopperImageWidth ? personalShopperDetails.personalShopperImageWidth : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'personalShopperDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Width in px'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='PersonalShopperTextColor' sm={3}>
              PersonalShopper TextColor
            </Label>
            <Col>
              <FormField
                name='White'
                field='personalShopperTextColorBlack'
                data={personalShopperDetails}
                type='checkbox'
                unCheckedValue={true}
                checkedValue={false}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'personalShopperDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <FormField
                name='Black'
                field='personalShopperTextColorBlack'
                data={personalShopperDetails}
                type='checkbox'
                unCheckedValue={false}
                checkedValue={true}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'personalShopperDetails', name: e.target.name, value: e.target.value }))
                }}
              />
            </Col>
          </FormGroup>
        </Form>
        <Button
          onClick={() => {
            dispatch(saveClubCMSData(personalShopperDetails, settings, savedpersonalShopperDetails, 'Personal Shopper'))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Hall Of Fame</strong>{' '}
          </React.Fragment>
        }
      >
        <Button
          className='float-right'
          onClick={() => {
            setAddHofData(true)
            setHofData([
              ...hofData,
              {
                languageKey: '',
                hofTitle: '',
                hofDescription: ''
              }
            ])
          }}
        >
          <FontAwesomeIcon icon='plus' />
        </Button>
        <Form
          style={{ marginTop: '5%' }}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          {hofData.map((event, idx) => (
            <HofMultiple
              key={idx}
              event={event}
              idx={idx}
              languages={languages}
              addHofData={addHofData}
              setAddHofData={setAddHofData}
              hofPageTitleData={hofDetails.hofTitle}
              hofPageContentData={hofDetails.hofDescription}
              hofData={hofData}
              setHofData={setHofData}
            />
          ))}
          <FormGroup row>
            <Label for='hofBannerImage' sm={3}>
              Image{' '}
              <span>
                <FontAwesomeIcon icon='info-circle' id='hofBannerImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='hofBannerImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
              </span>
            </Label>
            <Col sm={9}>
              <Input
                name='hofBannerImageURL'
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={hofDetails.hofBannerImageURL !== ''}
                onChange={e => {
                  uploadLoginImageURL(e, 'hofDetails', 'hofBannerImageURL')
                }}
                accept='image/*'
              />
              <img
                src={hofDetails.hofBannerImageURL}
                alt={hofDetails.hofBannerImageURL}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: hofDetails.hofBannerImageURL ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='hofBannerImage' sm={3}>
              Image Size
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='hofImageHeight'
                    id='hofImageHeight'
                    value={hofDetails.hofImageHeight ? hofDetails.hofImageHeight : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Height in px'
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='hofImageWidth'
                    id='hofImageWidth'
                    value={hofDetails.hofImageWidth ? hofDetails.hofImageWidth : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Width in px'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </Form>
        <FormGroup row>
          <Label for='hofTextColor' sm={3}>
            Hall Of Fame TextColor
          </Label>
          <Col>
            <FormField
              name='White'
              field='hofTextColorBlack'
              data={hofDetails}
              type='checkbox'
              unCheckedValue={true}
              checkedValue={false}
              onChange={e => {
                dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: e.target.name, value: e.target.value }))
              }}
            />
            <FormField
              name='Black'
              field='hofTextColorBlack'
              data={hofDetails}
              type='checkbox'
              unCheckedValue={false}
              checkedValue={true}
              onChange={e => {
                dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: e.target.name, value: e.target.value }))
              }}
            />
          </Col>
        </FormGroup>
        <Button
          onClick={() => {
            dispatch(saveClubCMSData(hofDetails, settings, savedhofDetails, 'HallOfFame'))
          }}
        >
          Gem
        </Button>
      </CollapsibleCard>

      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Count Down</strong>{' '}
          </React.Fragment>
        }
      >
        <Button
          className='float-right'
          onClick={() => {
            setAddCountDownData(true)
            setCountDownData([
              ...countDownData,
              {
                languageKey: '',
                countDownTitle: '',
                countDownDescription: ''
              }
            ])
          }}
        >
          <FontAwesomeIcon icon='plus' />
        </Button>
        <Form
          style={{ marginTop: '5%' }}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormGroup row>
            <Label for='isCountDown' sm={3}>
              Count Down
            </Label>
            <Col sm={9}>
              <StyledCustomInput
                type='switch'
                checked={countDownDetails.isCountDown}
                id='isCountDown'
                name='isCountDown'
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.checked }))
                }}
              />
            </Col>
          </FormGroup>
          {countDownData.map((event, idx) => (
            <CountDownMultiple
              key={idx}
              event={event}
              idx={idx}
              languages={languages}
              addCountDownData={addCountDownData}
              setAddCountDownData={setAddCountDownData}
              countDownPageTitleData={countDownDetails.countDownTitle}
              countDownPageContentData={countDownDetails.countDownDescription}
              countDownData={countDownData}
              setCountDownData={setCountDownData}
            />
          ))}
          <FormGroup row>
            <Label for='countDownBannerImage' sm={3}>
              Image{' '}
              <span>
                <FontAwesomeIcon icon='info-circle' id='countDownImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='countDownImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
              </span>
            </Label>
            <Col sm={9}>
              <Input
                name='countDownBannerImageURL'
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={countDownDetails.countDownBannerImageURL !== ''}
                onChange={e => {
                  uploadLoginImageURL(e, 'countDownDetails', 'countDownBannerImageURL')
                }}
                accept='image/*'
              />
              <img
                draggable='false'
                src={countDownDetails.countDownBannerImageURL}
                alt={countDownDetails.countDownBannerImageURL}
                style={{
                  height: '100px',
                  width: '100px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: countDownDetails.countDownBannerImageURL ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='countDownBannerImage' sm={3}>
              Image Size
            </Label>
            <Col sm={9}>
              <Row>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='countDownImageHeight'
                    id='countDownImageHeight'
                    value={countDownDetails.countDownImageHeight ? countDownDetails.countDownImageHeight : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Height in px'
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='text'
                    name='countDownImageWidth'
                    id='countDownImageWidth'
                    value={countDownDetails.countDownImageWidth ? countDownDetails.countDownImageWidth : ''}
                    onChange={e => {
                      dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                    }}
                    placeholder='Width in px'
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='date' sm={3}>
              Count Down till date
            </Label>
            <Col sm={9}>
              <Input
                type='date'
                name='countDownTimeEnd'
                id='countDownTimeEnd'
                value={countDownDetails.countDownTimeEnd ? countDownDetails.countDownTimeEnd : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='highlightedProductShop' sm={3}>
              Shop
            </Label>
            <Col sm={9}>
              <Input
                id='checked_shopCategory'
                name='countDownButtonTitle'
                type='radio'
                checked={countDownDetails.countDownButtonTitle === 'Se udvalget' ? true : false}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: 'Se udvalget' }))
                }}
              />
              <CustomInput
                type='select'
                id='countDownShopID'
                name='countDownShopID'
                value={countDownDetails.countDownShopID || ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                  dispatch(fetchShopProducts(e.target.value))
                }}
              >
                <option value=''>Select shop</option>
                {brandShops.map((shop, i) => {
                  return (
                    <option value={shop.ShopID} key={i}>
                      {`${shop.moduleName} - ${shop.ShopID}`}
                    </option>
                  )
                })}
              </CustomInput>
            </Col>
          </FormGroup>

          {/* Category */}
          <FormGroup row>
            <Label for='countDownCategoryName' sm={3}>
              Category
            </Label>
            <Col sm={9}>
              <CustomInput
                type='select'
                id='countDownCategoryName'
                name='countDownCategoryName'
                value={countDownDetails.countDownCategoryName ? countDownDetails.countDownCategoryName : ''}
                onChange={e => dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))}
              >
                <option value=''>Select Category</option>
                {shopCategories.map((category, i) => {
                  return (
                    <option value={category.categoryName} key={i}>
                      {`${category.categoryName} - ${category.CategoryID}`}
                    </option>
                  )
                })}
              </CustomInput>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='countDownCompetition' sm={3}>
              Competition
            </Label>
            <Col sm={9}>
              <Input
                id='checked_competition'
                name='countDownButtonTitle'
                type='radio'
                checked={countDownDetails.countDownButtonTitle === 'Se konkurrencen' ? true : false}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: 'Se konkurrencen' }))
                }}
              />
              <Input
                type='text'
                name='countDetailsCompetitionID'
                id='countDetailsCompetitionID'
                value={countDownDetails.countDetailsCompetitionID ? countDownDetails.countDetailsCompetitionID : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                }}
                placeholder='Competition ID'
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='countDownNews' sm={3}>
              News
            </Label>
            <Col sm={9}>
              <Input
                id='checked_News'
                name='countDownButtonTitle'
                type='radio'
                checked={countDownDetails.countDownButtonTitle === 'Se nyheden' ? true : false}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: 'Se nyheden' }))
                }}
              />
              <Input
                type='text'
                name='countDetailsPostID'
                id='countDetailsPostID'
                value={countDownDetails.countDetailsPostID ? countDownDetails.countDetailsPostID : ''}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                }}
                placeholder='News Post ID'
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='countDownTextColor' sm={3}>
              Count Down Text Color
            </Label>
            <Col>
              <FormField
                name='White'
                field='countDownTextColorBlack'
                data={countDownDetails}
                type='checkbox'
                unCheckedValue={true}
                checkedValue={false}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                }}
              />
              <FormField
                name='Black'
                field='countDownTextColorBlack'
                data={countDownDetails}
                type='checkbox'
                unCheckedValue={false}
                checkedValue={true}
                onChange={e => {
                  dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: e.target.name, value: e.target.value }))
                }}
              />
            </Col>
          </FormGroup>
          <Button
            onClick={() => {
              dispatch(saveClubCMSData(countDownDetails, settings, savedCountDownDetails, 'CountDown'))
            }}
          >
            Gem
          </Button>
        </Form>
      </CollapsibleCard>
    </>
  )
}

function FrontBannerMultiple(props) {
  const dispatch = useDispatch()
  const {
    event,
    idx,
    languages,
    frontBanner,
    setFrontBanner,
    frontPageBannerTitleData,
    frontPageBannerContentData,
    addFrontBanner,
    setAddFrontBanner
  } = props
  const [frontBannerDetails, setFrontBannerDetails] = useState(event)

  const fetchCmsData = () => {
    if (typeof frontPageBannerTitleData === 'object' && typeof frontPageBannerContentData === 'object') {
      let titleData = Object.entries(frontPageBannerTitleData).map(([key, value]) => ({
        languageKey: key,
        frontPageBannerTitle: value
      }))

      let contentData = Object.entries(frontPageBannerContentData).map(([key, value]) => ({
        languageKey: key,
        frontPageBannerContent: value
      }))

      let data = titleData.map(item => {
        const content = contentData.find(content => content.languageKey === item.languageKey)
        return {
          languageKey: item.languageKey,
          frontPageBannerTitle: item.frontPageBannerTitle,
          frontPageBannerContent: content.frontPageBannerContent
        }
      })

      if (!addFrontBanner) {
        setFrontBanner(data)
      } else if (addFrontBanner) {
        setFrontBanner([...data, {}])
        setAddFrontBanner(false)
      }
    }
  }
  const handleCmsDataLanguage = () => {
    let objTitle = {}
    frontBanner.map(e => {
      if (e.languageKey) {
        objTitle[e.languageKey] = e.frontPageBannerTitle
      }
    })
    let objContent = {}
    frontBanner.map(e => {
      if (e.languageKey) {
        objContent[e.languageKey] = e.frontPageBannerContent
      }
    })
    dispatch(updateBannerContent({ name: 'frontPageBannerTitle', value: objTitle }))
    dispatch(updateBannerContent({ name: 'frontPageBannerContent', value: objContent }))
  }

  const handleCmsDataTitle = val => {
    let obj = {}
    frontBanner.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.frontPageBannerTitle
      }
    })
    dispatch(updateBannerContent({ name: val, value: obj }))
  }

  const handleCmsDataContent = val => {
    let obj = {}
    frontBanner.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.frontPageBannerContent
      }
    })
    dispatch(updateBannerContent({ name: val, value: obj }))
  }

  const handleInputs = (key, val) => {
    let res = { ...frontBanner[idx], [key]: val }
    frontBanner[idx] = res
    setFrontBanner(frontBanner)
  }

  useEffect(() => {
    if (frontPageBannerTitleData) {
      fetchCmsData()
    }
  }, [frontPageBannerTitleData])

  useEffect(() => {
    setFrontBannerDetails(event)
  }, [event])

  return (
    <Table style={{ borderBottom: '1px solid #e8e8e8' }}>
      <FormGroup row>
        <Label for='languageKey' sm={3}>
          Language
        </Label>
        <Col>
          <Input
            className={frontBannerDetails.languageKey ? 'is-valid' : 'is-invalid'}
            required
            type='select'
            name='languageKey'
            value={frontBannerDetails.languageKey ? frontBannerDetails.languageKey : ''}
            id='languageKey'
            onChange={e => {
              setFrontBannerDetails({ ...frontBannerDetails, languageKey: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataLanguage(e.target.name)
            }}
          >
            <option value=''>Click to Select</option>
            {languages.map((lan, i) => {
              return (
                <option value={lan.languageKey} key={i}>
                  {`${lan.languageKey} - ${lan.languageName}`}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='frontPageBannerTitle' sm={3}>
          {' '}
          FrontBanner Title
        </Label>
        <Col>
          <Input
            required
            id='frontPageBannerTitle'
            className={frontBannerDetails.frontPageBannerTitle ? 'is-valid' : 'is-invalid'}
            value={frontBannerDetails.frontPageBannerTitle ? frontBannerDetails.frontPageBannerTitle : ''}
            name='frontPageBannerTitle'
            onChange={e => {
              setFrontBannerDetails({ ...frontBannerDetails, frontPageBannerTitle: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataTitle(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='frontPageBannerContent' sm={3}>
          FrontBanner Content
        </Label>
        <Col>
          <Input
            required
            type='textarea'
            maxLength={250}
            id='frontPageBannerContent'
            className={frontBannerDetails.frontPageBannerContent ? 'is-valid' : 'is-invalid'}
            value={frontBannerDetails.frontPageBannerContent ? frontBannerDetails.frontPageBannerContent : ''}
            name='frontPageBannerContent'
            onChange={e => {
              setFrontBannerDetails({ ...frontBannerDetails, frontPageBannerContent: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataContent(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
    </Table>
  )
}

function LoginMultiple(props) {
  const dispatch = useDispatch()
  const { event, idx, languages, loginData, setLoginData, loginPageTitleData, loginPageContentData, addLoginData, setAddLoginData } = props
  const [loginDetails, setLoginDetails] = useState(event)

  const fetchLoginData = () => {
    if (typeof loginPageTitleData === 'object' && typeof loginPageContentData === 'object') {
      let titleData = Object.entries(loginPageTitleData).map(([key, value]) => ({
        languageKey: key,
        loginPageTitle: value
      }))

      let contentData = Object.entries(loginPageContentData).map(([key, value]) => ({
        languageKey: key,
        loginPageDescription: value
      }))

      let data = titleData.map(item => {
        const content = contentData.find(content => content.languageKey === item.languageKey)
        return {
          languageKey: item.languageKey,
          loginPageTitle: item.loginPageTitle,
          loginPageDescription: content ? content.loginPageDescription : ''
        }
      })

      if (!addLoginData) {
        setLoginData(data)
      } else if (addLoginData) {
        setLoginData([...data, {}])
        setAddLoginData(false)
      }
    }
  }
  const handleLoginDataLanguage = () => {
    let objTitle = {}
    loginData.map(e => {
      if (e.languageKey) {
        objTitle[e.languageKey] = e.loginPageTitle
      }
    })
    let objContent = {}
    loginData.map(e => {
      if (e.languageKey) {
        objContent[e.languageKey] = e.loginPageDescription
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: 'loginPageTitle', value: objTitle }))
    dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: 'loginPageDescription', value: objContent }))
  }

  const handleCmsDataTitle = val => {
    let obj = {}
    loginData.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.loginPageTitle
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: val, value: obj }))
  }

  const handleCmsDataContent = val => {
    let obj = {}
    loginData.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.loginPageDescription
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'loginPageDetails', name: val, value: obj }))
  }

  const handleInputs = (key, val) => {
    let res = { ...loginData[idx], [key]: val }
    loginData[idx] = res
    setLoginData(loginData)
  }

  useEffect(() => {
    if (loginPageTitleData) {
      fetchLoginData()
    }
  }, [loginPageTitleData])

  useEffect(() => {
    setLoginDetails(event)
  }, [event])

  return (
    <Table style={{ borderBottom: '1px solid #e8e8e8' }}>
      <FormGroup row>
        <Label for='languageKey' sm={3}>
          Language
        </Label>
        <Col>
          <Input
            className={loginDetails.languageKey ? 'is-valid' : 'is-invalid'}
            required
            type='select'
            name='languageKey'
            value={loginDetails.languageKey ? loginDetails.languageKey : ''}
            id='languageKey'
            onChange={e => {
              setLoginDetails({ ...loginDetails, languageKey: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleLoginDataLanguage(e.target.name)
            }}
          >
            <option value=''>Click to Select</option>
            {languages.map((lan, i) => {
              return (
                <option value={lan.languageKey} key={i}>
                  {`${lan.languageKey} - ${lan.languageName}`}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='loginPageTitle' sm={3}>
          {' '}
          LoginPage Title
        </Label>
        <Col>
          <Input
            required
            id='loginPageTitle'
            className={loginDetails.loginPageTitle ? 'is-valid' : 'is-invalid'}
            value={loginDetails.loginPageTitle ? loginDetails.loginPageTitle : ''}
            name='loginPageTitle'
            onChange={e => {
              setLoginDetails({ ...loginDetails, loginPageTitle: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataTitle(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='loginPageDescription' sm={3}>
          LoginPage Description
        </Label>
        <Col>
          <Input
            required
            type='textarea'
            maxLength={250}
            id='loginPageDescription'
            className={loginDetails.loginPageDescription ? 'is-valid' : 'is-invalid'}
            value={loginDetails.loginPageDescription ? loginDetails.loginPageDescription : ''}
            name='loginPageDescription'
            onChange={e => {
              setLoginDetails({ ...loginDetails, loginPageDescription: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataContent(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
    </Table>
  )
}

function HofMultiple(props) {
  const dispatch = useDispatch()
  const { event, idx, languages, hofData, setHofData, hofPageTitleData, hofPageContentData, addHofData, setAddHofData } = props
  const [hofDetails, setHofDetails] = useState(event)

  const fetchHofData = () => {
    if (typeof hofPageTitleData === 'object' && typeof hofPageContentData === 'object') {
      let titleData = Object.entries(hofPageTitleData).map(([key, value]) => ({
        languageKey: key,
        hofTitle: value
      }))

      let contentData = Object.entries(hofPageContentData).map(([key, value]) => ({
        languageKey: key,
        hofDescription: value
      }))

      let data = titleData.map(item => {
        const content = contentData.find(content => content.languageKey === item.languageKey)
        return {
          languageKey: item.languageKey,
          hofTitle: item.hofTitle,
          hofDescription: content.hofDescription
        }
      })

      if (!addHofData) {
        setHofData(data)
      } else if (addHofData) {
        setHofData([...data, {}])
        setAddHofData(false)
      }
    }
  }
  const handleHofDataLanguage = () => {
    let objTitle = {}
    hofData.map(e => {
      if (e.languageKey) {
        objTitle[e.languageKey] = e.hofTitle
      }
    })
    let objContent = {}
    hofData.map(e => {
      if (e.languageKey) {
        objContent[e.languageKey] = e.hofDescription
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: 'hofTitle', value: objTitle }))
    dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: 'hofDescription', value: objContent }))
  }

  const handleCmsDataTitle = val => {
    let obj = {}
    hofData.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.hofTitle
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: val, value: obj }))
  }

  const handleCmsDataContent = val => {
    let obj = {}
    hofData.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.hofDescription
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'hofDetails', name: val, value: obj }))
  }

  const handleInputs = (key, val) => {
    let res = { ...hofData[idx], [key]: val }
    hofData[idx] = res
    setHofData(hofData)
  }

  useEffect(() => {
    if (hofPageTitleData) {
      fetchHofData()
    }
  }, [hofPageTitleData])

  useEffect(() => {
    setHofDetails(event)
  }, [event])

  return (
    <Table style={{ borderBottom: '1px solid #e8e8e8' }}>
      <FormGroup row>
        <Label for='languageKey' sm={3}>
          Language
        </Label>
        <Col>
          <Input
            className={hofDetails.languageKey ? 'is-valid' : 'is-invalid'}
            required
            type='select'
            name='languageKey'
            value={hofDetails.languageKey ? hofDetails.languageKey : ''}
            id='languageKey'
            onChange={e => {
              setHofDetails({ ...hofDetails, languageKey: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleHofDataLanguage(e.target.name)
            }}
          >
            <option value=''>Click to Select</option>
            {languages.map((lan, i) => {
              return (
                <option value={lan.languageKey} key={i}>
                  {`${lan.languageKey} - ${lan.languageName}`}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='hofTitle' sm={3}>
          {' '}
          Hall of Fame Title
        </Label>
        <Col>
          <Input
            required
            id='hofTitle'
            className={hofDetails.hofTitle ? 'is-valid' : 'is-invalid'}
            value={hofDetails.hofTitle ? hofDetails.hofTitle : ''}
            name='hofTitle'
            onChange={e => {
              setHofDetails({ ...hofDetails, hofTitle: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataTitle(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='hofDescription' sm={3}>
          Hall Of Fame Description
        </Label>
        <Col>
          <Input
            required
            type='textarea'
            maxLength={250}
            id='hofDescription'
            className={hofDetails.hofDescription ? 'is-valid' : 'is-invalid'}
            value={hofDetails.hofDescription ? hofDetails.hofDescription : ''}
            name='hofDescription'
            onChange={e => {
              setHofDetails({ ...hofDetails, hofDescription: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataContent(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
    </Table>
  )
}

function CountDownMultiple(props) {
  const dispatch = useDispatch()
  const {
    event,
    idx,
    languages,
    countDownData,
    setCountDownData,
    countDownPageTitleData,
    countDownPageContentData,
    addCountDownData,
    setAddCountDownData
  } = props
  const [countDownDetails, setCountDownDetails] = useState(event)

  const fetchCountDownData = () => {
    if (typeof countDownPageTitleData === 'object' && typeof countDownPageContentData === 'object') {
      let titleData = Object.entries(countDownPageTitleData).map(([key, value]) => ({
        languageKey: key,
        countDownTitle: value
      }))

      let contentData = Object.entries(countDownPageContentData).map(([key, value]) => ({
        languageKey: key,
        countDownDescription: value
      }))

      let data = titleData.map(item => {
        const content = contentData.find(content => content.languageKey === item.languageKey)
        return {
          languageKey: item.languageKey,
          countDownTitle: item.countDownTitle,
          countDownDescription: content.countDownDescription
        }
      })

      if (!addCountDownData) {
        setCountDownData(data)
      } else if (addCountDownData) {
        setCountDownData([...data, {}])
        setAddCountDownData(false)
      }
    }
  }

  const handleCountDownDataLanguage = () => {
    let objTitle = {}
    countDownData.map(e => {
      if (e.languageKey) {
        objTitle[e.languageKey] = e.countDownTitle
      }
    })
    let objContent = {}
    countDownData.map(e => {
      if (e.languageKey) {
        objContent[e.languageKey] = e.countDownDescription
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: 'countDownTitle', value: objTitle }))
    dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: 'countDownDescription', value: objContent }))
  }

  const handleCmsDataTitle = val => {
    let obj = {}
    countDownData.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.countDownTitle
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: val, value: obj }))
  }

  const handleCmsDataContent = val => {
    let obj = {}
    countDownData.map(e => {
      if (e.languageKey) {
        obj[e.languageKey] = e.countDownDescription
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'countDownDetails', name: val, value: obj }))
  }

  const handleInputs = (key, val) => {
    let res = { ...countDownData[idx], [key]: val }
    countDownData[idx] = res
    setCountDownData(countDownData)
  }

  useEffect(() => {
    if (countDownPageTitleData) {
      fetchCountDownData()
    }
  }, [countDownPageTitleData])

  useEffect(() => {
    setCountDownDetails(event)
  }, [event])

  return (
    <Table style={{ borderBottom: '1px solid #e8e8e8' }}>
      <FormGroup row>
        <Label for='languageKey' sm={3}>
          Language
        </Label>
        <Col>
          <Input
            className={countDownDetails.languageKey ? 'is-valid' : 'is-invalid'}
            required
            type='select'
            name='languageKey'
            value={countDownDetails.languageKey ? countDownDetails.languageKey : ''}
            id='languageKey'
            onChange={e => {
              setCountDownDetails({ ...countDownDetails, languageKey: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCountDownDataLanguage(e.target.name)
            }}
          >
            <option value=''>Click to Select</option>
            {languages.map((lan, i) => {
              return (
                <option value={lan.languageKey} key={i}>
                  {`${lan.languageKey} - ${lan.languageName}`}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='countDownTitle' sm={3}>
          {' '}
          Count Down Title
        </Label>
        <Col>
          <Input
            required
            id='countDownTitle'
            className={countDownDetails.countDownTitle ? 'is-valid' : 'is-invalid'}
            value={countDownDetails.countDownTitle ? countDownDetails.countDownTitle : ''}
            name='countDownTitle'
            onChange={e => {
              setCountDownDetails({ ...countDownDetails, countDownTitle: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataTitle(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='countDownDescription' sm={3}>
          Count Down Description
        </Label>
        <Col>
          <Input
            required
            type='textarea'
            maxLength={250}
            id='countDownDescription'
            className={countDownDetails.countDownDescription ? 'is-valid' : 'is-invalid'}
            value={countDownDetails.countDownDescription ? countDownDetails.countDownDescription : ''}
            name='countDownDescription'
            onChange={e => {
              setCountDownDetails({ ...countDownDetails, countDownDescription: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handleCmsDataContent(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
    </Table>
  )
}

function PersonalShopperMultiple(props) {
  const dispatch = useDispatch()
  const {
    event,
    idx,
    languages,
    personalShopperData,
    setPersonalShopperData,
    personalShopperPageContentData,
    addPersonalShopperData,
    setAddPersonalShopperData
  } = props
  const [personalShopperDetails, setPersonalShopperDetails] = useState(event)

  const fetchPersonalShopperData = () => {
    if (typeof personalShopperPageContentData === 'object') {
      let data = Object.entries(personalShopperPageContentData).map(([key, value]) => ({
        languageKey: key,
        personalShopperDescription: value
      }))

      if (!addPersonalShopperData) {
        setPersonalShopperData(data)
      } else if (addPersonalShopperData) {
        setPersonalShopperData([...data, {}])
        setAddPersonalShopperData(false)
      }
    }
  }

  const handlePersonalShopperDataLanguage = () => {
    let objContent = {}
    personalShopperData.map(e => {
      if (e.languageKey) {
        objContent[e.languageKey] = e.personalShopperDescription
      }
    })
    dispatch(updateClubCmsContent({ fieldName: 'personalShopperDetails', name: 'personalShopperDescription', value: objContent }))
  }

  const handleInputs = (key, val) => {
    let res = { ...personalShopperData[idx], [key]: val }
    personalShopperData[idx] = res
    setPersonalShopperData(personalShopperData)
  }

  useEffect(() => {
    if (personalShopperPageContentData) {
      fetchPersonalShopperData()
    }
  }, [personalShopperPageContentData])

  useEffect(() => {
    setPersonalShopperDetails(event)
  }, [event])

  return (
    <Table style={{ borderBottom: '1px solid #e8e8e8' }}>
      <FormGroup row>
        <Label for='languageKey' sm={3}>
          Language
        </Label>
        <Col>
          <Input
            className={personalShopperDetails.languageKey ? 'is-valid' : 'is-invalid'}
            required
            type='select'
            name='languageKey'
            value={personalShopperDetails.languageKey ? personalShopperDetails.languageKey : ''}
            id='languageKey'
            onChange={e => {
              setPersonalShopperDetails({ ...personalShopperDetails, languageKey: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handlePersonalShopperDataLanguage(e.target.name)
            }}
          >
            <option value=''>Click to Select</option>
            {languages.map((lan, i) => {
              return (
                <option value={lan.languageKey} key={i}>
                  {`${lan.languageKey} - ${lan.languageName}`}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='personalShopperDescription' sm={3}>
          Personal Shopper Description
        </Label>
        <Col>
          <Input
            required
            type='textarea'
            maxLength={250}
            id='personalShopperDescription'
            className={personalShopperDetails.personalShopperDescription ? 'is-valid' : 'is-invalid'}
            value={personalShopperDetails.personalShopperDescription ? personalShopperDetails.personalShopperDescription : ''}
            name='personalShopperDescription'
            onChange={e => {
              setPersonalShopperDetails({ ...personalShopperDetails, personalShopperDescription: e.target.value })
              handleInputs(e.target.name, e.target.value)
              handlePersonalShopperDataLanguage(e.target.name)
            }}
          />
        </Col>
      </FormGroup>
    </Table>
  )
}

function FeatureToggles() {
  const settings = useSelector(state => state.login.settings)
  const CustomisationID = useRef(null)
  const [enableUpdates, setEnableUpdates] = useState(false)
  const [features, setFeatures] = useState({
    disablePoints: false,
    enableOnePointUsers: false,
    disableShop: false,
    disableNews: false,
    disableHome: false,
    profileMyCompetition: false,
    displayProfilePointGrid: false,
    displayProfilePointExpiryGrid: false,
    displayProfileTaxGrid: false,
    enableMobileNumberUpdates: false,
    mobileNumberCountryCode: ''
  })

  const fetchFeatures = () => {
    let baseData = { CustomisedID: settings.BrandID, customisedType: 'Brand', title: 'upgraderClubFeatureToggles' }
    GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
      setEnableUpdates(true)
      if (!responseJSON || !responseJSON.length || responseJSON.error) return
      responseJSON.sort((a, b) => b.CustomisationID - a.CustomisationID > 0)
      CustomisationID.current = responseJSON[0].CustomisationID || null
      const customisationObj = JSON.parse(responseJSON[0].customisationData)
      const {
        disablePoints,
        enableOnePointUsers,
        disableShop,
        disableNews,
        disableHome,
        profileMyCompetition,
        displayProfilePointGrid,
        displayProfilePointExpiryGrid,
        displayProfileTaxGrid,
        enableMobileNumberUpdates,
        mobileNumberCountryCode
      } = customisationObj.features

      setFeatures({
        disablePoints: disablePoints,
        enableOnePointUsers: enableOnePointUsers,
        disableShop: disableShop,
        disableNews: disableNews,
        disableHome: disableHome,
        profileMyCompetition: profileMyCompetition,
        displayProfilePointGrid: displayProfilePointGrid,
        displayProfilePointExpiryGrid: displayProfilePointExpiryGrid,
        displayProfileTaxGrid: displayProfileTaxGrid,
        enableMobileNumberUpdates: enableMobileNumberUpdates,
        mobileNumberCountryCode: mobileNumberCountryCode
      })
    })
  }

  const updateFeatures = () => {
    if (!enableUpdates) return
    const baseData = {
      CustomisationID: CustomisationID.current || null,
      CustomisedID: settings.BrandID,
      customisedType: 'Brand',
      description: 'Upgraderclub feature toggles for ' + settings.brandName,
      title: 'upgraderClubFeatureToggles',
      customisationData: JSON.stringify({ features: { ...features, disableProfilePointsOverview: !!features?.enableOnePointUsers } })
    }
    if (!baseData.CustomisationID) delete baseData.CustomisationID
    PUT('customisationv2', baseData).then(responseJSON => {
      if (!responseJSON || !responseJSON.length || responseJSON.error) return
    })
  }

  useEffect(() => {
    fetchFeatures()
  }, [])

  useEffect(() => {
    updateFeatures()
  }, [features])

  return (
    <>
      <Container>
        <Row>
          <Col xs='6'>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <h3>Features</h3>
              <ToggleSwitch
                label='Disable Points'
                handleChange={e => setFeatures({ ...features, disablePoints: e.target.checked })}
                value={features.disablePoints}
              />
              <ToggleSwitch
                label='1-point-users'
                handleChange={e => setFeatures({ ...features, enableOnePointUsers: e.target.checked })}
                value={features.enableOnePointUsers}
              />
              <ToggleSwitch
                label='Disable Shop'
                handleChange={e => setFeatures({ ...features, disableShop: e.target.checked })}
                value={features.disableShop}
              />
              <ToggleSwitch
                label='Disable News'
                handleChange={e => setFeatures({ ...features, disableNews: e.target.checked })}
                value={features.disableNews}
              />
              <ToggleSwitch
                label='Disable Home'
                handleChange={e => setFeatures({ ...features, disableHome: e.target.checked })}
                value={features.disableHome}
              />
              <ToggleSwitch
                label='Show "My Competitions"'
                handleChange={e => setFeatures({ ...features, profileMyCompetition: e.target.checked })}
                value={features.profileMyCompetition}
              />
              <ToggleSwitch
                label='Show point-box on profile page'
                handleChange={e => setFeatures({ ...features, displayProfilePointGrid: e.target.checked })}
                value={features.displayProfilePointGrid}
              />
              <ToggleSwitch
                label='Show point-expiry-box on profile page'
                handleChange={e => setFeatures({ ...features, displayProfilePointExpiryGrid: e.target.checked })}
                value={features.displayProfilePointExpiryGrid}
              />
              <ToggleSwitch
                label='Show point-tax-box on profile page'
                handleChange={e => setFeatures({ ...features, displayProfileTaxGrid: e.target.checked })}
                value={features.displayProfileTaxGrid}
              />
              <ToggleSwitch
                label='Enable Mobile Number Updates'
                handleChange={e => setFeatures({ ...features, enableMobileNumberUpdates: e.target.checked })}
                value={features.enableMobileNumberUpdates}
              />
              {features.enableMobileNumberUpdates && (
                <>
                  <FormGroup row>
                    <Label for='mobileNumberCountryCode' sm={3}>
                      Country Code
                    </Label>
                    <Col sm={3}>
                      <Input
                        type='text'
                        id='mobileNumberCountryCode'
                        value={features.mobileNumberCountryCode ? features.mobileNumberCountryCode : ''}
                        name='mobileNumberCountryCode'
                        onChange={e => {
                          setFeatures({ ...features, mobileNumberCountryCode: e.target.value })
                        }}
                      />
                    </Col>
                  </FormGroup>
                </>
              )
              // mobileNumberCountryCode
              }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const GdprTextConfiguration = () => {
  const settings = useSelector(state => state.login.settings)
  const [consentText, setConsentText] = useState({
    gdprConsentText: '',
    newsConsentText: '',
    customUserConsentGDPRAndNews: false,
    consentCookiePolicy: ''
  })
  const [enableUpdates, setEnableUpdates] = useState(false)
  const CustomisationID = useRef(null)

  const fetchFeatures = () => {
    let baseData = { CustomisedID: settings.BrandID, customisedType: 'Brand', title: 'customUserConsentGDPRAndNews' }
    GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
      setEnableUpdates(true)
      if (!responseJSON || !responseJSON.length || responseJSON.error) return
      responseJSON.sort((a, b) => b.CustomisationID - a.CustomisationID > 0)
      CustomisationID.current = responseJSON[0].CustomisationID || null
      const customisationObj = JSON.parse(responseJSON[0].customisationData)
      const { gdprConsentText, newsConsentText, customUserConsentGDPRAndNews, consentCookiePolicy } = customisationObj.features
      setConsentText({
        gdprConsentText: gdprConsentText || '',
        newsConsentText: newsConsentText || '',
        customUserConsentGDPRAndNews: customUserConsentGDPRAndNews || false,
        consentCookiePolicy: consentCookiePolicy || ''
      })
    })
  }

  const updateFeatures = () => {
    if (!enableUpdates) return
    const baseData = {
      CustomisationID: CustomisationID.current || null,
      CustomisedID: settings.BrandID,
      customisedType: 'Brand',
      description: 'customUserConsentGDPRAndNews',
      title: 'customUserConsentGDPRAndNews',
      customisationData: JSON.stringify({ features: consentText })
    }
    if (!baseData.CustomisationID) delete baseData.CustomisationID
    PUT('customisationv2', baseData).then(responseJSON => {
      if (!responseJSON || !responseJSON.CustomisationID || responseJSON.error) {
        return toast.error('Error while saving Banner Content', {
          autoClose: 7500,
          onClose: () => {
            clearToast()
          }
        })
      }
      fetchFeatures()
      return toast.success('Successfully Saved', {
        autoClose: 7500,
        onClose: () => {
          clearToast()
        }
      })
    })
  }

  useEffect(() => {
    fetchFeatures()
  }, [])

  return (
    <>
      <Container>
        <Row>
          <Col xs='12'>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <ToggleSwitch
                label='Custom user-consent text GDPR and News'
                handleChange={e => setConsentText({ ...consentText, customUserConsentGDPRAndNews: e.target.checked })}
                value={consentText.customUserConsentGDPRAndNews}
              />
              <FormGroup row>
                <Label for='gdprConsentText' sm={3}>
                  GDPR consent text
                </Label>
                <Col>
                  <Input
                    type='textarea'
                    rows={5}
                    id='gdprConsentText'
                    value={consentText.gdprConsentText ? consentText.gdprConsentText : ''}
                    name='gdprConsentText'
                    onChange={e => {
                      setConsentText({ ...consentText, gdprConsentText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='newsConsentText' sm={3}>
                  News consent text
                </Label>
                <Col>
                  <Input
                    type='textarea'
                    rows={5}
                    id='newsConsentText'
                    value={consentText.newsConsentText ? consentText.newsConsentText : ''}
                    name='newsConsentText'
                    onChange={e => {
                      setConsentText({ ...consentText, newsConsentText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='consentCookiePolicy' sm={3}>
                  Consent CookiePolicy Document
                </Label>
                <Col>
                  <Input
                    type='text'
                    id='consentCookiePolicy'
                    value={consentText.consentCookiePolicy ? consentText.consentCookiePolicy : ''}
                    name='consentCookiePolicy'
                    onChange={e => {
                      setConsentText({ ...consentText, consentCookiePolicy: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <Col xs={3}>
                <Button onClick={() => updateFeatures()}>Gem</Button>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
