import React, { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import { toast } from 'react-toastify'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'

const { GET, POST } = Proxies

const HighlightedProductOverview = () => {
  const [loading, setLoading] = useState(false)
  const [highlightedProducts, setHighlightedProducts] = useState([])

  const fetchHighlightedProducts = () => {
    setLoading(true)
    GET('highlightedproducts/getMultiple').then(responseJSON => {
      if (!responseJSON.error) {
        setHighlightedProducts(responseJSON)
      }
      setLoading(false)
    })
  }

  const RemoveHighlightedProduct = HighlightedProductID => {
    POST('highlightedproducts/removeHighlightedProduct', { HighlightedProductID }).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('success', {
        autoClose: 7000
      })
      fetchHighlightedProducts()
    })
  }

  // const copyHighlightedProduct = () =>{

  // }

  useEffect(() => {
    fetchHighlightedProducts()
  }, [])

  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Highlighted Products
            <Button className='float-right' tag={Link} to={'highlighted-product/new'} style={{ marginLeft: '16px' }}>
              Opret ny
            </Button>
          </strong>
        }
      >
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'HighlightedProductID'
              },
              fieldFunctions: {
                brands: e => {
                  return e.brands.map(b => b.brandName).join(', ')
                },
                product: e => {
                  return `${e.product.elementTitle} - ${e.ProductID}`
                },
                catalog: e => {
                  return `${e.catalog.moduleName} - ${e.CatalogID}`
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.HighlightedProductID}>Redigere</Tooltip>
                      <Button id={'edit' + e.HighlightedProductID} tag={Link} to={'/highlighted-product/' + e.HighlightedProductID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>

                      {/* <Tooltip target={'copy' + e.HighlightedProductID}>Kopier</Tooltip>
                            <Button
                            id={'copy' + e.HighlightedProductID}
                            onClick={() => {
                                copyHighlightedProduct(e.HighlightedProductID)
                            }}
                            >
                            <FontAwesomeIcon icon='copy' />
                            </Button> */}

                      <Tooltip target={'delete' + e.HighlightedProductID}>Slet</Tooltip>
                      <Button
                        id={'delete' + e.HighlightedProductID}
                        color='danger'
                        onClick={() => {
                          if (confirm('Are you sure you want to delete?')) {
                            RemoveHighlightedProduct(e.HighlightedProductID)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              HighlightedProductID: '#',
              catalog: 'Catalog',
              categoryName: 'Category',
              brands: 'Brands',
              product: 'Product',
              title: 'Title'
            }}
            data={highlightedProducts}
          />
        )}
      </CollapsibleCard>
    </>
  )
}

export default HighlightedProductOverview
