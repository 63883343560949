/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'

export const ShopActions = createActions([
  'getShops',
  'getShopDetails',
  'createEmptyShop',
  'clearShop',
  'getCatalogs',
  'getGroups',
  'updateShopField',
  'toggleCatalog',
  'toggleGroup',
  'saveShop',
  'deleteShop',
  'clearToast',
  'changeShopStatus',
  'toggleRemovedShopsHandler',
  'toggleActiveShop',
  'toggleInActiveShop'
])

export class ShopStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = ShopActions
    this.state = {
      shops: [],
      shopDetails: {
        shopGroupFilterText: '',
        shopCatalogFilterText: ''
      },
      isSaving: false,
      fetchingShops: false,
      catalogs: [],
      fetchingCatalogs: false,
      groups: [],
      fetchingGroups: false,
      showShopError: false,
      showShopSuccess: false,
      filteredShopGroups: [],
      filteredShopCatalogs: [],
      showShopDeleteMessage: false,
      shopsFilterValue: 'active',
      showActiveShops: true,
      showInActiveShops: false
    }
  }
  clearToast() {
    this.setState({ showShopDeleteMessage: false, showShopError: false, showShopSuccess: false })
  }
  createEmptyShop() {
    this.setState({ shopDetails: {} })
  }
  clearShop() {
    this.setState({ shopDetails: {} })
  }
  getCatalogs(BrandID) {
    if (this.state.fetchingCatalogs) {
      return false
    }
    this.setState({ fetchingCatalogs: true })
    GET('schemas/catalogs', { BrandID }).then(responseJSON => {
      let nextState = {
        catalogs: [],
        fetchingCatalogs: false
      }
      if (responseJSON) {
        nextState.catalogs = responseJSON
      }
      this.setState(nextState)
    })
  }
  getGroups(BrandID) {
    if (this.state.fetchingGroups) {
      return false
    }
    this.setState({ fetchingGroups: true })
    GET('groups/fetch-groups', { BrandID }).then(responseJSON => {
      let nextState = {
        groups: [],
        fetchingGroups: false
      }
      if (responseJSON) {
        nextState.groups = responseJSON
      }
      this.setState(nextState)
    })
  }
  getShops() {
    this.setState({ fetchingShops: true })
    GET('shops/pool').then(responseJSON => {
      let nextState = {
        shops: [],
        fetchingShops: false
      }
      if (responseJSON) {
        responseJSON = responseJSON.filter(e => {
          return !isNaN(e.ShopID)
        })
        nextState.shops = responseJSON
      }
      this.setState(nextState)
    })
  }
  getShopDetails(ShopID, isMSBAdmin = false, isCopy = false) {
    this.setState({ fetchingShops: true })
    let api = isMSBAdmin ? 'shops/admin-fetch' : 'shops/fetch'
    GET(api, { ShopID: ShopID }).then(responseJSON => {
      let nextState = {
        shopDetails: {},
        fetchingShops: false
      }
      if (responseJSON) {
        isCopy && delete responseJSON.ShopID
        nextState.shopDetails = responseJSON
        this.getGroups(responseJSON.BrandID)
        this.getCatalogs(responseJSON.BrandID)
      }
      this.setState(nextState)
    })
  }
  updateShopField(field) {
    let name = field.target.name
    let value = strip_tags(field.target.value)
    let shopDetails = this.state.shopDetails
    shopDetails[name] = value
    if (name === 'shopGroupFilterText') {
      const groupsFilter = value ? this.state.groups.filter(item => item.moduleName.toLowerCase().includes(value.toLowerCase())) : this.state.groups
      this.setState({ filteredShopGroups: groupsFilter })
    }
    if (name === 'shopCatalogFilterText') {
      const catalogsFilter = value
        ? this.state.catalogs.filter(item => item.moduleName.toLowerCase().includes(value.toLowerCase()))
        : this.state.groups
      this.setState({ filteredShopCatalogs: catalogsFilter })
    }
    if (name === 'BrandID') {
      this.getGroups(shopDetails[name])
      this.getCatalogs(shopDetails[name])
    }
    if (name === 'shopStatus') {
      shopDetails[name] = field.target.checked ? 'CLOSED' : 'OPEN'
    }
    this.setState({ shopDetails: shopDetails })
  }
  toggleCatalog(checkbox) {
    let CatalogID = checkbox.target.name
    let checked = checkbox.target.checked
    let shopDetails = this.state.shopDetails
    if (checked) {
      let thisCatalog = this.state.catalogs.find(e => {
        return e.CatalogID == CatalogID
      })
      if (shopDetails.catalogs) {
        shopDetails.catalogs.push(thisCatalog)
      } else {
        shopDetails.catalogs = [thisCatalog]
      }
    } else {
      shopDetails.catalogs = shopDetails.catalogs.filter(e => {
        return e.CatalogID != CatalogID
      })
    }
    this.setState({ shopDetails: shopDetails })
  }
  toggleGroup(checkbox) {
    let GroupID = checkbox.target.name
    let checked = checkbox.target.checked
    let shopDetails = this.state.shopDetails
    if (checked) {
      let thisGroup = this.state.groups.find(e => {
        return e.GroupID == GroupID
      })
      if (shopDetails.groups) {
        shopDetails.groups.push(thisGroup)
      } else {
        shopDetails.groups = [thisGroup]
      }
    } else {
      shopDetails.groups = shopDetails.groups.filter(e => {
        return e.GroupID != GroupID
      })
    }
    this.setState({ shopDetails: shopDetails })
  }
  saveShop() {
    if (this.state.shopDetails.moduleName != '') {
      this.setState({ isSaving: true })
      POST('shops/replace', this.state.shopDetails).then(responseJSON => {
        let nextState = {
          isSaving: false,
          shopDetails: {},
          shops: this.state.shops,
          showShopSuccess: false
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.showShopError = true
          } else {
            nextState.shopDetails = responseJSON
            if (nextState.shops.length > 0) {
              let found = false
              for (let i in nextState.shops) {
                if (nextState.shops[i].ShopID == responseJSON.ShopID) {
                  nextState.shops[i] = responseJSON
                  found = true
                }
              }
              if (!found) {
                nextState.shops.push(responseJSON)
              }
            }
            nextState.showShopSuccess = true
          }
        }
        this.setState(nextState)
      })
    }
  }
  deleteShop(ShopID = null) {
    if (ShopID) {
      POST('shops/remove', { ShopID: ShopID }).then(() => {
        this.getShops()
      })
    }
  }
  changeShopStatus(shop, status) {
    let shopDetails = Object.assign(shop)
    shopDetails.removed = status ? 1 : 0
    this.setState({ showShopDeleteMessage: false })
    POST('shops/replace', shopDetails).then(responseJSON => {
      if (responseJSON.error) {
        this.setState({ showShopDeleteMessage: true })
      } else {
        this.getShops()
      }
    })
  }
  toggleActiveShop() {
    let value = !this.state.showActiveShops ? 'active' : 'both'
    this.setState({ showActiveShops: !this.state.showActiveShops, shopsFilterValue: value, showInActiveShops: false })
  }
  toggleInActiveShop() {
    let value = this.state.showInActiveShops ? 'both' : 'inactive'
    this.setState({ showInActiveShops: !this.state.showInActiveShops, shopsFilterValue: value, showActiveShops: false })
  }
}
