/* eslint-disable eqeqeq */
import React, { Fragment } from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import nl2br from 'Components/nl2br'
import { isOurID } from 'Components/us'
import moment from 'moment'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  UncontrolledTooltip as Tooltip,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap'
import { OrderActions, OrderStore } from 'Stores/orderStore'
import { AddressActions } from 'Stores/addressStore'
import { LoginStore } from 'Stores/loginStore'
import styled from 'styled-components'
import _ from 'lodash'
import OrderDetailsForm from 'Modules/OrderDetailsForm.js'
import { AddressStore } from '../stores/addressStore'

const OrderLineCheckbox = styled.input`
  vertical-align: middle;
`
const AreaSeperator = styled(Table)`
  border-bottom: 1px dashed #ddd;
`

const StyledDropdown = styled(DropdownItem)`
  padding: 0px 40px !important;
  height: 25px !important;
`
const StyledSpan = styled.span`
  all: revert !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 0.875rem !important;
  color: #23282c !important;
  cursor: pointer !important;
  &:hover {
    color: ${props => (props.value ? '#23282c' : 'green')}!important;
  }
`
const StyledProductNumberSpan = styled.span`
  &:hover {
    color: green !important;
  }
`

const StyledProductTitleSpan = styled.span`
  all: revert !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 0.875rem !important;
  color: #23282c !important;
  cursor: auto !important;
`
const orderStatus = {
  NEW: 'Ny',
  TENTATIVE: 'Afventer',
  BACKORDER: 'Backorder',
  ORDERED: 'Bestilt',
  PACKED: 'Pakket',
  SHIPPED: 'Sendt',
  RECEIVED: 'Modtaget',
  REFUNDED: 'Refunderet',
  COMPLAINT: 'Reklamation',
  DECLINED: 'Afvist',
  FAILED: 'Fejlet',
  PREORDER: 'Preorder'
}
const seasonOrderStatus = {
  NEW: 'Ny',
  TENTATIVE: 'Afventer',
  ORDERED: 'Bestilt',
  PACKED: 'Pakket',
  SHIPPED: 'Sendt',
  RECEIVED: 'Modtaget',
  TO_ORDER: 'Skal bestilles'
}

const statusColors = {
  NEW: '#042e26',
  TENTATIVE: '#0a6553',
  BACKORDER: '#18b394',
  ORDERED: '#69f2d7',
  PACKED: '#892020',
  SHIPPED: '#dc4040',
  RECEIVED: '#ffc7c7',
  REFUNDED: '#f88c2d',
  COMPLAINT: '#ffdc18',
  DECLINED: '#217188',
  FAILED: '#52c9eb',
  PREORDER: 'orange'
}
const orderColors = { TENTATIVE: 'white', NEW: 'white', PACKED: 'white', DECLINED: 'white' }

export class CustomOrder extends Component {
  render() {
    return ''
  }
}
export const Order = props => {
  if (props.match && props.match.params && props.match.params.OrderID) {
    return <OrderDetails {...props} />
  }
  return <OrderListOverview {...props} />
}

class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [OrderStore, AddressStore, LoginStore]
    this.storeKeys = ['fetchingSingleOrder', 'orderDetails', 'showTooltip', 'isCustomOrder', 'addresses', 'settings']
    this.orderStatus = this.props.seasonOrder ? seasonOrderStatus : orderStatus
  }
  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.OrderID) {
      if (this.state.orderDetails.OrderID != this.props.match.params.OrderID) {
        OrderActions.getOrder(this.props.match.params.OrderID)
      }
    }
    AddressActions.getAddresses()
  }
  render() {
    if (this.state.fetchingSingleOrder || this.state.orderDetails.OrderID != this.props.match.params.OrderID) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let order = this.state.orderDetails
    order.timeline = order.timeline.map(timeline => {
      timeline.timelineEvent = this.orderStatus[timeline.timelineEvent] ? this.orderStatus[timeline.timelineEvent] : timeline.timelineEvent
      return timeline
    })
    return (
      <Row>
        <Col xs='6'>
          <OrderDetailsForm initialOrderDetails={order} isSeasonOrder={this.props.seasonOrder} addressList={this.state.addresses} />
        </Col>
        <Col xs='6'>
          <CollapsibleCard
            startOpen
            header={
              <React.Fragment>
                <strong>Modtager</strong>
                <Tooltip target='shipOrder'>Send Ordre</Tooltip>
                <Button
                  style={{ float: 'right', marginLeft: '8px' }}
                  onClick={() => {
                    OrderActions.shipOrder(order.OrderID, true, this.props)
                  }}
                  id='shipOrder'
                >
                  <FontAwesomeIcon icon='ship' />
                </Button>
                <Tooltip target='copy'>Copy to clipboard</Tooltip>
                <Button style={{ float: 'right' }} onClick={OrderActions.copyToClipboard} id='copy'>
                  <FontAwesomeIcon icon='copy' />
                </Button>
                <Tooltip target='print'>Print følgeseddel</Tooltip>
                <Button
                  style={{ float: 'right', marginRight: '8px' }}
                  onClick={() => {
                    window.open('/orders/print?OrderID=' + order.OrderID, '_blank')
                  }}
                  id='print'
                >
                  <FontAwesomeIcon icon='print' />
                </Button>
              </React.Fragment>
            }
          >
            <div id='deliveryInfo'>
              {order.orderConsigneeName}
              <br />
              {order.orderConsignee && (
                <Fragment>
                  Att: {order.orderConsignee}
                  <br />
                </Fragment>
              )}
              {order.orderConsigneeAddress1}
              <br />
              {order.orderConsigneeAddress2 && (
                <Fragment>
                  Att: {order.orderConsigneeAddress2}
                  <br />
                </Fragment>
              )}
              {order.orderConsigneeZipcode} {order.orderConsigneeCity}
              <br />
              <em>{order.orderConsigneePhone}</em>
              <br />
              <em>{order.orderConsigneeEmail}</em>
            </div>
          </CollapsibleCard>
          {this.state.settings && this.state.settings.features && this.state.settings.features.enableAddManualTnT && (
            <CollapsibleCard
              startOpen
              header={
                <React.Fragment>
                  <strong>Trackingnumre</strong>
                  <Tooltip target='addTracking'>Add T/T</Tooltip>
                  <Button
                    style={{ float: 'right', marginLeft: '8px' }}
                    onClick={() => {
                      OrderActions.addTracking(order.OrderID, true, this.props)
                    }}
                    id='addTracking'
                  >
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                </React.Fragment>
              }
            >
              <div id='tracking'>
                {this.state.orderDetails.timeline.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item.timelineEvent === 'TRACKED' && (
                        <Row style={{ marginTop: '8px' }}>
                          {item.TimelineID && (
                            <Col md='11'>
                              <span>{item.timelineData}</span>
                            </Col>
                          )}
                          {!item.TimelineID && (
                            <Col md='10'>
                              <Input type='number' onChange={e => OrderActions.setTracking(index, e)} name='timelineData' value={item.timelineData} />
                            </Col>
                          )}
                          {!item.TimelineID && <Tooltip target='addTracking'>Save</Tooltip>}
                          {!item.TimelineID && (
                            <Button
                              md='auto'
                              disabled={!item.timelineData}
                              style={{ float: 'right', marginLeft: '8px', transform: 'scale(0.75)', marginTop: '-6px' }}
                              onClick={() => {
                                OrderActions.saveTracking(item)
                              }}
                              id='addTracking'
                            >
                              <FontAwesomeIcon icon='save' />
                            </Button>
                          )}
                          <Tooltip target='delete'>Slet</Tooltip>
                          <Button
                            color='danger'
                            md='auto'
                            style={{ float: 'right', transform: 'scale(0.75)', marginTop: '-6px' }}
                            onClick={() => {
                              if (confirm('Vil du slette denne T/T?')) {
                                OrderActions.removeTracking(item, index)
                              }
                            }}
                            id='delete'
                          >
                            <FontAwesomeIcon icon='trash' />
                          </Button>
                        </Row>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </CollapsibleCard>
          )}
          <CollapsibleCard startOpen header={<strong>Ordrelinier</strong>}>
            <DynamicTable
              data={order.orderlines}
              responsive
              size='sm'
              showAll
              hideSearch
              headers={{ OrderlineID: '#', orderlineTitle: 'Produkter' }}
              options={{
                fieldFunctions: {
                  orderlineTitle: e => {
                    let orderlineTitle = ''
                    let orderlineProductNumber = ''
                    let orderlineProductNumberArray = ''
                    let orderlineVariation = {}
                    if (this.state.isCustomOrder) {
                      // custom-orders don't have product number
                      orderlineTitle = e.orderlineTitle ? e.orderlineTitle : ''
                    } else {
                      // normal orders go in here
                      if (e.orderlineTitle.match(/\((.*)\)/)) {
                        orderlineTitle = e.orderlineTitle.replace(/ *\([^)]*\) */g, ' ')
                      } else {
                        orderlineTitle = e.orderlineTitle
                      }
                      if (
                        e.ProductID &&
                        e.ProductID != 0 &&
                        e.product &&
                        e.product.elementTitle &&
                        e.product.variations &&
                        e.product.variations.length > 0
                      ) {
                        // set product number based on api response
                        orderlineVariation = e.product.variations.filter(item => {
                          return item.VariationID === e.VariationID
                        })
                        if (orderlineVariation.length > 0) {
                          orderlineProductNumber = orderlineVariation[0].variationUID
                        }
                      }
                      if (!orderlineProductNumber) {
                        if (e.orderlineTitle.match(/\((.*)\)/)) {
                          orderlineProductNumberArray = e.orderlineTitle.match(/\((.*)\)/)
                        }
                      }
                    }
                    return (
                      <div>
                        <StyledProductTitleSpan
                          onClick={e => {
                            e.stopPropagation()
                          }}
                        >
                          {orderlineTitle}
                        </StyledProductTitleSpan>
                        {orderlineProductNumber && (
                          <StyledSpan
                            onClick={event => {
                              event.stopPropagation()
                              // set display value for title and copy-value for productNumber
                              orderlineProductNumber = orderlineProductNumber
                                ? orderlineProductNumber
                                : orderlineProductNumberArray.length > 0 && orderlineProductNumberArray[1]
                                ? orderlineProductNumberArray[1]
                                : ''
                              navigator.clipboard.writeText(orderlineProductNumber)
                              toast.success('Kopieret: ' + orderlineProductNumber, {
                                autoClose: 3000
                              })
                            }}
                          >
                            {// set display value for productNumber
                            orderlineProductNumber
                              ? ' (' + orderlineProductNumber + ')'
                              : orderlineProductNumberArray.length > 0 && orderlineProductNumberArray[0]
                              ? ' ' + orderlineProductNumberArray[0]
                              : ''}
                          </StyledSpan>
                        )}
                      </div>
                    )
                  }
                }
              }}
            />
          </CollapsibleCard>
          <CollapsibleCard startOpen header={<strong>Ordrehistorik</strong>}>
            <DynamicTable
              responsive
              size='sm'
              showAll
              hideSearch
              data={order.timeline}
              options={{ startSort: { column: 'TimelineID', dir: 'DESC' } }}
              headers={{
                TimelineID: '#',
                timeInsert: 'Tid',
                parentRelationID: 'Ordrenummer',
                timelineEvent: 'Begivenhed'
              }}
            />
          </CollapsibleCard>
        </Col>
      </Row>
    )
  }
}

const OrderListOverview = props => {
  return (
    <Fragment>
      <OrderFilters {...props} />
      <OrderList {...props} />
    </Fragment>
  )
}

class OrderList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mouseOver: false
    }
    this.store = OrderStore
    this.storeKeys = [
      'orders',
      'orderFilter',
      'fetchingOrders',
      'selectedOrders',
      'selectedOrderLines',
      'filterText',
      'orderStatus',
      'showClipBoardMessage',
      'c5SelectedOrders',
      'previewOrders',
      'isAllChecked',
      'isAllOrderLinesChecked',
      'isIndeterminate',
      'scrollPosition',
      'paginationPoint',
      'paginationAmount',
      'refundedOrder',
      'c5SupplierError'
    ]
    this.orderStatus = this.props.seasonOrder ? seasonOrderStatus : orderStatus
  }
  componentDidMount() {
    OrderActions.setOrderFilter(this.props.seasonOrder ? 'seasonOrder' : 'order')
    OrderActions.getOrders()
  }
  componentDidUpdate() {
    if (this.state.orderStatus === false) {
      toast.error('Unable to change the status', {
        autoClose: 7500,
        onClose: () => {
          OrderActions.clearToast()
        }
      })
    }
    if (this.state.orderStatus) {
      toast.success('Status Changed Successfully', {
        autoClose: 7500,
        onClose: () => {
          OrderActions.clearToast()
        }
      })
      OrderActions.clearToast()
    }
    if (this.state.showClipBoardMessage) {
      toast.success('Kopieret', {
        autoClose: 7500,
        onClose: () => {
          OrderActions.clearToast()
        }
      })
      OrderActions.clearToast()
    }
  }
  hoverOn = () => {
    if (!this.state.mouseOver) {
      this.setState({ mouseOver: true })
    }
  }
  hoverOff = () => {
    if (this.state.mouseOver) {
      this.setState({ mouseOver: false })
    }
  }
  render() {
    if (this.state.fetchingOrders) {
      return <FontAwesomeIcon data-cy='loader' icon='spinner' spin />
    }
    let tableFieldStringFunctions = {
      timeInsert: e => {
        return e.timeInsert
      },
      orderLabel: e => {
        return e.orderLabel
      },
      orderConsignee: e => {
        return e.orderConsignee
      },
      orderComment: e => {
        return e.orderComment
      },
      shippingids: e => {
        return e.shippingids
      },
      brandName: e => {
        return e.originBrand ? e.originBrand.brandName : ''
      },
      status: e => {
        return e.orderFlowStatus
      },
      products: e => {
        let rVal = e.orderlines.map(ol => {
          let supplier = ol.product && ol.product.supplier ? ol.product.supplier.supplierName : ''
          return [ol.orderlineTitle, ol.manufacturer, supplier].join(' ')
        })
        return rVal.join(' ')
      }
    }
    const OrderlineView = props => {
      let ol = props.ol
      let orderlines = props.order.orderlines.filter(line => line.orderlineTitle === ol.orderlineTitle)
      let supplier = ol.product && ol.product.supplier ? ol.product.supplier.supplierName : null
      if (ol.orderlineTitleNew && !props.order.orderCustomOrder) {
        if (ol.orderlineTitleNew.match(/\((.*)\)/)) {
          ol.orderLineTitleProductID = ol.orderlineTitleNew.match(/\((.*)\)/)
          ol.orderlineTitleNew = ol.orderlineTitleNew.replace(/ *\([^)]*\) */g, ' ')
        }
      }
      return (
        <StyledSpan value={this.state.mouseOver} onMouseMove={() => this.hoverOff()}>
          <OrderLineCheckbox
            className='mr-2'
            type='checkbox'
            checked={
              this.state.selectedOrderLines.findIndex(i => {
                return i == ol.OrderlineID
              }) > -1
            }
            onChange={() => {
              OrderActions.selectOrderLine(ol.OrderlineID, ol.grouped ? orderlines : [])
            }}
          />
          <span>
            <span
              onClick={e => {
                e.stopPropagation()
                let value = ol.orderlineTitleNew.trim()
                if (value && value.endsWith(',')) {
                  value = value.slice(0, -1)
                }
                navigator.clipboard.writeText(value)
                toast.success('Kopieret: ' + value, {
                  autoClose: 3000
                })
              }}
            >
              {ol.orderlineTitleNew}
            </span>
            {ol.orderLineTitleProductID && (
              <React.Fragment>
                <StyledProductNumberSpan
                  onMouseMove={e => {
                    e.stopPropagation()
                    this.hoverOn()
                  }}
                  onMouseLeave={e => {
                    e.stopPropagation()
                    this.hoverOff()
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(ol.orderLineTitleProductID[1])
                    toast.success('Kopieret: ' + ol.orderLineTitleProductID[1], {
                      autoClose: 3000
                    })
                  }}
                >
                  {ol.orderLineTitleProductID[0]}
                </StyledProductNumberSpan>
              </React.Fragment>
            )}
          </span>
          {ol.variationStockable === 1 && (
            <span className='mx-1'>
              <Tooltip target={ol.externalStock === 1 ? 'catExternalStock' + ol.OrderlineID : 'catInternalStock' + ol.OrderlineID}>
                {ol.externalStock === 1 ? 'Externalstock' : 'Internalstock'}
              </Tooltip>
              <Badge
                pill
                id={ol.externalStock === 1 ? 'catExternalStock' + ol.OrderlineID : 'catInternalStock' + ol.OrderlineID}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  OrderActions.addFilter({ column: 'externalStock', value: ol.externalStock }, true)
                }}
                color='warning'
              >
                {ol.externalStock === 1 ? 'E' : 'I'}
              </Badge>
            </span>
          )}
          {ol.manufacturer && (
            <span className='mx-1'>
              <Tooltip target={'catManufacturer' + ol.OrderlineID}>Producent</Tooltip>
              <Badge
                pill
                id={'catManufacturer' + ol.OrderlineID}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  OrderActions.addFilter({ column: 'manufacturer', value: ol.manufacturer }, true)
                }}
                color='primary'
              >
                {ol.manufacturer}
              </Badge>
            </span>
          )}
          {supplier && (
            <span className='mx-1'>
              <Tooltip target={'catSupplier' + ol.OrderlineID}>Leverandør</Tooltip>
              <Badge
                pill
                id={'catSupplier' + ol.OrderlineID}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  OrderActions.addFilter({ column: 'supplier', value: supplier }, true)
                }}
                color='info'
              >
                {supplier}
              </Badge>
            </span>
          )}
          / {ol.orderAmountPoints} point
          {ol.InvoiceID != '0' && (
            <Link title={`Faktura ID: ${ol.InvoiceID}`} to={'/admininvoice/' + props.originBrandID + '/' + ol.InvoiceID} style={{ color: 'orange' }}>
              <FontAwesomeIcon fixedWidth icon='file-alt' />
            </Link>
          )}
          {ol.grouped && (
            <span
              onClick={() => {
                OrderActions.expandOrderlines(ol)
              }}
            >
              <FontAwesomeIcon style={{ color: 'orange' }} fixedWidth icon='toggle-on' />
            </span>
          )}
          {ol.grouped === false && (
            <span
              onClick={() => {
                OrderActions.collapseOrderlines(ol)
              }}
            >
              <FontAwesomeIcon style={{ color: 'orange' }} fixedWidth icon='toggle-off' />
            </span>
          )}
        </StyledSpan>
      )
    }
    const dynamicTableOptions = {
      showAdminAccessDropdown: true,
      copyHightlighText: true,
      startPagination: 100,
      preserveSearch: OrderActions.preserveSearch,
      filterText: this.state.filterText,
      unsortable: ['actions'],
      preserveAllChecked: OrderActions.preserveAllChecked,
      preservePagination: OrderActions.preservePagination,
      preservePaginationAmount: OrderActions.preservePaginationAmount,
      fieldStringFunctions: tableFieldStringFunctions,
      fieldExportFunctions: tableFieldStringFunctions,
      paginationPoint: this.state.paginationPoint,
      paginationAmount: this.state.paginationAmount,
      fieldFunctions: {
        OrderID: e => {
          return (
            <Fragment>
              <Input
                className='order-selection'
                type='checkbox'
                id={'checkbox' + e.OrderID}
                name={'checkbox' + e.OrderID}
                checked={e.checked}
                onChange={event => {
                  OrderActions.selectOrderHandler(event, e.OrderID)
                }}
              />
            </Fragment>
          )
        },
        brandName: e => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                OrderActions.fastFilter(
                  { filterID: 0, column: 'originBrandID', value: e.originBrandID },
                  { column: 'originBrandID', value: e.originBrand.brandName }
                )
              }}
            >
              {e.originBrand ? e.originBrand.brandName : ''}
            </span>
          )
        },
        timeInsert: e => {
          return (
            <Fragment>
              <Tooltip target={'orderStatus' + e.OrderID}>{this.orderStatus[e.orderFlowStatus]}</Tooltip>
              <FontAwesomeIcon
                size='lg'
                icon={isOurID(e.UserID) ? 'ban' : 'tag'}
                id={'orderStatus' + e.OrderID}
                style={{ color: statusColors[e.orderFlowStatus], cursor: 'default' }}
                fixedWidth
              />
              {moment(e.timeInsert).format('YYYY-MM-DD')}
            </Fragment>
          )
        },
        orderLabel: e => {
          return e.orderLabel
        },
        orderConsignee: e => {
          return (
            <Fragment>
              {e.orderConsignee}{' '}
              {e.siblings > 1 && (
                <span
                  style={{ color: 'orange', cursor: 'pointer' }}
                  onClick={() => {
                    OrderActions.clearFilters()
                    OrderActions.addFilter({ filterID: 0, column: 'orderFlowStatus', value: 'NEW' })
                    OrderActions.addFilter({ filterID: 0, column: 'orderFlowStatus', value: 'TENTATIVE' })
                    OrderActions.addFilter({ filterID: 0, column: 'orderFlowStatus', value: 'BACKORDER' })
                    OrderActions.addFilter({ filterID: 0, column: 'orderFlowStatus', value: 'ORDERED' })
                    OrderActions.addFilter({ filterID: 0, column: 'orderFlowStatus', value: 'PACKED' })
                    OrderActions.addFilter({ filterID: 1, column: 'UserID', value: e.UserID }, true)
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'user']} /> {e.siblings}
                </span>
              )}
            </Fragment>
          )
        },
        orderComment: e => {
          if (!e.orderComment) {
            return null
          }
          return (
            <Fragment>
              <Tooltip target={'orderComment' + e.OrderID}>{nl2br(e.orderComment)}</Tooltip>
              <FontAwesomeIcon icon='sticky-note' id={'orderComment' + e.OrderID} />
            </Fragment>
          )
        },
        shippingids: e => {
          if (!e.shippingids) {
            return null
          }
          let shippingids = e.shippingids || []
          return (
            <Fragment>
              {shippingids.map((id, i) => {
                return (
                  <a key={i} href={'https://gls-group.eu/DK/da/find-pakke?match=' + id} target='_blank' rel='noopener noreferrer'>
                    {id}
                    <br />
                  </a>
                )
              })}
            </Fragment>
          )
        },
        products: e => {
          let finalOrderlines = []
          if (e.orderlines.length > 1) {
            const orderlinesGrouped = _.groupBy(e.orderlines, 'orderlineTitle')
            for (const i in orderlinesGrouped) {
              const originalOrderlines = orderlinesGrouped[i]
              let orderlines = Array.from(originalOrderlines)
              if (orderlines.length > 1) {
                let finalOrderline = { ...orderlines[0] }
                if (finalOrderline.grouped === undefined || finalOrderline.grouped) {
                  finalOrderline.orderlineTitleNew = orderlines.length + ' x ' + finalOrderline.orderlineTitle
                  finalOrderline.grouped = true
                  finalOrderlines.push(finalOrderline)
                } else {
                  let orderlinesExpanded = orderlines.map(ol => {
                    ol.grouped = false
                    ol.orderlineTitleNew = '1 x ' + ol.orderlineTitle
                    return ol
                  })
                  finalOrderlines = finalOrderlines.concat(orderlinesExpanded)
                }
              } else {
                let finalOrderline = { ...orderlines[0] }
                finalOrderline.orderlineTitleNew = '1 x ' + finalOrderline.orderlineTitle
                finalOrderlines.push(finalOrderline)
              }
            }
          } else {
            let finalOrderline = { ...e.orderlines[0] }
            finalOrderline.orderlineTitleNew = '1 x ' + finalOrderline.orderlineTitle
            finalOrderlines.push(finalOrderline)
          }
          return (
            <Fragment>
              {finalOrderlines.map((ol, iOL) => {
                return (
                  <Fragment key={iOL}>
                    <OrderlineView ol={ol} originBrandID={e.originBrandID} order={e}></OrderlineView>
                    <br />
                  </Fragment>
                )
              })}
            </Fragment>
          )
        },
        status: e => {
          return (
            <Fragment>
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  style={{
                    background: statusColors[e.orderFlowStatus],
                    color: orderColors[e.orderFlowStatus] ? orderColors[e.orderFlowStatus] : 'black'
                  }}
                >
                  <span>{this.orderStatus[e.orderFlowStatus]}</span>
                </DropdownToggle>
                <DropdownMenu style={{ overflow: 'visible' }}>
                  {Object.keys(this.orderStatus).map((s, is) => {
                    return (
                      <StyledDropdown
                        onClick={item => {
                          if (confirm('Do you want to change order status?')) {
                            OrderActions.updateOrderInstant(item, e)
                          }
                        }}
                        value={s}
                        key={is}
                        style={{ background: statusColors[s], color: orderColors[s] ? orderColors[s] : 'black' }}
                      >
                        {this.orderStatus[s]}
                      </StyledDropdown>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Fragment>
          )
        },
        actions: e => {
          return (
            <Fragment>
              <ButtonGroup>
                <Tooltip target={'edit' + e.OrderID}>Rediger</Tooltip>
                <span onClick={OrderActions.handleClick}>
                  <Button data-cy='order.editBtn' tag={Link} id={'edit' + e.OrderID} to={`${this.props.location.pathname}/` + e.OrderID}>
                    <FontAwesomeIcon icon='edit' />
                  </Button>
                </span>
                <Tooltip target={'print' + e.OrderID}>Print</Tooltip>
                <Button
                  id={'print' + e.OrderID}
                  onClick={() => {
                    window.open('/orders/print?OrderID=' + e.OrderID, '_blank')
                  }}
                >
                  <FontAwesomeIcon icon='print' />
                </Button>
                <Tooltip target={'ship' + e.OrderID}>Send</Tooltip>
                <Button
                  id={'ship' + e.OrderID}
                  onClick={() => {
                    OrderActions.shipOrder(e.OrderID)
                  }}
                  disabled={e.orderRefunded == 1}
                  color='success'
                >
                  <FontAwesomeIcon icon='ship' />
                </Button>
                {!this.props.seasonOrder && (
                  <>
                    <Tooltip target={'refund' + e.OrderID}>Refunder</Tooltip>
                    <Button
                      id={'refund' + e.OrderID}
                      onClick={() => {
                        if (confirm('Vil du refundere denne ordre?')) {
                          OrderActions.refundOrder(e.OrderID)
                        }
                      }}
                      disabled={e.orderRefunded == 1 || (this.state.refundedOrder && this.state.refundedOrder[e.OrderID])}
                      color='danger'
                    >
                      <span className='fa-layers fa-fw'>
                        <FontAwesomeIcon icon='hand-holding-usd' />
                        {e.orderRefunded == 1 && <FontAwesomeIcon icon='ban' size='lg' />}
                      </span>
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Fragment>
          )
        }
      }
    }
    return (
      <Fragment>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <span data-cy='order.title'>{this.props.seasonOrder ? 'Season Order' : 'Ordrer'}</span>
              </Col>
              {!this.props.seasonOrder && (
                <Col md='auto'>
                  <Button onClick={OrderActions.getSelectedOrders} disabled={!this.state.selectedOrderLines.length}>
                    {' '}
                    Eksporter til PO System{' '}
                  </Button>
                </Col>
              )}
              <Col md='2'>
                <Input
                  type='select'
                  name='orderFlowStatus'
                  id='orderFlowStatus'
                  onChange={item => {
                    let bulkSelectedOrders = this.state.orders.filter(elem => elem.checked)

                    if (bulkSelectedOrders.length > 0 && !this.state.selectedOrderLines.length) {
                      if (bulkSelectedOrders.length > 0) {
                        if (confirm('Do you want to change the status of selected orders?')) {
                          OrderActions.bulkUpdateSeasonOrderStatus(item, bulkSelectedOrders)
                        } else {
                          item.target.value = false // reset the dropdown
                        }
                      } else {
                        item.target.value = false // reset the dropdown
                        toast.error('No orders were selected.', {
                          autoClose: 7000,
                          onClose: () => {
                            OrderActions.clearToast()
                          }
                        })
                      }
                    } else if (this.state.selectedOrderLines.length && !bulkSelectedOrders.length) {
                      if (confirm('Do you want to split/merge selected orderlines?')) {
                        OrderActions.updateOrderGlobal(item, this.orderStatus)
                      } else {
                        item.target.value = false // reset the dropdown
                      }
                    } else if (this.state.selectedOrderLines.length && bulkSelectedOrders.length) {
                      item.target.value = false // reset the dropdown
                      toast.error('Order(s) and Orderline(s) cannot be selected simultaneously for this action.', {
                        autoClose: 7000,
                        onClose: () => {
                          OrderActions.clearToast()
                        }
                      })
                    } else {
                      item.target.value = false // reset the dropdown
                      toast.error('No orders were selected.', {
                        autoClose: 7000,
                        onClose: () => {
                          OrderActions.clearToast()
                        }
                      })
                    }
                  }}
                >
                  <option selected='true' disabled={true} value={false}>
                    Change Status
                  </option>
                  {Object.keys(this.orderStatus).map((s, is) => {
                    return (
                      <option key={is} value={s}>
                        {this.orderStatus[s]}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {/* {!this.props.seasonOrder && (
              <Row style={{ padding: '19px', marginBottom: '25px' }}>
                <Col xs={3}>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id={'checkbox'}
                    name={'checkbox'}
                    checked={this.state.isAllOrderLinesChecked}
                    onChange={e => {
                      OrderActions.selectAllOrderLines(e)
                    }}
                    ref={input => {
                      if (input) {
                        input.indeterminate = this.state.isIndeterminate
                      }
                    }}
                  />
                  <Label check htmlFor={'checkbox'}>
                    Select All Orderlines
                  </Label>
                </Col>
              </Row>
            )} */}
            {this.props.seasonOrder && (
              <DynamicTable
                responsive
                size='sm'
                data-cy='order.table'
                data={this.state.orders}
                headers={{
                  OrderID: ' ',
                  timeInsert: 'Dato',
                  orderLabel: '#',
                  brandName: 'Klub',
                  orderConsignee: 'Bruger',
                  products: 'Produkter',
                  orderComment: 'Note',
                  shippingids: 'Trackingnumre',
                  status: 'Status',
                  AddressID: 'Address',
                  actions: ' '
                }}
                options={dynamicTableOptions}
              />
            )}
            {!this.props.seasonOrder && (
              <DynamicTable
                responsive
                {...(this.state.isAllChecked && { showAll: true })}
                size='sm'
                data-cy='order.table'
                data={this.state.orders}
                headers={{
                  OrderID: ' ',
                  timeInsert: 'Dato',
                  orderLabel: '#',
                  brandName: 'Klub',
                  orderConsignee: 'Bruger',
                  products: 'Produkter',
                  orderComment: 'Note',
                  shippingids: 'Trackingnumre',
                  status: 'Status',
                  AddressID: 'Address',
                  actions: ' '
                }}
                options={dynamicTableOptions}
              />
            )}
            <Modal isOpen={this.state.previewOrders} toggle={() => OrderActions.logoutModal()} size='xl'>
              <ModalHeader toggle={() => OrderActions.logoutModal()}>OrderLines</ModalHeader>
              <ModalBody>
                <DynamicTable
                  data={this.state && this.state.c5SelectedOrders && !!this.state.c5SelectedOrders.length ? this.state.c5SelectedOrders : []}
                  responsive
                  size='sm'
                  options={{
                    fieldFunctions: {
                      c5Exported: e => {
                        return e.c5Exported ? <span style={{ color: 'red' }}>YES</span> : <span>NO</span>
                      }
                    }
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button onClick={OrderActions.generateDropShipment} disabled={!this.state.c5SelectedOrders.length}>
                  Opret drop-ship
                </Button>
                <Button onClick={OrderActions.generateRegularShipment} disabled={!this.state.c5SelectedOrders.length}>
                  Opret almindelig
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={!!this.state.c5SupplierError} toggle={() => OrderActions.clearC5Error()} size='md'>
              <ModalHeader toggle={() => OrderActions.clearC5Error()}>Error</ModalHeader>
              <ModalBody>
                <p>{this.state.c5SupplierError}</p>
              </ModalBody>
              <ModalFooter>
                <Button onClick={OrderActions.clearC5Error}>Ok</Button>
              </ModalFooter>
            </Modal>
          </CardBody>
        </Card>
      </Fragment>
    )
  }
}

const StyledButton = styled(Button)`
  background: ${props => props.selected && '#716ACA'} !important;
`
class OrderFilters extends Component {
  constructor(props) {
    super(props)
    this.store = OrderStore
    this.storeKeys = [
      'filters',
      'seasonOrderFilters',
      'filterName',
      'suppliers',
      'manufacturers',
      'dynamicFilters',
      'predefinedFilters',
      'orderFilter',
      'seasonOrderPredefinedFilters'
    ]
    this.orderStatus = this.props.seasonOrder ? seasonOrderStatus : orderStatus
  }
  componentDidMount() {
    OrderActions.setOrderFilter(this.props.seasonOrder ? 'seasonOrder' : 'order')
    if ((this.state.suppliers && this.state.suppliers.length == 0) || !this.state.suppliers) {
      OrderActions.getSuppliers()
    }
    if ((this.state.manufacturers && this.state.manufacturers.length == 0) || !this.state.manufacturers) {
      OrderActions.getManufacturers()
    }
  }
  handleKeypress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      OrderActions.applyFilters()
    }
  }
  render() {
    const predefinedFilters = this.props.seasonOrder ? this.state.seasonOrderPredefinedFilters : this.state.predefinedFilters
    let filterFields = {
      OrderID: 'Ordrenummer',
      ConfirmationID: 'Confirmation nummer',
      orderFlowStatus: 'Ordrestatus',
      supplier: 'Leverandør',
      manufacturer: 'Producent',
      variationid: 'Varenummer',
      productname: 'Vare Navn',
      UserID: 'Bruger ID',
      userName: 'Bruger Navn',
      originBrandID: 'Brand ID',
      ProductID: 'Produktnummer',
      orderConsigneeEmail: 'User Email',
      orderConsigneePhone: 'User Phone Number',
      AddressID: 'Address',
      orderConsigneeName: 'Company'
    }
    if (!this.state || !this.state.suppliers || !this.state.manufacturers) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <Card>
        <CardHeader tag='h4'>Filtre</CardHeader>
        <CardBody>
          <Table responsive size='sm'>
            <thead>
              <tr>
                <th>Kilde</th>
                <th>Operatør</th>
                <th>Værdi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state[this.state.filterName].map((e, i) => {
                let filter = (
                  <Input
                    type='text'
                    onKeyDown={this.handleKeypress}
                    onChange={t => {
                      OrderActions.updateFilter(e, t)
                    }}
                    value={e.value}
                    name='value'
                  />
                )
                switch (e.column) {
                  case 'orderFlowStatus':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          OrderActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                        style={{ color: statusColors[e.value] }}
                      >
                        <option value=''>Vælg</option>
                        {Object.keys(this.orderStatus).map((s, is) => {
                          return (
                            <option key={is} value={s}>
                              {this.orderStatus[s]}
                            </option>
                          )
                        })}
                      </Input>
                    )
                    break
                  case 'supplier':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          OrderActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value=''>Vælg</option>
                        {this.state.suppliers.map((s, is) => {
                          return <option key={is}>{s.supplierName}</option>
                        })}
                      </Input>
                    )
                    break
                  case 'manufacturer':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          OrderActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value=''>Vælg</option>
                        {this.state.manufacturers.map((s, is) => {
                          //return <option key={is} value={s.CategoryID}>{s.categoryName}</option>
                          return <option key={is}>{s.categoryName}</option>
                        })}
                      </Input>
                    )
                    break
                }
                let column = (
                  <Input
                    type='select'
                    onChange={t => {
                      OrderActions.updateFilter(e, t)
                    }}
                    value={e.column}
                    name='column'
                  >
                    <option value='' disabled>
                      Vælg
                    </option>
                    {Object.keys(filterFields).map((s, is) => {
                      return (
                        <option key={is} value={s}>
                          {filterFields[s]}
                        </option>
                      )
                    })}
                  </Input>
                )
                let operator = 'måske noget her'
                if (
                  e.column &&
                  (e.column === 'productname' || e.column === 'userName' || e.column === 'orderConsigneePhone' || e.column === 'orderConsigneeEmail')
                ) {
                  operator = 'indeholder'
                } else {
                  operator = 'lig med'
                }
                return (
                  <tr key={i}>
                    <td>{column}</td>
                    <td>{operator}</td>
                    <td onKeyDown={this.handleKeypress}>{filter}</td>
                    <td>
                      <Button
                        onClick={() => {
                          OrderActions.removeFilter(e.column)
                        }}
                      >
                        <FontAwesomeIcon icon='minus' />
                      </Button>
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan='3'></td>
                <td>
                  <Button onClick={OrderActions.addFilter}>
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <React.Fragment>
            {this.state[this.state.filterName].length > 0 && <Button onClick={OrderActions.applyFilters}>Filtrer</Button>}
          </React.Fragment>
          <React.Fragment>
            {this.state[this.state.filterName].length > 0 && (
              <Button
                color='danger'
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  OrderActions.clearFilters()
                  OrderActions.applyFilters()
                }}
              >
                Clear Filtrer
              </Button>
            )}
          </React.Fragment>
          <AreaSeperator row />
          <h6>Præ-definerede filtre</h6>
          <ButtonGroup>
            {predefinedFilters.map((item, index) => {
              return (
                <StyledButton
                  className='m-1'
                  key={index}
                  selected={item.selected}
                  onClick={() => {
                    item.selected = !item.selected
                    if (item.selected) {
                      OrderActions.fastFilter({ filterID: 0, column: 'orderFlowStatus', value: item.value })
                    } else {
                      OrderActions.removePreDefinedFilter({ filterID: 0, column: 'orderFlowStatus', value: item.value })
                    }
                  }}
                >
                  {item.name}
                </StyledButton>
              )
            })}
          </ButtonGroup>
          <br />
        </CardBody>
      </Card>
    )
  }
}
