import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { Form, FormGroup, Label, Col, Row, Input, Button } from 'reactstrap'
import FormField from 'Components/formField'
import 'react-image-crop/dist/ReactCrop.css'
import Proxies from 'Components/proxies'
import { StyledDropdown } from '../../campaigns/SantanderCampaignUserSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { GET } = Proxies

const HighlightedProductForm = props => {
  const [loading, setLoading] = useState(false)
  const { event, idx, highlightedProducts, setHighlightedProducts, HighlightedProductID } = props
  const [highlightedProductDetails, setHighlightedProductDetails] = useState({})
  const [selectedCatalog, setSelectedCatalog] = useState([])
  const [selectedImage, setSelectedImage] = useState({})
  const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [products, setProducts] = useState([])
  const [productImages, setProductImages] = useState([])
  const [brandsLoading, setBrandsLoading] = useState(false)
  const [selectedID, setSelectedID] = useState(HighlightedProductID ? true : false)
  const [languages, setLanguages] = useState([])

  const fetchHighlightedProduct = HighlightedProductID => {
    GET('highlightedproducts/fetch', { HighlightedProductID }).then(responseJSON => {
      if (!responseJSON.error) {
        setHighlightedProductDetails({
          HighlightedProductID,
          CatalogID: responseJSON.CatalogID,
          categoryName: responseJSON.categoryName,
          ProductID: responseJSON.ProductID,
          title: responseJSON.title,
          content: responseJSON.content,
          productImageSrc: responseJSON.productImageSrc,
          BrandIDs: responseJSON.BrandIDs,
          translations: responseJSON.translations.length ? responseJSON.translations : [{ title: '', LanguageID: '', content: '' }]
        })
        setHighlightedProducts([
          {
            HighlightedProductID,
            CatalogID: responseJSON.CatalogID,
            categoryName: responseJSON.categoryName,
            ProductID: responseJSON.ProductID,
            title: responseJSON.title,
            content: responseJSON.content,
            productImageSrc: responseJSON.productImageSrc,
            BrandIDs: responseJSON.BrandIDs,
            translations: responseJSON.translations.length ? responseJSON.translations : [{ title: '', LanguageID: '', content: '' }]
          }
        ])
        !!responseJSON && !!responseJSON.BrandIDs && !!responseJSON.BrandIDs.length && getLanguages(responseJSON.BrandIDs)
        updateHighlightedProductCatalog(responseJSON.CatalogID)
      }
    })
  }

  const fetchCatalog = () => {
    GET('catalog/loadCatalogs').then(responseJSON => {
      if (responseJSON.error) return setSelectedCatalog([])
      let catalogs = responseJSON.map(item => ({ CatalogID: item.CatalogID, moduleName: item.moduleName }))
      catalogs.sort((a, b) => a.moduleName.localeCompare(b.moduleName))
      return setSelectedCatalog(catalogs)
    })
  }

  function getLanguages(BrandIDs) {
    GET('brands/brand-languages', { BrandIDs }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setLanguages(responseJSON)
      }
    })
  }

  const updateHighlightedProductCatalog = CatalogID => {
    setBrandsLoading(true)
    GET('catalog/catalogBrands', { CatalogID: CatalogID }).then(responseJSON => {
      setBrandsLoading(false)
      if (responseJSON.error) {
        return setBrands([])
      }
      let allbrands = responseJSON.map(item => ({ BrandID: item.BrandID, brandName: item.brandName }))
      allbrands.sort((a, b) => a.brandName.localeCompare(b.brandName))
      setBrands(allbrands)
    })
  }

  const fetchCategories = () => {
    GET('categories/pool').then(responseJSON => {
      if (responseJSON.error) return setCategories([])
      const temp = []
      responseJSON.forEach(e => {
        if (e.categoryTaxonomy !== 'PRODUCT') return
        temp.push(e)
      })
      return setCategories(
        temp.map(item => ({ CategoryID: item.CategoryID, categoryName: item.categoryName, categoryTaxonomy: item.categoryTaxonomy }))
      )
    })
  }

  const fetchAllProducts = () => {
    setLoading(true)
    GET('products/pool').then(responseJSON => {
      setAllProducts(responseJSON)
      setLoading(false)
    })
  }

  const fetchProducts = (CatalogID, CategoryName) => {
    setProducts(
      allProducts.filter(item => {
        if (item.product === CategoryName) {
          for (let i = 0; i < item.catalogs.length; i++) {
            if (Number(item.catalogs[i].CatalogID) === Number(CatalogID)) {
              return item
            }
          }
        }
      })
    )
  }

  const fetchSelectedproductImages = ProductID => {
    GET('products/fetch', { ProductID }).then(responseJSON => {
      if (responseJSON.error) return setProductImages([])
      setProductImages(responseJSON.attachments)
      let attachments = responseJSON.attachments
      let img = attachments.filter(e => e.imageOriginSource === highlightedProductDetails.productImageSrc)
      img ? setSelectedImage(img[0]) : setSelectedImage({})
    })
  }

  const fetchproductImages = ProductID => {
    GET('products/fetch', { ProductID }).then(responseJSON => {
      if (responseJSON.error) return setProductImages([])
      setProductImages(responseJSON.attachments)
    })
  }

  const handleInputs = (key, value) => {
    let res = { ...highlightedProducts[idx], [key]: value }
    highlightedProducts[idx] = res
    setHighlightedProducts(highlightedProducts)
  }

  const editBrandIDS = item => {
    let selectedBrand = brands.map(brand => {
      for (let i = 0; i < item.length; i++) {
        if (Number(brand.BrandID) === Number(item[i])) {
          brand.checked = true
          break
        }
      }
      return brand
    })
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleInputs('BrandIDs', BrandIDs)
    setSelectedID(false)
  }

  const updateBrands = item => {
    let selectedBrand = brands.map(brand => {
      if (Number(brand.BrandID) === Number(item.value)) brand.checked = item.checked
      return brand
    })
    setBrands(selectedBrand)
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleInputs('BrandIDs', BrandIDs)
  }

  const removeForm = () => {
    let removeIdx = highlightedProducts.filter((e, id) => {
      if (id !== idx) return e
    })
    setHighlightedProducts(removeIdx)
  }

  useEffect(() => {
    fetchCatalog()
    fetchAllProducts()
    fetchCategories()
  }, [])

  const addTranslation = () => {
    let translations = highlightedProductDetails.translations
    translations.push({ title: '', LanguageID: '', content: '' })
    setHighlightedProductDetails({ ...highlightedProductDetails, translations: translations })
  }

  const removeTranslation = i => {
    let translations = highlightedProductDetails.translations
    translations.splice(i, 1)
    setHighlightedProductDetails({ ...highlightedProductDetails, translations: translations })
  }

  const updateData = (field, index, type) => {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let tempArray = highlightedProductDetails[type]
    tempArray[index][fieldName] = fieldValue
    setHighlightedProductDetails({ ...highlightedProductDetails, [type]: tempArray })
  }

  useEffect(() => {
    if (!!HighlightedProductID && highlightedProductDetails.ProductID) {
      fetchSelectedproductImages(highlightedProductDetails.ProductID)
    }
  }, [products])

  useEffect(() => {
    if (!!HighlightedProductID && allProducts.length > 0) {
      fetchProducts(highlightedProductDetails.CatalogID, highlightedProductDetails.categoryName)
    }
  }, [allProducts])

  useEffect(() => {
    if (!!HighlightedProductID && brands.length > 0 && selectedID) {
      editBrandIDS(highlightedProductDetails.BrandIDs)
    }
  }, [brands])

  useEffect(() => {
    HighlightedProductID ? fetchHighlightedProduct(HighlightedProductID) : null
  }, [HighlightedProductID])

  useEffect(() => {
    setHighlightedProductDetails(event)
  }, [event])

  return (
    <>
      <CollapsibleCard startOpen header={<strong>Highlighted Product</strong>}>
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <>
            <Form
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              {/* Catalogue */}
              <FormGroup row>
                <Label for='CatalogID' sm={3}>
                  Catalog
                </Label>
                <Col sm={9}>
                  <Input
                    className={highlightedProductDetails.CatalogID ? 'is-valid' : 'is-invalid'}
                    type='select'
                    required
                    id='CatalogID'
                    name='CatalogID'
                    value={highlightedProductDetails.CatalogID ? highlightedProductDetails.CatalogID : ''}
                    onChange={e => {
                      setHighlightedProductDetails({
                        ...highlightedProductDetails,
                        CatalogID: e.target.value,
                        highlightedProductID: ''
                      })
                      updateHighlightedProductCatalog(e.target.value)
                      if (highlightedProductDetails.categoryName) {
                        setProducts([])
                        fetchProducts(e.target.value, highlightedProductDetails.categoryName)
                      }
                      handleInputs(e.target.name, Number(e.target.value))
                    }}
                  >
                    <option value=''>Select Catalog</option>
                    {selectedCatalog.map((category, i) => {
                      return (
                        <option value={category.CatalogID} key={i}>
                          {`${category.moduleName} - ${category.CatalogID}`}
                        </option>
                      )
                    })}
                  </Input>
                </Col>
              </FormGroup>

              {/*Brands*/}
              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='BrandID'> Brands </Label>
                  </Col>
                  {brandsLoading ? (
                    <FontAwesomeIcon icon='spinner' spin />
                  ) : (
                    <Col xs='9'>
                      <StyledDropdown
                        disabled={!highlightedProductDetails || !highlightedProductDetails.CatalogID}
                        required={true}
                        data={
                          !brands.length
                            ? {}
                            : {
                                label: 'All',
                                value: 'all',
                                expanded: true,
                                children: brands.map(item => ({
                                  label: `${item.BrandID} - ${item.brandName}`,
                                  value: item.BrandID,
                                  checked: item.checked
                                }))
                              }
                        }
                        onChange={item => updateBrands(item)}
                        keepTreeOnSearch={true}
                        keepChildrenOnSearch={true}
                        showPartiallySelected={true}
                        texts={{
                          placeholder: !brands.length ? 'Ingen brand fundet' : 'Vælg brand',
                          noMatches: 'Ingen brand fundet',
                          labelRemove: 'Fjern'
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </FormGroup>

              {/* Category */}
              <FormGroup row>
                <Label for='categoryName' sm={3}>
                  Category
                </Label>
                <Col sm={9}>
                  <Input
                    className={highlightedProductDetails.categoryName ? 'is-valid' : 'is-invalid'}
                    required
                    disabled={!highlightedProductDetails || !highlightedProductDetails.CatalogID}
                    type='select'
                    id='categoryName'
                    name='categoryName'
                    value={highlightedProductDetails.categoryName ? highlightedProductDetails.categoryName : ''}
                    onChange={e => {
                      setHighlightedProductDetails({ ...highlightedProductDetails, categoryName: e.target.value })
                      fetchProducts(highlightedProductDetails.CatalogID, e.target.value)
                      handleInputs(e.target.name, e.target.value)
                    }}
                  >
                    <option value=''>Select Category</option>
                    {categories.map((category, i) => {
                      return (
                        <option value={category.categoryName} key={i}>
                          {`${category.categoryName} - ${category.CategoryID}`}
                        </option>
                      )
                    })}
                  </Input>
                </Col>
              </FormGroup>

              {/* PRODUCT */}
              {!!highlightedProductDetails.categoryName && !!highlightedProductDetails.CatalogID && (
                <FormGroup row>
                  <Label for='ProductID' sm={3}>
                    Product
                  </Label>
                  <Col sm={9}>
                    <Input
                      className={highlightedProductDetails.ProductID ? 'is-valid' : 'is-invalid'}
                      required
                      type='select'
                      id='ProductID'
                      name='ProductID'
                      value={highlightedProductDetails.ProductID ? highlightedProductDetails.ProductID : ''}
                      onChange={e => {
                        setHighlightedProductDetails({ ...highlightedProductDetails, ProductID: e.target.value })
                        fetchproductImages(e.target.value)
                        handleInputs(e.target.name, Number(e.target.value))
                      }}
                    >
                      <option value=''>Select product</option>
                      {products.map((product, i) => {
                        return (
                          <option value={product.ProductID} key={i}>
                            {`${product.ProductID} - ${product.elementTitle} - ${product.productStatus}`}
                          </option>
                        )
                      })}
                    </Input>
                  </Col>
                </FormGroup>
              )}
              {/* Title and Content */}
              {!!highlightedProductDetails.ProductID && (
                <>
                  <FormField
                    name='title'
                    onChange={e => {
                      setHighlightedProductDetails({ ...highlightedProductDetails, title: e.target.value })
                      handleInputs(e.target.name, e.target.value)
                    }}
                    field='title'
                    data={highlightedProductDetails}
                  />
                  <FormField
                    name='content'
                    rows={5}
                    onChange={e => {
                      setHighlightedProductDetails({ ...highlightedProductDetails, content: e.target.value })
                      handleInputs(e.target.name, e.target.value)
                    }}
                    field='content'
                    data={highlightedProductDetails}
                    type='textarea'
                  />
                </>
              )}
              {/* Selected Product Images */}
              {!!highlightedProductDetails.ProductID && (
                <div style={{ display: 'flex', gap: '2rem', margin: '1rem 0', flexWrap: 'wrap' }}>
                  {!!productImages &&
                    !!productImages.length &&
                    productImages.map((image, i) => (
                      <img
                        key={i}
                        onClick={() => {
                          if (selectedImage) {
                            if (image.ImageID === selectedImage.ImageID) {
                              setHighlightedProductDetails({ ...highlightedProductDetails, productImageSrc: '' })
                              setSelectedImage({})
                              handleInputs('productImageSrc', '')
                            } else {
                              setHighlightedProductDetails({ ...highlightedProductDetails, productImageSrc: image.imageOriginSource })
                              setSelectedImage(image)
                              handleInputs('productImageSrc', image.imageOriginSource)
                            }
                            return
                          }
                          setHighlightedProductDetails({ ...highlightedProductDetails, productImageSrc: image.imageOriginSource })
                          setSelectedImage(image)
                          handleInputs('productImageSrc', image.imageOriginSource)
                        }}
                        style={
                          selectedImage
                            ? {
                                width: '200px',
                                objectFit: 'contain',
                                boxShadow: image.ImageID === selectedImage.ImageID ? `0px 0px 15px 0px #000` : ''
                              }
                            : {
                                width: '200px',
                                objectFit: 'contain',
                                boxShadow: ''
                              }
                        }
                        src={image.imageOriginSource}
                        alt={image.imageName}
                      />
                    ))}
                </div>
              )}
              {HighlightedProductID &&
                !!highlightedProductDetails &&
                !!highlightedProductDetails.BrandIDs &&
                !!highlightedProductDetails.BrandIDs.length && (
                  <FormGroup row>
                    <Label for='textColor' sm={3}>
                      Highlighted Product Translations
                    </Label>
                    <Col sm={9}>
                      {!!highlightedProductDetails &&
                        !!highlightedProductDetails.translations &&
                        !!highlightedProductDetails.translations.length &&
                        highlightedProductDetails.translations.map((translation, inx) => {
                          return (
                            <div key={inx}>
                              <Row>
                                <Col sm={11}>
                                  <Input
                                    type='select'
                                    name='LanguageID'
                                    value={translation.LanguageID ? Number(translation.LanguageID) : ''}
                                    id='LanguageID'
                                    onChange={e => updateData(e, inx, 'translations')}
                                  >
                                    <option value='' disabled>
                                      Click to Select
                                    </option>
                                    {!!languages &&
                                      !!languages.length &&
                                      languages.map((e, i) => {
                                        return (
                                          <option value={Number(e.LanguageID)} key={i}>
                                            {e.languageName}
                                          </option>
                                        )
                                      })}
                                  </Input>
                                </Col>
                                <Col sm={1}>
                                  <Button
                                    onClick={() =>
                                      inx === 0
                                        ? inx !== highlightedProductDetails.translations.length - 1
                                          ? removeTranslation(inx)
                                          : addTranslation()
                                        : removeTranslation(inx)
                                    }
                                    color={
                                      inx === 0 ? (inx !== highlightedProductDetails.translations.length - 1 ? 'danger' : 'secondary') : 'danger'
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={inx === 0 ? (inx !== highlightedProductDetails.translations.length - 1 ? 'minus' : 'plus') : 'minus'}
                                    />
                                  </Button>
                                </Col>
                              </Row>
                              <br></br>
                              <Row>
                                <Label for='textColor' sm={2}>
                                  Title
                                </Label>
                                <Col sm={9}>
                                  <Input name='title' value={translation.title} type='text' onChange={e => updateData(e, inx, 'translations')} />
                                </Col>
                                {inx === highlightedProductDetails.translations.length - 1 && inx !== 0 && (
                                  <Col sm={1}>
                                    <Button onClick={addTranslation}>
                                      <FontAwesomeIcon icon='plus' />
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                              <br></br>
                              <Row>
                                <Label for='textColor' sm={2}>
                                  Content
                                </Label>
                                <Col sm={9}>
                                  <Input
                                    name='content'
                                    value={translation.content}
                                    type='textarea'
                                    onChange={e => updateData(e, inx, 'translations')}
                                  />
                                </Col>
                              </Row>
                              <br></br>
                              <Row>
                                <Label for='textColor' sm={2}>
                                  Category Text
                                </Label>
                                <Col sm={9}>
                                  <Input
                                    name='categoryName'
                                    value={translation.categoryName}
                                    type='textarea'
                                    onChange={e => updateData(e, inx, 'translations')}
                                  />
                                </Col>
                              </Row>
                              <br></br>
                            </div>
                          )
                        })}
                    </Col>
                  </FormGroup>
                )}
            </Form>
            {!HighlightedProductID ? (
              <div style={{ marginBottom: '5%' }}>
                <Button
                  className='float-right'
                  color='danger'
                  onClick={() => {
                    removeForm()
                  }}
                >
                  <FontAwesomeIcon icon='minus' />
                </Button>
              </div>
            ) : null}
          </>
        )}
      </CollapsibleCard>
    </>
  )
}

export default HighlightedProductForm
