/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Form, Input } from 'reactstrap'
import FormField from 'Components/formField'
import DatePicker from 'react-datepicker'
import { InvoiceStore, InvoiceActions } from 'Stores/invoiceStore'
import { currencyFormatter, numberFormatter } from 'Util/currencyFormatter'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  padding: 2px 100px 1px 5px !important;
  @media only screen and (min-width: 2000px) {
    padding: 2px 180px 1px 5px !important;
  }
  @media only screen and (max-width: 1024px) {
    padding: 2px 65px 1px 5px !important;
  }
`
const StyledInvoiceAmount = styled.b`
  padding-right: 20px !important;
  @media only screen and (min-width: 2000px) {
    padding-right: 37px !important;
  }
`

const StyledSpan = styled.span`
  margin: 0px 15px !important;
`
const AdminInvoice = props => {
  if (props.match.params.InvoiceID) {
    return <AdminInvoiceDetails {...props} />
  }
  if (props.match.params.BrandID) {
    if (props.create) {
      return <AdminInvoiceDetails {...props} />
    }
    return <AdminInvoiceList {...props} />
  }
  return <AdminInvoiceBrandList {...props} />
}
export default AdminInvoice

class AdminInvoiceDetails extends Component {
  constructor(props) {
    super(props)
    this.store = InvoiceStore
    this.updateDateField = this.updateDateField.bind(this)
    this.createStatusList = ['OPEN']
    this.editStatusList = ['OPEN', 'CLOSED']
  }
  componentWillMount() {
    super.componentWillMount()
    this.resetInvoice()
  }
  resetInvoice() {
    if (this.props.create) {
      InvoiceActions.createEmptyInvoice(this.props.match.params.BrandID)
    } else {
      InvoiceActions.getInvoice(this.props.match.params.InvoiceID, true)
    }
  }
  updateDateField(m, field) {
    let data = {
      target: {
        name: field,
        value: null
      }
    }
    if (field === 'timeEnd') {
      data.target.value = m.endOf('day').toString()
    } else if (field === 'timeStart') {
      data.target.value = m.startOf('day').toString()
    }
    InvoiceActions.updateInvoiceField(data)
  }
  render() {
    if (this.state.invoiceLine && this.state.invoiceLine.InvoiceID && this.props.create) {
      if (this.props.freight) {
        return <Redirect to={'/fragt/' + this.state.invoiceLine.originBrandID + '/' + this.state.invoiceLine.InvoiceID} />
      } else {
        return <Redirect to={'/admininvoice/' + this.state.invoiceLine.originBrandID + '/' + this.state.invoiceLine.InvoiceID} />
      }
    }
    if (this.state.fetchingInvoiceData || this.state.fetchingInvoiceLine) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={this.props.create} header={<strong>Detaljer for faktura</strong>}>
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormField data={this.state.invoiceLine} field={'invoiceTitle'} name='Titel' onChange={InvoiceActions.updateInvoiceField} required />
            <FormField field={'invoiceStatus'} name='Status'>
              <Input type='select' name='invoiceStatus' value={this.state.invoiceLine.invoiceStatus} onChange={InvoiceActions.updateInvoiceField}>
                {this.props.create &&
                  this.createStatusList.map(e => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
                {!this.props.create &&
                  this.editStatusList.map(e => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
              </Input>
            </FormField>
            <FormField field={'timeStart'} name='Start dato'>
              <DatePicker
                selected={moment(this.state.invoiceLine.timeStart)}
                onChange={e => {
                  this.updateDateField(e, 'timeStart')
                }}
              />
            </FormField>
            <FormField field={'timeEnd'} name='Slut dato'>
              <DatePicker
                selected={moment(this.state.invoiceLine.timeEnd)}
                onChange={e => {
                  this.updateDateField(e, 'timeEnd')
                }}
              />
            </FormField>
            <Button onClick={InvoiceActions.saveInvoice} disabled={this.state.isSaving}>
              {this.state.isSaving ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetInvoice} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
        {!this.props.create && (
          <CollapsibleCard header={<strong>Linier</strong>} startOpen>
            <DynamicTable
              responsive
              size='sm'
              data={this.state.invoiceData}
              headers={{
                OrderlineID: '#',
                ConfirmationID: 'Confirmation ID',
                OrderID: 'Ordre ID',
                orderType: 'Order Type',
                UserID: 'Bruger ID',
                userName: 'Bruger',
                userUID: 'Unikt ID',
                userCompany: 'Virksomhed',
                orderlineTitle: 'Vare',
                orderAmountPoints: 'Point',
                orderPointRatio: 'Ratio',
                orderlineProductPrice: 'Basis',
                orderCurrency: 'Valuta',
                timeInsert: 'Oprettet',
                timeShipped: 'Afsendt',
                vaultSlug: 'Vault Slug'
              }}
              options={{
                fieldFunctions: {
                  ConfirmationID: e => {
                    return e.order.ConfirmationID
                  },
                  OrderID: e => {
                    return e.order.OrderID
                  },
                  orderType: e => {
                    return e.orderCustomOrder ? 'Custom' : 'Normal'
                  },
                  UserID: e => {
                    return e.UserID
                  },
                  userName: e => {
                    return e.userName
                  },
                  userUID: e => {
                    return e.userUID
                  },
                  userCompany: e => {
                    return e.userCompany
                  },
                  timeInsert: e => {
                    return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm') : ''
                  },
                  timeShipped: e => {
                    return e.timeShipped ? moment(e.timeShipped).format('DD-MM-YYYY HH:mm') : ''
                  }
                },
                fieldStringFunctions: {
                  timeInsert: e => {
                    return moment(e.timeInsert).format('x')
                  },
                  timeShipped: e => {
                    return moment(e.timeShipped).format('x')
                  }
                }
              }}
            />
          </CollapsibleCard>
        )}
      </React.Fragment>
    )
  }
}

class AdminInvoiceList extends Component {
  constructor(props) {
    super(props)
    this.store = InvoiceStore
  }
  componentDidMount() {
    if (this.state.invoices.length === 0 || this.state.currBrandID !== this.props.match.params.BrandID) {
      InvoiceActions.getAdminInvoices(this.props.match.params.BrandID)
    }
    if (
      this.state.invoiceBrands.length == 0 ||
      !this.state.invoiceBrands.find(e => {
        return e.BrandID == this.props.match.params.BrandID
      })
    ) {
      InvoiceActions.getBrands()
    }
  }
  render() {
    if (this.state.fetchingInvoices) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let currBrand = this.state.invoiceBrands.find(e => {
      return e.BrandID == this.props.match.params.BrandID
    })
    let currBrandLabel = ''
    if (currBrand) {
      currBrandLabel = currBrand.brandName
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Fakturaer for {currBrandLabel}{' '}
            <Button tag={Link} to={this.props.location.pathname + '/new'} className='float-right'>
              Opret ny
            </Button>
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          headerscss={{
            invoiceAmountTotal: { whiteSpace: 'nowrap', textAlign: 'right' },
            invoiceTotalPoints: { whiteSpace: 'nowrap', textAlign: 'right' },
            invoiceBasisTotal: { whiteSpace: 'nowrap', textAlign: 'right' }
          }}
          data={this.state.invoices}
          headers={{
            InvoiceID: '#',
            invoiceStatus: 'Status',
            invoiceTitle: 'Titel',
            timeStart: 'Kører fra',
            timeEnd: 'Kører til',
            brandPointRatio: 'Point Ratio',
            invoiceAmountTotal: 'Invoice/sales price',
            invoiceTotalPoints: 'Total Points',
            invoiceBasisTotal: 'Basis/purchase price'
          }}
          options={{
            startSort: {
              column: 'InvoiceID',
              dir: 'DESC'
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.InvoiceID}>Detaljer</Tooltip>
                    <Button tag={Link} to={this.props.location.pathname + '/' + e.InvoiceID} id={'edit' + e.InvoiceID}>
                      <FontAwesomeIcon icon='info-circle' />
                    </Button>
                    <Tooltip target={'copy' + e.InvoiceID}>Kopier</Tooltip>
                    <Button
                      id={'copy' + e.InvoiceID}
                      onClick={() => {
                        InvoiceActions.copyInvoice(e, this.props)
                      }}
                    >
                      <FontAwesomeIcon icon={['far', 'copy']} />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            },
            fieldFunctions: {
              timeStart: e => {
                return e.timeStart ? moment(e.timeStart).format('DD-MM-YYYY HH:mm') : ''
              },
              timeEnd: e => {
                return e.timeEnd ? moment(e.timeEnd).format('DD-MM-YYYY HH:mm') : ''
              },
              origin: e => {
                return e.origin && e.origin.brandPointRatio ? e.origin.brandPointRatio : ''
              },
              invoiceAmountTotal: e => {
                return <StyledSpan> {e.invoiceAmountTotal > 0 ? currencyFormatter.format(e.invoiceAmountTotal) : '0,00 kr.'}</StyledSpan>
              },
              invoiceBasisTotal: e => {
                return <StyledSpan>{e.invoiceBasisTotal > 0 ? currencyFormatter.format(e.invoiceBasisTotal) : '0,00 kr.'}</StyledSpan>
              },
              invoiceTotalPoints: e => {
                return <StyledSpan>{e.invoiceTotalPoints > 0 ? numberFormatter.format(e.invoiceTotalPoints) : e.invoiceTotalPoints}</StyledSpan>
              }
            },
            fieldExportFunctions: {
              invoiceAmountTotal: e => {
                return e.invoiceAmountTotal > 0 ? currencyFormatter.format(e.invoiceAmountTotal) : '0,00 kr.'
              },
              invoiceBasisTotal: e => {
                return e.invoiceBasisTotal > 0 ? currencyFormatter.format(e.invoiceBasisTotal) : '0,00 kr.'
              },
              invoiceTotalPoints: e => {
                return e.invoiceTotalPoints > 0 ? numberFormatter.format(e.invoiceTotalPoints) : e.invoiceTotalPoints
              }
            },
            fieldStringFunctions: {
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}

class AdminInvoiceBrandList extends Component {
  constructor(props) {
    super(props)
    this.store = InvoiceStore
  }
  componentDidMount() {
    if (this.state.invoiceBrands.length == 0) {
      InvoiceActions.getBrands()
    }
  }
  componentDidUpdate() {
    if (this.state.invoiceProcessSuccess) {
      toast.success('Invoices processed successfully', {
        autoClose: 5000,
        onClose: () => {
          InvoiceActions.clearToast()
        }
      })
    }
    if (this.state.invoiceProcessError) {
      toast.error('Invoices process failed', {
        autoClose: 5000,
        onClose: () => {
          InvoiceActions.clearToast()
        }
      })
    }
  }
  render() {
    if (this.state.fetchingBrands) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Fakturaer</strong>{' '}
            <Button className='float-right' onClick={InvoiceActions.processInvoices}>
              {this.state.processingInvoice ? <FontAwesomeIcon icon='spinner'></FontAwesomeIcon> : 'Process invoices'}
            </Button>
          </React.Fragment>
        }
      >
        <DynamicTable
          headerscss={{
            openInvoiceAmount: { textAlign: 'right', whiteSpace: 'nowrap' },
            closedInvoiceThisMonth: { textAlign: 'right', whiteSpace: 'nowrap' },
            closedInvoiceLastMonth: { textAlign: 'right', whiteSpace: 'nowrap' },
            closedInvoiceTwoMonthsBack: { textAlign: 'right', whiteSpace: 'nowrap' },
            openOrdersAmount: { textAlign: 'right', whiteSpace: 'nowrap' }
          }}
          responsive
          size='sm'
          data={this.state.invoiceBrands}
          headers={{
            brandName: 'Brand',
            openInvoiceAmount: 'Open Invoice Amount',
            closedInvoiceThisMonth: 'Closed Current Month',
            closedInvoiceLastMonth: 'Closed Last Month',
            closedInvoiceTwoMonthsBack: 'Closed 2 Months Back',
            openOrdersAmount: 'Open Orders Amount'
          }}
          options={{
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'view' + e.BrandID}>Vis</Tooltip>
                    <Button tag={Link} to={'/admininvoice/' + e.BrandID} id={'view' + e.BrandID}>
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            },
            fieldFunctions: {
              openInvoiceAmount: e => {
                return <StyledSpan>{e.openInvoiceAmount ? currencyFormatter.format(e.openInvoiceAmount) : '0,00 kr.'}</StyledSpan>
              },
              closedInvoiceThisMonth: e => {
                return <StyledSpan>{e.closedInvoiceThisMonth ? currencyFormatter.format(e.closedInvoiceThisMonth) : '0,00 kr.'}</StyledSpan>
              },
              closedInvoiceLastMonth: e => {
                return <StyledSpan>{e.closedInvoiceLastMonth ? currencyFormatter.format(e.closedInvoiceLastMonth) : '0,00 kr.'}</StyledSpan>
              },
              closedInvoiceTwoMonthsBack: e => {
                return <StyledSpan>{e.closedInvoiceTwoMonthsBack ? currencyFormatter.format(e.closedInvoiceTwoMonthsBack) : '0,00 kr.'}</StyledSpan>
              },
              openOrdersAmount: e => {
                return <StyledSpan>{e.openOrdersAmount ? currencyFormatter.format(e.openOrdersAmount) : '0,00 kr.'}</StyledSpan>
              }
            },
            fieldExportFunctions: {
              openInvoiceAmount: e => {
                return e.openInvoiceAmount ? currencyFormatter.format(e.openInvoiceAmount) : '0,00 kr.'
              },
              closedInvoiceThisMonth: e => {
                return e.closedInvoiceThisMonth ? currencyFormatter.format(e.closedInvoiceThisMonth) : '0,00 kr.'
              },
              closedInvoiceLastMonth: e => {
                return e.closedInvoiceLastMonth ? currencyFormatter.format(e.closedInvoiceLastMonth) : '0,00 kr.'
              },
              closedInvoiceTwoMonthsBack: e => {
                return e.closedInvoiceTwoMonthsBack ? currencyFormatter.format(e.closedInvoiceTwoMonthsBack) : '0,00 kr.'
              },
              openOrdersAmount: e => {
                return e.openOrdersAmount ? currencyFormatter.format(e.openOrdersAmount) : '0,00 kr.'
              }
            }
          }}
        />

        <StyledRow>
          <Col>
            <b>Totals</b>
          </Col>
          <Col md='2' style={{ textAlign: 'right' }}>
            <StyledInvoiceAmount>{currencyFormatter.format(this.state.totalOpenInvoiceAmount)}</StyledInvoiceAmount>
          </Col>
          <Col md='2' style={{ textAlign: 'right' }}>
            <b>{currencyFormatter.format(this.state.totalClosedInvoiceThisMonth)}</b>
          </Col>
          <Col md='2' style={{ textAlign: 'right' }}>
            <b>{currencyFormatter.format(this.state.totalClosedInvoiceLastMonth)}</b>
          </Col>
          <Col md='2' style={{ textAlign: 'right' }}>
            <b>{currencyFormatter.format(this.state.totalClosedInvoiceTwoMonthsBack)}</b>
          </Col>
          <Col md='2' style={{ textAlign: 'right' }}>
            <b>{currencyFormatter.format(this.state.totalOpenOrderAmount)}</b>
          </Col>
        </StyledRow>

        <StyledRow className='mt-2'>
          <Col>
            <b>Dagens omsætning</b>
          </Col>
        </StyledRow>
        <StyledRow className='mt-2'>
          <Col md='2'>
            <b>{moment().format('DD-MM-YYYY')}</b>
          </Col>
          <Col md='2' style={{ textAlign: 'right' }}>
            <StyledInvoiceAmount>{currencyFormatter.format(this.state.totalTodayShippedAmount)}</StyledInvoiceAmount>
          </Col>
        </StyledRow>
      </CollapsibleCard>
    )
  }
}
