import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { OrderActions, OrderStore } from 'Stores/orderStore'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Component } from 'reflux'
import useDashboardData from 'Components/dashboard/useDashboardData.js'

const AreaSeperator = styled(FormGroup)`
  border-bottom: 1px dashed #ddd;
`

export default class CustomOrder extends Component {
  constructor(props) {
    super(props)
    this.store = OrderStore
  }
  render() {
    return (
      <React.Fragment>
        <CustomOrderForm />
      </React.Fragment>
    )
  }
}

function CustomOrderForm() {
  const [formState, updateFormState] = useState({
    brandIdentifier: '',
    userIdentifier: '',
    orderlineTitle: '',
    orderAmountPoints: '',
    orderlineProductPrice: '',
    orderlineProductMargin: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [orderStatus, setOrderStatus] = useState()
  const [isRedirect, setIsRedirect] = useState(false)
  const [errorInfo, setErrorInfo] = useState('')
  const [data] = useDashboardData('schemas/allbrands')
  const [brandRatio, setBrandRatio] = useState('')

  const handleChange = event => {
    if (event.target.name === 'brandIdentifier') {
      let margin_calculation = ''
      data &&
        data.length &&
        data.map(e => {
          if (e.BrandID === +event.target.value) {
            setBrandRatio(e.brandPointRatio)
            if (formState.orderlineProductPrice && formState.orderAmountPoints) {
              margin_calculation = (
                ((formState.orderAmountPoints / e.brandPointRatio - formState.orderlineProductPrice) / formState.orderlineProductPrice) *
                100
              ).toFixed(2)
              margin_calculation < 0 ? (margin_calculation = 0) : margin_calculation
            }
          }
        })
      updateFormState({ ...formState, ...{ orderlineProductMargin: margin_calculation, [event.target.name]: event.target.value } })
    } else if (formState.orderAmountPoints > 0 && event.target.name === 'orderlineProductPrice' && event.target.value > 0 && brandRatio) {
      let margin_calculation = (((formState.orderAmountPoints / brandRatio - event.target.value) / event.target.value) * 100).toFixed(2)
      margin_calculation < 0 ? (margin_calculation = 0) : margin_calculation
      updateFormState({ ...formState, ...{ orderlineProductMargin: margin_calculation, orderlineProductPrice: event.target.value } })
    } else if (formState.orderlineProductPrice > 0 && event.target.name === 'orderAmountPoints' && event.target.value > 0 && brandRatio) {
      let margin_calculation = (
        ((event.target.value / brandRatio - formState.orderlineProductPrice) / formState.orderlineProductPrice) *
        100
      ).toFixed(2)
      margin_calculation < 0 ? (margin_calculation = 0) : margin_calculation
      updateFormState({ ...formState, ...{ orderlineProductMargin: margin_calculation, orderAmountPoints: event.target.value } })
    } else if (
      (event.target.name === 'orderAmountPoints' && event.target.value === '') ||
      (event.target.name === 'orderlineProductPrice' && event.target.value === '')
    ) {
      updateFormState({ ...formState, ...{ orderlineProductMargin: '', [event.target.name]: event.target.value } })
    } else {
      updateFormState({ ...formState, ...{ [event.target.name]: event.target.value } })
    }
  }

  useEffect(() => {
    if (orderStatus === 'SUCCESS') {
      toast.success('Created custom order', {
        autoClose: 5000
      })
      setIsRedirect(true)
    }
    if (orderStatus === 'FAILED') {
      toast.error('Failed to create custom order: ' + errorInfo, {
        autoClose: 5000
      })
      setOrderStatus()
    }
  }, [orderStatus])

  useEffect(() => {
    const unsubscribe = OrderActions.createCustomOrder.failed.listen(error => {
      setIsLoading(false)
      if (error && error.message) {
        setErrorInfo(error.message)
      }
      setOrderStatus('FAILED')
    })
    return () => {
      unsubscribe()
    }
  })

  if (isRedirect) {
    return <Redirect to={'/orders'} />
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    setIsLoading(true)
    OrderActions.createCustomOrder.completed.listen(() => {
      setIsLoading(false)
      setOrderStatus('SUCCESS')
    })
    OrderActions.createCustomOrder(formState)
  }
  return (
    <Card>
      <CardHeader>Custom Order </CardHeader>
      <CardBody>
        <Form onSubmit={e => handleFormSubmit(e)}>
          <FormGroup row>
            <Label for='brandIdentifier' sm={2}>
              Brand
            </Label>
            <Col sm={10}>
              <Input type='select' name='brandIdentifier' value={formState.brandIdentifier} onChange={handleChange} required={true}>
                <option value=''>Select Brand</option>
                {data &&
                  data.length &&
                  data.map(e => {
                    return (
                      <option value={e.BrandID} key={e.BrandID}>
                        {e.BrandID}-{e.brandName}
                      </option>
                    )
                  })}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='userIdentifier' sm={2}>
              User Email or Phone
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='userIdentifier'
                id='userIdentifier'
                placeholder='User Email or Phone'
                onChange={handleChange}
                value={formState.userIdentifier}
                required={true}
              />
            </Col>
          </FormGroup>
          <AreaSeperator row />
          <FormGroup row>
            <Label for='orderlineTitle' sm={2}>
              Title
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderlineTitle'
                id='orderlineTitle'
                placeholder='Title'
                onChange={handleChange}
                value={formState.orderlineTitle}
                required={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderAmountPoints' sm={2}>
              Points
            </Label>
            <Col sm={10}>
              <Input
                type='number'
                name='orderAmountPoints'
                id='orderAmountPoints'
                placeholder='Points'
                onChange={handleChange}
                value={formState.orderAmountPoints}
                required={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderlineProductPrice' sm={2}>
              Price
            </Label>
            <Col sm={10}>
              <Input
                type='number'
                name='orderlineProductPrice'
                id='orderlineProductPrice'
                placeholder='Price'
                onChange={handleChange}
                value={formState.orderlineProductPrice}
                required={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderlineProductMargin' sm={2}>
              Margin
            </Label>
            <Col sm={10}>
              <p>
                <span
                  id='orderlineProductMargin'
                  style={
                    formState.orderlineProductMargin < 30
                      ? { color: 'red', fontSize: '25px', fontWeight: 'bold' }
                      : { color: 'black', fontSize: '25px', fontWeight: 'bold' }
                  }
                >
                  {formState.orderlineProductMargin}%
                </span>
              </p>
            </Col>
          </FormGroup>
          <AreaSeperator row />
          <FormGroup row>
            <Col sm={{ size: 10, offset: 2 }}>
              <Button disabled={isLoading}>{isLoading ? <FontAwesomeIcon icon='spinner' spin /> : 'Opret & underret'}</Button>
            </Col>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  )
}
