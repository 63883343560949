import { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import React from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Input, Table } from 'reactstrap'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import PackageImageUpload from './PackageImageUpload'
import styled from 'styled-components'

const StyledImg = styled.img`
  width: 364px;
  height: 492px;
  object-fit: cover;
`

function PackageForm(props) {
  const [loading, setLoading] = useState(false)
  const { ProductPackageID } = props.match.params
  const [packageDetails, setPackageDetails] = useState({
    categories: [{ CategoryID: '' }],
    products: [{ ProductID: '', filterText: '', products: [] }],
    catalogs: [
      {
        CatalogID: '',
        overrideMarkup: '',
        overridePrice: ''
      }
    ],
    tags: [
      {
        CategoryID: ''
      }
    ]
  })
  const [status, setStatus] = useState('LOADING')
  const [categoryProducts, setCategoryProducts] = useState([])
  const [tags, setTags] = useState([])
  const [products, setProducts] = useState([])
  const [catalogs, setCatalogs] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const packageStatus = ['AVAILABLE', 'UNAVAILABLE']

  useEffect(() => {
    let price = packageDetails?.products?.reduce(
      (sum, pr) => (sum + pr.productPriceAdjust ? pr.productPrice * (pr.productPriceAdjust / 100) : pr.productPrice),
      0
    )
    setTotalPrice(price)
  }, [packageDetails])

  const fetchCategoryProduct = () => {
    Proxies.GET('categories/pool').then(responseJSON => {
      let categories = []
      let tags = []
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        responseJSON.map(item => {
          if (item.categoryTaxonomy === 'PRODUCT' && item.categoryName !== '') {
            categories.push(item)
          }
          if (item.categoryTaxonomy === 'TAG' && item.categoryName !== '') {
            tags.push(item)
          }
        })
      }
      setCategoryProducts(categories)
      setTags(tags)
    })
  }

  const fetchProducts = () => {
    Proxies.GET('products/simplePool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setProducts(responseJSON)
      }
    })
  }

  const fetchCatalogs = () => {
    Proxies.GET('schemas/catalogs').then(responseJSON => {
      let catalogs = []
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        responseJSON.map(item => {
          if (item.moduleName !== '') {
            catalogs.push(item)
          }
        })
        setCatalogs(catalogs)
      }
    })
  }

  function savePackage() {
    let request = packageDetails
    request.categories = request.categories.filter(c => +c.CategoryID > 0)
    request.tags = request.tags.filter(t => +t.CategoryID > 0)
    request.products = request.products.filter(p => +p.ProductID > 0)
    request.catalogs = request.catalogs.filter(c => +c.CatalogID > 0)
    if (request.products.length < 2) {
      toast.error('Please add minimum of two products', {
        autoClose: 5000,
        onClose: () => {
          setStatus('LOADING')
        }
      })
      return
    }
    Proxies.POST('productpackage/replace', { ...packageDetails, categories: packageDetails.categories.concat(packageDetails.tags) }).then(
      responseJSON => {
        if (responseJSON.error) {
          setStatus('FAILED')
        } else {
          setStatus('SUCCESS')
        }
      }
    )
  }

  const fetchPackage = ProductPackageID => {
    setLoading(true)
    Proxies.GET('productpackage/fetch', { ProductPackageID }).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        responseJSON.tags = responseJSON?.categories?.filter(item => item?.categoryTaxonomy === 'TAG') || []
        if (!responseJSON.tags.length) {
          responseJSON.tags = [{ CategoryID: '' }]
        }
        setPackageDetails(responseJSON)
      }
    })
  }

  const resetData = () => {
    if (ProductPackageID) {
      fetchPackage()
    } else {
      setPackageDetails({})
    }
  }

  const updatePackage = ({ target }) => {
    setPackageDetails({ ...packageDetails, [target.name]: target.value })
  }

  const updateFieldData = (field, index, type) => {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let tempPackage = packageDetails
    let column = tempPackage[type][index]

    column[fieldName] = fieldValue

    setPackageDetails({ ...packageDetails, [type]: tempPackage[type] })
  }

  const addField = (type, value) => {
    let temp = packageDetails[type]
    temp.push(value)
    setPackageDetails({ ...packageDetails, [type]: temp })
  }

  const removeField = (key, type) => {
    let temp = packageDetails[type]
    temp.splice(key, 1)
    setPackageDetails({ ...packageDetails, [type]: temp })
  }

  const updateFilterText = (key, t) => {
    setPackageDetails({
      ...packageDetails,
      ...packageDetails.products.map((item, index) => {
        if (index === key && t.target) {
          let filterText = t.target.value.toLowerCase()
          item.filterText = filterText
          item.products =
            filterText && products.length
              ? products.filter(
                  pr =>
                    pr.ProductID.toString().indexOf(filterText) > -1 ||
                    pr.productUID.toLowerCase().indexOf(filterText) > -1 ||
                    pr.elementTitle.toLowerCase().indexOf(filterText) > -1
                )
              : products
        }
        return item
      })
    })
  }

  useEffect(() => {
    if (ProductPackageID) {
      fetchPackage(ProductPackageID)
    }
    fetchCategoryProduct()
    fetchProducts()
    fetchCatalogs()
  }, [])

  useEffect(() => {
    if (products && products.length) {
      setPackageDetails({
        ...packageDetails,
        ...packageDetails.products.map(item => {
          item.products = products
          return item
        })
      })
    }
  }, [products])

  useEffect(() => {
    if (packageDetails.products && packageDetails.products.length) {
      let totalPrice = 0
      for (let pr of packageDetails.products) {
        let product = products.find(p => p.ProductID === +pr.ProductID)
        if (product && product.ProductID) {
          totalPrice += product.productPriceComputed
        }
      }
      if (totalPrice > 0) {
        setTotalPrice(totalPrice)
      }
    }
  }, [packageDetails])

  if (status === 'SUCCESS') {
    toast.success(ProductPackageID ? 'Successfully Updated' : 'Successfully Created', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
    return <Redirect to='/packages' />
  }

  if (status === 'FAILED') {
    toast.error('Error while saving package', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
  }
  return (
    <CollapsibleCard startOpen={true} header={<strong>Sampakning</strong>}>
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <Form>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Title</label>
            </Col>
            <Col xs='10'>
              <Input type='text' value={packageDetails.title} name='title' id='title' onChange={e => updatePackage(e)} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Short Description</label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={packageDetails.shortDescription ? packageDetails.shortDescription : ''}
                name='shortDescription'
                id='shortDescription'
                onChange={e => updatePackage(e)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Detail Description</label>
            </Col>
            <Col xs='10'>
              <Input
                type='textarea'
                value={packageDetails.fullDescription ? packageDetails.fullDescription : ''}
                name='fullDescription'
                onChange={e => updatePackage(e)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Products</label>
            </Col>
            <Col xs='10'>
              {!products.length ? (
                <FontAwesomeIcon icon='spinner' spin />
              ) : (
                <Table className='mb-sm-0'>
                  <thead>
                    <tr>
                      <th className='w-25'>Search</th>
                      <th className='w-75'>Products</th>
                      <th>
                        <Button onClick={() => addField('products', { ProductID: '', filterText: '', products })}>
                          <FontAwesomeIcon icon='plus' />
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packageDetails &&
                      !!packageDetails.products &&
                      !!packageDetails.products.length &&
                      packageDetails.products.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <Input
                                type='text'
                                placeholder='Search'
                                value={item.filterText}
                                onChange={t => {
                                  updateFilterText(key, t)
                                }}
                              ></Input>
                            </td>
                            <td>
                              <Input
                                type='select'
                                name='ProductID'
                                value={item.ProductID}
                                id='productID'
                                onChange={t => updateFieldData(t, key, 'products')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {!!item.products &&
                                  !!item.products.length &&
                                  item.products.map((e, i) => {
                                    return (
                                      <option value={e.ProductID} key={i}>
                                        {`${e.ProductID}  ${e.elementTitle}`}
                                      </option>
                                    )
                                  })}
                              </Input>
                            </td>

                            <td>
                              <Button
                                onClick={() => {
                                  removeField(key, 'products')
                                }}
                              >
                                <FontAwesomeIcon icon='minus' />
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Total Price</label>
            </Col>
            <Col xs='10'>
              <Input type='text' value={totalPrice || 0} disabled />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Categories</label>
            </Col>
            <Col xs='10'>
              <Table className='mb-sm-0'>
                <thead>
                  <tr>
                    <th className='w-100'>Categories</th>
                    <th>
                      <Button onClick={() => addField('categories', { CategoryID: '' })}>
                        <FontAwesomeIcon icon='plus' />
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {packageDetails &&
                    !!packageDetails.categories &&
                    !!packageDetails.categories.length &&
                    packageDetails.categories.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td className='w-100'>
                            <Input
                              type='select'
                              name='CategoryID'
                              value={item.CategoryID}
                              id='productCategoryID'
                              onChange={t => updateFieldData(t, key, 'categories')}
                            >
                              <option value='' disabled>
                                Click to Select
                              </option>
                              {!!categoryProducts.length &&
                                categoryProducts.map((e, i) => {
                                  return (
                                    <option value={e.CategoryID} key={i}>
                                      {e.categoryName}
                                    </option>
                                  )
                                })}
                            </Input>
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                removeField(key, 'categories')
                              }}
                            >
                              <FontAwesomeIcon icon='minus' />
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
              <Table className='mb-sm-0'>
                <thead>
                  <tr>
                    <th className='w-100'>Tags</th>
                    <th>
                      <Button onClick={() => addField('tags', { CategoryID: '' })}>
                        <FontAwesomeIcon icon='plus' />
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {packageDetails &&
                    !!packageDetails.tags &&
                    !!packageDetails.tags.length &&
                    packageDetails.tags.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td className='w-100'>
                            <Input
                              type='select'
                              name='CategoryID'
                              value={item.CategoryID}
                              id='productCategoryID'
                              onChange={t => updateFieldData(t, key, 'tags')}
                            >
                              <option value='' disabled>
                                Click to Select
                              </option>
                              {!!tags.length &&
                                tags.map((e, i) => {
                                  return (
                                    <option value={e.CategoryID} key={i}>
                                      {e.categoryName}
                                    </option>
                                  )
                                })}
                            </Input>
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                removeField(key, 'tags')
                              }}
                            >
                              <FontAwesomeIcon icon='minus' />
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Catalog</label>
            </Col>
            <Col xs='10'>
              <Table className='mb-sm-0'>
                <thead>
                  <tr>
                    <th className='w-50'>Catalogs</th>
                    <th className='w-25'>Override Price</th>
                    <th className='w-25'>Override Markup</th>
                    <th>
                      <Button onClick={() => addField('catalogs', { CatalogID: '', overridePrice: '', overrideMarkup: '' })}>
                        <FontAwesomeIcon icon='plus' />
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {packageDetails &&
                    !!packageDetails.catalogs &&
                    packageDetails.catalogs.length &&
                    packageDetails.catalogs.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Input
                              type='select'
                              name='CatalogID'
                              value={item.CatalogID}
                              id='CatalogID'
                              onChange={t => updateFieldData(t, key, 'catalogs')}
                            >
                              <option value='' disabled>
                                Click to Select
                              </option>
                              {!!catalogs &&
                                !!catalogs.length &&
                                catalogs.map((e, i) => {
                                  return (
                                    <option value={e.CatalogID} key={i}>
                                      {e.moduleName}
                                    </option>
                                  )
                                })}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type='text'
                              name='overridePrice'
                              value={item.overridePrice}
                              id='overridePrice'
                              onChange={t => updateFieldData(t, key, 'catalogs')}
                            />
                          </td>
                          <td>
                            <Input
                              type='text'
                              name='overrideMarkup'
                              value={item.overrideMarkup}
                              id='overrideMarkup'
                              onChange={t => updateFieldData(t, key, 'catalogs')}
                            />
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                removeField(key, 'catalogs')
                              }}
                            >
                              <FontAwesomeIcon icon='minus' />
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Status</label>
            </Col>
            <Col xs='10'>
              <Input type='select' name='status' value={packageDetails.status} onChange={e => updatePackage(e)}>
                <option value='' disabled>
                  Click to Select
                </option>
                {packageStatus.map(e => {
                  return (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
          <PackageImageUpload onUploadImage={updatePackage} />
          {packageDetails && packageDetails.image && (
            <Row>
              <Col xs='2'></Col>
              <Col xs='10'>
                <StyledImg src={packageDetails.image.imageOriginSource} alt={packageDetails.image.imageName} />
              </Col>
            </Row>
          )}
          <Button onClick={savePackage} style={{ marginTop: 20 }}>
            Gem
          </Button>
          <Button packageDetails='danger' onClick={resetData} className='ml-2' style={{ marginTop: 20 }}>
            Nulstil
          </Button>
        </Form>
      )}
    </CollapsibleCard>
  )
}

export default PackageForm
