import React from 'react'
import Reflux from 'reflux'
import { Col, Row, Container } from 'reactstrap'
import PointsEarnedChart from 'Components/dashboard/PointsEarnedChart.js'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'
import PointsEarnedTotalChart from 'Components/dashboard/PointsEarnedTotalChart.js'
import verifyRequirements from 'Components/authorization.js'
import LastMonthTotalPoints from 'Components/dashboard/LastMonthTotalPoints.js'
import LastMonthTotalActivationPoints from 'Components/dashboard/LastMonthTotalActivationPoints.js'
import PointsUsedChart from 'Components/dashboard/PointsUsedChart.js'
import PointsUsedYearlyTotals from 'Components/dashboard/PointsUsedYearlyTotals.js'
import PointsRemainingChart from 'Components/dashboard/PointsRemainingChart.js'
import ActivationPointsRemainingChart from 'Components/dashboard/ActivationPointsRemainingChart.js'
import PointsRemainingYearlyTotals from 'Components/dashboard/PointsRemainingYearlyTotals.js'
import KamUsersChart from 'Components/dashboard/KamUsersChart.js'
import UnusedActiveInActivePointsChartLine from 'Components/dashboard/UnusedActiveInActivePointsChartLine.js'
import KamUsersPieChart from 'Components/dashboard/KamUsersPieChart'
import KamUsersPeriodGraph from 'Components/dashboard/KamUsersPeriodGraph'
import KamUsersCompanyChart from 'Components/dashboard/KamUsersCompanyChart'
import JyskeDashboardComponent from 'Components/dashboard/JyskeDashboardComponent'
import SantanderKamBudgetReport from 'Components/dashboard/SantanderKamBudgetReport'
import SantanderKAMSpentBudgetReport from 'Components/dashboard/SantanderKAMSpentBudgetReport'
import SantanderKAMRemainingPoints from 'Components/dashboard/SantanderKAMRemainingPoints'
import MerchandiseProductsCategoryChart from 'Components/dashboard/MerchandiseProductsCategoryChart'
import MerchandiseProductsChart from 'Components/dashboard/MerchandiseProductsChart'
import OrdersMonthlyChart from 'Components/dashboard/OrdersMonthlyChart.js'
import OrdersYearlyChart from 'Components/dashboard/OrdersYearlyChart.js'
import DealersActiveInactiveChart from '../components/dashboard/DealersActiveInactiveChart'
import UsersChartLineAll from '../components/dashboard/UsersChartLineAll'
import UsersChartLineApproved from '../components/dashboard/UsersChartLineAllApproved'
import UsersChartLineDeclined from '../components/dashboard/UsersChartLineAllDeclined'

export default class DefaultIndex extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['settings']
  }

  render() {
    if (
      verifyRequirements({
        requirements: {
          userRole: 'CLIENT',
          userPrivilege: 'MANAGE'
        }
      }) &&
      _.get(this.state, 'settings.features.dashboard')
    ) {
      return (
        <Row className='py-4'>
          {_.get(this.state, 'settings.features.enableWalletActivation') && (
            <Col xs='12'>
              <LastMonthTotalActivationPoints />
            </Col>
          )}
          <Col xs='12'>
            <LastMonthTotalPoints isWalletActivation={_.get(this.state, 'settings.features.enableWalletActivation')} />
          </Col>
          <Col lg='6'>
            <PointsEarnedChart />
          </Col>
          <Col lg='6'>
            <PointsEarnedTotalChart />
          </Col>
          <Col lg='6'>
            <PointsUsedChart />
          </Col>
          <Col lg='6'>
            <PointsUsedYearlyTotals />
          </Col>
          <Col lg='6'>
            <PointsRemainingChart isWalletActivation={_.get(this.state, 'settings.features.enableWalletActivation')} />
          </Col>
          <Col lg='6'>
            <PointsRemainingYearlyTotals isWalletActivation={_.get(this.state, 'settings.features.enableWalletActivation')} />
          </Col>
          {_.get(this.state, 'settings.features.enableWalletActivation') && (
            <Col lg='12'>
              <ActivationPointsRemainingChart />
            </Col>
          )}
          <Col lg='6'>
            <OrdersMonthlyChart />
          </Col>
          <Col lg='6'>
            <OrdersYearlyChart />
          </Col>
          <Col lg='6'>
            <UsersChartLineApproved />
          </Col>
          <Col lg='6'>
            <UsersChartLineDeclined />
          </Col>
          <Col xs='12'>
            <KamUsersChart />
          </Col>
          <Col xs='12'>
            <DealersActiveInactiveChart />
          </Col>
          <Col lg='6'>
            <UsersChartLineAll />
          </Col>
          <Col lg='6'>
            <UnusedActiveInActivePointsChartLine />
          </Col>
        </Row>
      )
    } else if (
      verifyRequirements({
        requirements: {
          userRole: 'USER',
          userPrivilege: 'VIEW'
        }
      }) &&
      _.get(this.state, 'settings.features.dashboard') &&
      this.state.settings.userAccess === 'APPROVED'
    ) {
      return (
        <Row className='py-4'>
          <Col lg='6'>
            <KamUsersPeriodGraph />
          </Col>
          <Col lg='6'>
            <KamUsersPieChart />
          </Col>
          <Col xs='12'>
            <KamUsersCompanyChart />
          </Col>
        </Row>
      )
    } else if (_.get(this.state, 'settings.features.kamdashboard') && this.state.settings.userAccess === 'APPROVED') {
      return <JyskeDashboardComponent username={this.state.settings.userName} company={this.state.settings.userCompany} />
    } else if (
      verifyRequirements({
        requirements: {
          userRole: 'CLIENT',
          userPrivilege: 'MANAGE'
        }
      }) &&
      _.get(this.state, 'settings.features.merchandiseShopBudgetReport')
    ) {
      return (
        <Row className='py-4'>
          <Col xs='12'>
            <SantanderKamBudgetReport />
          </Col>
          <Col xs='12'>
            <SantanderKAMSpentBudgetReport />
          </Col>
          <Col xs='12'>
            <SantanderKAMRemainingPoints />
          </Col>
          <Col xs='12'>
            <MerchandiseProductsCategoryChart />
          </Col>
          <Col xs='12'>
            <MerchandiseProductsChart />
          </Col>
        </Row>
      )
    } else {
      return (
        <div className='app flex-row align-items-center' data-cy='welcome.container'>
          <Container>
            <Row className='justify-content-center'>
              <Col md='6'>
                <div className='clearfix'>
                  {/* <h1 className='float-left display-3 mr-4'>Start</h1> */}
                  <h1 className='text-muted text-center'>Velkommen</h1>
                  {/* <p className='text-muted float-left'>Der er sikkert en masse mere at lave i menuen.</p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  }
}
