import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Label, Input } from 'reactstrap'
import { HofActions, HofStore, STATE } from 'Stores/hofStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import styled from 'styled-components'
import DynamicTable from 'Components/dynamicTable'
import { LoginStore } from 'Stores/loginStore'
import cx from 'classnames'
import { toast } from 'react-toastify'
import _ from 'lodash'

const StyledCheckbox = styled(FormField)`
  position: relative !important;
  left: 20px;
`
const StyledButton = styled(Button)`
  &.btn-warning.disabled,
  .btn-warning:disabled {
    cursor: not-allowed !important;
  }
`
const StyledWrapper = styled.div`
  max-height: 300px !important;
  overflow: auto;
  padding: 20px !important;
`
export default class HOFForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [HofStore, LoginStore]
    this.storeKeys = [
      'isAdminAccess',
      'fetchingGroups',
      'fetchingBrands',
      'brands',
      'settings',
      'status',
      'hofDetails',
      'groups',
      'filteredGroupData',
      'previewElements',
      'isPreview'
    ]
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  componentDidUpdate() {
    if (this.state.settings && this.state.settings.BrandID) {
      const fetchGroups = _.get(this.props, 'match.params.HOFID') || _.get(this.props, 'location.search') ? false : true
      if (
        this.state.settings.brandName === 'MSB' &&
        this.state.settings.BrandID === 9 &&
        this.state.settings.userPrivilege === 'MANAGE' &&
        this.state.settings.userRole === 'System' &&
        !this.state.isAdminAccess
      ) {
        HofActions.giveAccess(this.state.settings.BrandID, fetchGroups)
        HofActions.getBrands()
      } else {
        fetchGroups &&
          !this.state.isAdminAccess &&
          !this.state.fetchingGroups &&
          this.state.groups &&
          !this.state.groups.length &&
          HofActions.getGroups(this.state.settings.BrandID)
      }
      this.state.hofDetails &&
        !this.state.hofDetails.BrandID &&
        this.state.isAdminAccess &&
        fetchGroups &&
        HofActions.giveAccess(this.state.settings.BrandID, fetchGroups)
      this.state.isAdminAccess && this.state.brands && !this.state.brands.length && !this.state.fetchingBrands && HofActions.getBrands()
    }
    if (this.state.status === STATE.FETCHING_GROUPS_FAILED) {
      toast.error('FETCHING GROUPS FAILED', {
        autoClose: 3000,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }
  }

  resetData = () => {
    this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
    if (_.get(this.props, 'match.params.HOFID') || _.get(this.props, 'location.search')) {
      const copy = _.get(this.props, 'location.search') ? true : false
      const HOFID = _.get(this.props, 'location.search') ? this.props.location.search.split('=')[1] : this.props.match.params.HOFID
      HofActions.getHOFDetails(Number(HOFID), copy)
    } else {
      HofActions.createEmptyHOF()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.state.hofDetails.moduleName ? true : false,
      ...(this.state.hofDetails.timeStart &&
        this.state.hofDetails.timeEnd && { isDateValid: moment(this.state.hofDetails.timeStart).isSameOrBefore(this.state.hofDetails.timeEnd) })
    }

    this.setState({ fieldValidation })

    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveHOFForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      HofActions.saveHOF()
    }
  }

  render() {
    if (this.state.status === STATE.IS_REDIRECT) {
      return <Redirect to='/hof' />
    }

    let groups = []
    const groupList =
      this.state.filteredGroupData && this.state.hofDetails.groupFilterText && this.state.hofDetails.groupFilterText.length
        ? this.state.filteredGroupData
        : this.state.groups
    let hofGroups = this.state.hofDetails.groups ? this.state.hofDetails.groups.concat([]) : []
    groupList.forEach((e, i) => {
      let isCheckedData = hofGroups.find(s => {
        return s.GroupID === e.GroupID
      })
      let isChecked = isCheckedData !== undefined
      if (isChecked) {
        hofGroups = hofGroups.filter(s => {
          return s.GroupID !== e.GroupID
        })
      }
      groups.push(
        <React.Fragment key={i}>
          <Input type='checkbox' checked={isChecked} name={e.GroupID} onChange={HofActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
          <br />
        </React.Fragment>
      )
    })
    if (hofGroups.length > 0) {
      groups.push(
        <React.Fragment key={'overflow'}>
          Følgende grupper skal gendannes via databasen hvis de slettes
          <br />
        </React.Fragment>
      )
      hofGroups.forEach((e, i) => {
        groups.push(
          <React.Fragment key={'overflow' + i}>
            <Input type='checkbox' checked={true} name={e.GroupID} onChange={HofActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Hall Of Fame</strong>}>
          <Form>
            {this.state.isAdminAccess && (
              <FormField field={'BrandID'} name='Brand'>
                <Input type='select' name='BrandID' value={this.state.hofDetails.BrandID} onChange={HofActions.updateHOFField}>
                  <option value=''>Select Brand</option>
                  {this.state.brands &&
                    !!this.state.brands.length &&
                    this.state.brands.map(e => {
                      return (
                        <option value={e.BrandID} key={e.BrandID}>
                          {e.brandName}
                        </option>
                      )
                    })}
                </Input>
              </FormField>
            )}
            <FormField
              name='Navn'
              required
              field='moduleName'
              data={this.state.hofDetails}
              onChange={e => {
                HofActions.updateHOFField(e)
                this.validateForm()
              }}
            />
            <FormField name='Beskrivelse' field='moduleDescription' data={this.state.hofDetails} onChange={HofActions.updateHOFField} />
            <FormField type='textarea' name='Module Query' field='moduleQuery' data={this.state.hofDetails} onChange={HofActions.updateHOFField} />
            <FormField name='Grupper' field='Groups' data={{}}>
              <Row>
                <Col xs='2'>
                  <Label for='groupFilterHead'>Søg Grupper</Label>
                </Col>
                <Col xs='5'>
                  <Input
                    type='text'
                    value={this.state.hofDetails.groupFilterText}
                    name='groupFilterText'
                    id='groupFilterText'
                    onChange={HofActions.updateHOFField}
                  />
                </Col>
              </Row>
              <React.Fragment>
                <StyledWrapper>
                  <Col>{groups}</Col>
                </StyledWrapper>
              </React.Fragment>
            </FormField>
            <FormField name='Tidsplan' data={{}}>
              <Row>
                <Col xs='6'>
                  <Input
                    className={cx({
                      border: this.state.fieldValidation.isDateValid === false,
                      rounded: this.state.fieldValidation.isDateValid === false,
                      'border-danger': this.state.fieldValidation.isDateValid === false,
                      'p-2': this.state.fieldValidation.isDateValid === false
                    })}
                    type='date'
                    placeholder='Time start'
                    value={this.state.hofDetails.timeStart}
                    name='timeStart'
                    id='timeStart'
                    onChange={e => {
                      HofActions.updateHOFField(e)
                      this.validateForm()
                    }}
                  />
                </Col>
                <Col xs='6'>
                  <Input
                    className={cx({
                      border: this.state.fieldValidation.isDateValid === false,
                      rounded: this.state.fieldValidation.isDateValid === false,
                      'border-danger': this.state.fieldValidation.isDateValid === false,
                      'p-2': this.state.fieldValidation.isDateValid === false
                    })}
                    type='date'
                    value={this.state.hofDetails.timeEnd}
                    name='timeEnd'
                    id='timeEnd'
                    onChange={e => {
                      HofActions.updateHOFField(e)
                      this.validateForm()
                    }}
                  />
                </Col>
              </Row>
            </FormField>
            <FormField type='number' name='Pool Order' field='poolOrder' data={this.state.hofDetails} onChange={HofActions.updateHOFField} />
            <StyledCheckbox
              type='checkbox'
              name='Show ProfileImages'
              field='showProfileImages'
              data={this.state.hofDetails}
              onChange={HofActions.updateHOFField}
              checkedValue={1}
              unCheckedValue={0}
            />
            <Button onClick={this.saveHOFForm} disabled={this.state.status === STATE.IS_SAVING}>
              {this.state.status === STATE.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
            <StyledButton color='warning' disabled={!this.state.hofDetails.moduleQuery} onClick={HofActions.previewHOF} className='ml-2'>
              Preview
            </StyledButton>
          </Form>
        </CollapsibleCard>
        {this.state.isPreview && (
          <CollapsibleCard startOpen header={<strong>Hall Of Fame Preview</strong>}>
            {this.state.status === STATE.FETCHING_PREVIEW ? (
              <FontAwesomeIcon icon='spinner' spin />
            ) : (
              <DynamicTable responsive size='sm' data={this.state.previewElements} />
            )}
          </CollapsibleCard>
        )}
      </React.Fragment>
    )
  }
}
