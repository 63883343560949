import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { InvoiceStore, InvoiceActions } from 'Stores/invoiceStore'
import { toast } from 'react-toastify'
import { LoginStore } from '../../stores/loginStore'

export default class FreightOverview extends Component {
  constructor(props) {
    super(props)
    this.stores = [InvoiceStore, LoginStore]
    this.storeKeys = ['BrandID', 'invoiceBrands', 'fetchingWeightPoolError', 'fetchingWeightPool', 'weights']
  }
  componentDidMount() {
    if (this.state.BrandID === 9) {
      InvoiceActions.getAdminWeightPool(this.props.match.params.BrandID)
    } else {
      InvoiceActions.getWeightPool(this.props.match.params.BrandID)
    }
    if (
      this.state.invoiceBrands.length === 0 ||
      !this.state.invoiceBrands.find(e => {
        return e.BrandID === Number(this.props.match.params.BrandID)
      })
    ) {
      InvoiceActions.getBrands({ isFreightOverview: true })
    }
  }
  componentDidUpdate() {
    if (this.state.fetchingWeightPoolError) {
      toast.error('Fetching Fragt Details failed', {
        autoClose: 5000,
        onClose: () => {
          InvoiceActions.clearToast()
        }
      })
    }
  }
  render() {
    return (
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Fragt</strong>{' '}
            {this.state.BrandID === 9 && (
              <Button tag={Link} to={this.props.location.pathname + '/new'} className='float-right'>
                Opret ny
              </Button>
            )}
          </React.Fragment>
        }
      >
        {this.state.fetchingWeightPool ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            headerscss={{
              orderWeight5And10: { whiteSpace: 'nowrap' },
              orderWeight10And15: { whiteSpace: 'nowrap' },
              orderWeight15And20: { whiteSpace: 'nowrap' },
              orderWeightLessThan5: { whiteSpace: 'nowrap' },
              orderWeightMoreThan20: { whiteSpace: 'nowrap' }
            }}
            responsive
            size='sm'
            data={this.state.weights}
            headers={{
              InvoiceID: '#',
              invoiceStatus: 'Status',
              invoiceTitle: 'Titel',
              timeStart: 'Kører fra',
              timeEnd: 'Kører til',
              orderWeightLessThan5: 'Weight 0-5',
              orderWeight5And10: 'Weight 5-10',
              orderWeight10And15: 'Weight 10-15',
              orderWeight15And20: 'Weight 15-20',
              orderWeightMoreThan20: 'Weight > 20',
              allWeights: 'Number of parcels'
            }}
            options={{
              startSort: {
                column: 'InvoiceID',
                dir: 'DESC'
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.InvoiceID}>Detaljer</Tooltip>
                      <Button tag={Link} to={this.props.location.pathname + '/' + e.InvoiceID} id={'edit' + e.InvoiceID}>
                        <FontAwesomeIcon icon='info-circle' />
                      </Button>
                      <Tooltip target={'copy' + e.InvoiceID}>Kopier</Tooltip>
                      <Button
                        id={'copy' + e.InvoiceID}
                        onClick={() => {
                          InvoiceActions.copyInvoice(e, this.props)
                        }}
                      >
                        <FontAwesomeIcon icon={['far', 'copy']} />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              },
              fieldFunctions: {
                timeStart: e => {
                  return e.timeStart ? moment(e.timeStart).format('YYYY-MM-DD HH:mm') : ''
                },
                timeEnd: e => {
                  return e.timeEnd ? moment(e.timeEnd).format('YYYY-MM-DD HH:mm') : ''
                },
                orderWeightLessThan5: e => {
                  return e.weightOverview && e.weightOverview.length && e.weightOverview[0].orderWeightLessThan5 >= 0
                    ? e.weightOverview[0].orderWeightLessThan5
                    : ''
                },
                orderWeight5And10: e => {
                  return e.weightOverview && e.weightOverview.length && e.weightOverview[0].orderWeight5And10 >= 0
                    ? e.weightOverview[0].orderWeight5And10
                    : ''
                },
                orderWeight10And15: e => {
                  return e.weightOverview && e.weightOverview.length && e.weightOverview[0].orderWeight10And15 >= 0
                    ? e.weightOverview[0].orderWeight10And15
                    : ''
                },
                orderWeight15And20: e => {
                  return e.weightOverview && e.weightOverview.length && e.weightOverview[0].orderWeight15And20 >= 0
                    ? e.weightOverview[0].orderWeight15And20
                    : ''
                },
                orderWeightMoreThan20: e => {
                  return e.weightOverview && e.weightOverview.length && e.weightOverview[0].orderWeightMoreThan20 >= 0
                    ? e.weightOverview[0].orderWeightMoreThan20
                    : ''
                },
                allWeights: e => {
                  return e.weightOverview && e.weightOverview.length && e.weightOverview[0].otherCountryOrders ? `${e.allWeights} *` : e.allWeights
                }
              }
            }}
          />
        )}
      </CollapsibleCard>
    )
  }
}
